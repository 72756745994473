import React, { useState } from "react";
import ReactDOM from "react-dom";
import "../../tailwind.generated.css";
import clsx from "clsx";

import Modal from "../Modal";

// import { ReactComponent as IconZoom } from "../../icons/zoom.svg";
import { IconClose, IconZoom } from "../../icons";

type CloseButtonProps = {
  setIsZoomModalOpen: (isOpen: boolean) => void;
  isMobile?: boolean;
};

const CloseButton = ({ setIsZoomModalOpen, isMobile }: CloseButtonProps) => {
  const closeButtonClassName = clsx("fixed w-12 h-12 rounded-full bg-white", isMobile ? "bottom-6 -" : "left-6");

  return (
    <div className="fixed bottom-6 w-12 h-12 rounded-full bg-white shadow-xl">
      <IconClose className="w-8 h-8 m-2 text-gray-800 cursor-pointer fill-current" onClick={() => setIsZoomModalOpen(false)} />
    </div>
  );
};

type ZoomButtonProps = {
  setIsZoomModalOpen: (isOpen: boolean) => void;
};

const ZoomButton = ({ setIsZoomModalOpen }: ZoomButtonProps) => {
  return (
    <div className="absolute w-6 h-6 rounded-full bg-black opacity-75 z-50 left-[5%] top-[5%] hover:scale-105">
      <IconZoom className="w-4 h-4 m-1 text-white cursor-pointer fill-current" onClick={() => setIsZoomModalOpen(true)} />
    </div>
  );
};

type ImageWithZoomProps = {
  imageSrc: string | undefined;
  className?: string;
  withZoomIcon?: boolean;
};

const ImageWithZoom = ({ imageSrc, className, withZoomIcon }: ImageWithZoomProps) => {
  const [isZoomModalOpen, setIsZoomModalOpen] = useState(false);

  if (!imageSrc) return null;

  return (
    <>
      <div className="relative">
        {withZoomIcon && <ZoomButton setIsZoomModalOpen={setIsZoomModalOpen} />}
        <img
          className={clsx(className && className, "object-contain transition-opacity hover:opacity-75 cursor-zoom-in")}
          src={imageSrc}
          alt=""
          onClick={() => {
            setIsZoomModalOpen(true);
          }}
        />
      </div>
      {isZoomModalOpen && (
        //@ts-ignore
        <Modal isOpen={isZoomModalOpen} setIsOpen={setIsZoomModalOpen} background={false} noHeightLimit noRoudnedCorners>
          <div className="overflow-auto hideScrollbar zoomedImageContainer">
            <img
              src={imageSrc}
              alt=""
              className={clsx("object-contain transition-all duration-300 ease-in-out cursor-zoom-out")}
              onClick={() => {
                setIsZoomModalOpen(false);
              }}
            />
          </div>
        </Modal>
      )}
    </>
  );
};

export default ImageWithZoom;
