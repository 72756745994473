import { QueryFunctionContext, useQuery } from 'react-query';
import { PrototypesAPI } from '../../../actions/PrototypesAPI';
import { IFigmaPrototype } from '../../../models/Test';
import { getFormattedPrototype } from '../../../utils/figma';

export function usePrototypeQuery(protoId: string | undefined) {
	return useQuery<any, any, IFigmaPrototype, (string | undefined)[]>({
		queryKey: ['prototypes', protoId],
		queryFn: protoQueryFunction,
		staleTime: Infinity,
		enabled: !!protoId,
	});
}

function protoQueryFunction({ queryKey }: QueryFunctionContext<(string | undefined)[], any>) {
	return !queryKey[1] ?
		Promise.resolve(null) :
		PrototypesAPI.fetchPrototype(queryKey[1]).then(getFormattedPrototype)
}