import React, { useEffect } from "react";
import { locale, interfaceText } from "../../../../../helpers";
import { pushToGTM } from "../../../../../utils/gtm";
import { IconHeart } from "../../../../../icons";
import { useSendNotification } from "../../../../../hooks";
import { ReactComponent as IconLink } from "../../../../../icons/link.svg";
import Button from "../../../../Button";
import { useTranslation } from "react-i18next";

export const CodeBlock = ({ verificationCode }: { verificationCode: string }) => {
  const sendNotification = useSendNotification();
  const { i18n } = useTranslation();

  const t = i18n.getFixedT(locale(), "translation");

  return (
    <div className="verification-code flex items-center flex-col mt-6 max-w-lg">
      <div className="verification-code__title">
        {t("Copy the confirmation code and paste it into the corresponding task field to receive your reward.")}
      </div>
      <input
        className="mt-5 p-4 text-lg rounded-md border-gray-300 border-2 text-center"
        value={verificationCode}
        readOnly
      />
      <Button
        type="primary"
        name={t("Copy code")}
        className="whitespace-nowrap mt-5 flex-row"
        icon={<IconLink width={20} height={20} className="mr-1 fill-current text-white" />}
        handler={() => {
          navigator.clipboard.writeText(verificationCode);
          sendNotification("info", t("Code copied"));
        }}
      />
    </div>
  );
};

export default function FinalStep({ verificationCode, testId, redirectUrl }: { verificationCode?: string; testId: string; redirectUrl: string }) {
  useEffect(() => {
    pushToGTM({
      event: "thankYouScreen",
      testId,
    });
  }, []);

  return (
    <div className="container mx-auto h-full flex justify-center items-center">
      <div className="text-center" id="successMessage">
        <div className="mb-4 text-4xl mx-auto">
          <IconHeart className="mx-auto" />
        </div>
        <div className="mb-4 text-3xl">{(interfaceText as any).thankYou[locale()]}</div>
        {verificationCode && <CodeBlock verificationCode={verificationCode} />}
        {redirectUrl && (
          <div className="mt-6 flex justify-center w-full">
            <a
              href={redirectUrl}
              rel="nofollow noreferrer"
              data-testid="test.thankYouButton"
              className="object-center block rounded-full transition-all font-medium duration-75 ease-in cursor-pointer tracking-tight bg-blue-600 text-white hover:bg-blue-700 py-1 px-3 text-xl px-4"
            >
              {(interfaceText as any).thankYouButton[locale()]}
            </a>
          </div>
        )}
      </div>
    </div>
  );
}
