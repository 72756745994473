import React, { useEffect, useRef } from 'react';
import { Scrollbars } from "react-custom-scrollbars-2";

export function WithScroll({ children, initialParams = {}, viewProps = {}, ...params }) {
	const scrollRef = useRef();

	useEffect(() => {
		const { current: scrollBlock } = scrollRef;
		const viewBlock = scrollBlock.view;

		let position = {
			top: 0,
			left: 0,
			x: 0,
			y: 0,
		};

		if (initialParams) {
			const { scrollLeft, scrollTop } = initialParams;
			if (scrollLeft)
				scrollBlock.scrollLeft(scrollLeft);
			if (scrollTop)
				scrollBlock.scrollTop(scrollTop);
		}

		viewBlock.style.cursor = "default";

		const mouseMoveHandler = (e) => {
			// How far the mouse has been moved
			const dx = e.clientX - position.x;
			const dy = e.clientY - position.y;

			// Scroll the element
			scrollBlock.scrollTop(position.top - dy);
			scrollBlock.scrollLeft(position.left - dx);
		};

		const mouseUpHandler = () => {
			viewBlock.style.cursor = "default";
			viewBlock.style.removeProperty("user-select");

			document.removeEventListener("mousemove", mouseMoveHandler);
			document.removeEventListener("mouseup", mouseUpHandler);
		};

		const mouseDownHandler = (e) => {
			viewBlock.style.cursor = "default";
			viewBlock.style.userSelect = "none";

			position.left = scrollBlock.getScrollLeft();
			position.top = scrollBlock.getScrollTop();
			position.x = e.clientX;
			position.y = e.clientY;

			document.addEventListener("mousemove", mouseMoveHandler);
			document.addEventListener("mouseup", mouseUpHandler);
		};

		viewBlock.addEventListener("mousedown", mouseDownHandler);

		return () => {
			document.removeEventListener("mousemove", mouseMoveHandler);
			document.removeEventListener("mouseup", mouseUpHandler);
		};
	}, []);

	return (
		<Scrollbars
			{...params}
			renderView={(props) => <div className='scrollbars' {...props} {...viewProps} />}
			ref={scrollRef}
		>
			{children}
		</Scrollbars>
	);
}