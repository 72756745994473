import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

import ImageWithZoom from '../../../../../ImageWithZoom';
import { AIBlock, AssistantAiChatMessage } from '../../../../../../models/Test';
import { interfaceText, locale } from '../../../../../../helpers';
import Input from '../Input';
import Button from '../../../../../Button';
import useAIChat from '../../hooks/useAIChat';
import _, { uniqueId } from 'lodash';
import Loader from '../../../../../Loader';
import { ChatStatus } from '../../../../../../Common/schema/Chats/CreateChatRequestDTO';

import { Preview } from '../ContentBlock/Preview';
import { Stepper } from '../ContentBlock/Stepper';

import useTypingAnimation from '../../hooks/useTypingAnimation';
import useIsMobile from '../../hooks/useIsMobile';

import { IconAI } from '../../../../../../icons';


export interface AiOpenQuestionProps {
  block: AIBlock;
  testId: string;
  blockId: string;
  answerId: string;
  isPreview: boolean;
  withProgress: boolean;
  currentStep: number;
  totalSteps: number;
  isLoading: boolean;
  onChatComplete: (chatId: number) => void;
}

export default function AiOpenQuestion(props: AiOpenQuestionProps) {
  const [message, setMessage] = useState<string>('');
  
  const {
    isStarted,
    isCompleted,
    chat,
    sendMessage,
    isAIMessageLoading,
    startChat
  } = useAIChat({ testId: props.testId, answerId: props.answerId, blockId: props.blockId, isPreview: props.isPreview });

  const isMobile = useIsMobile();

  const startMessage = new AssistantAiChatMessage(props.block.text);
  const [currentMessage, setCurrentMessage] = useState<AssistantAiChatMessage | null>(startMessage);

  useEffect(() => {
    if (!isStarted) {
      startChat();
    }
  }, []);

  useEffect(() => {
    if (chat && chat.messages.length) {
      const assistantMessages = chat.messages.filter(m => !m.isUser) as AssistantAiChatMessage[];
      setCurrentMessage(_.last(assistantMessages) || startMessage);
    }
  }, [chat]);

  useEffect(() => {
    if (chat?.chat.status === ChatStatus.Completed) {
      props.onChatComplete(chat?.chat.id as number);
    }
  }, [props.onChatComplete, chat])

  function onTextChanged(text: string) {
    setMessage(text);
  }

  function onButtonClicked() {
    sendMessage(message);
    setMessage('');
  }

  const {text: animatedText, isTypingComplete } = useTypingAnimation(currentMessage?.text || '', 40);
  // Use animation only for follow-up questions
  const isFollowUp = currentMessage?.text !== startMessage.text;
  const questionText = isFollowUp ? animatedText : currentMessage?.text;

if (!props.block.image) {
  return (
    <div className="flex flex-col items-center h-[100dvh] max-h-[100dvh]">
      {props.isPreview && <Preview />}
      {props.withProgress && <Stepper currentStep={props.currentStep} totalSteps={props.totalSteps} />}
      {isAIMessageLoading ? (
        <Loader />
      ) : (
        <div className="ai-question__container w-full flex flex-col justify-center flex-1 p-6 sm:w-4/5 md:w-3/5 lg:w-1/2 xl:w-1/3">
          {isFollowUp && (
            <span className="flex items-center text-xs text-blue-400 mb-3  font-medium">
              <IconAI className="fill-current text-blue-400 w-4 h-4 mr-2" />
              {(interfaceText.test as any)[locale()].followUpQuestion}
            </span>
          )}
          <div className="ai-question__title w-full text-lg">{questionText}</div>
            <div className="ai-question__input w-full">
              <Input
                singleLine={false}
                placeholder={(interfaceText.test as any)[locale()].inputPlaceholder}
                id={`${props.blockId}-response`}
                handler={(_: string, value: string) => onTextChanged(value)}
                designConfig={{}}
                className="test-textarea"
                initialValue={message}
              />
            </div>
            <Button
              className={clsx("ai-question__button")}
              id="nextButton"
              type="primary"
              name={(interfaceText.test as any)[locale()].nextButton}
              handler={() => onButtonClicked()}
              large
              disabled={!message || !isTypingComplete}
            />
        </div>
      )}
    </div>
  );
} else {
  return (
    <div className="h-[100dvh] flex flex-col items-center">
      {props.isPreview && <Preview />}
      {props.withProgress && <Stepper currentStep={props.currentStep} totalSteps={props.totalSteps} />}
      <div className="grid grid-auto gap-8 grid-cols-1 lg:grid-rows-1 lg:grid-cols-2 w-full flex-1 overflow-y-auto">
        <div className={clsx("flex justify-center items-center p-6 md:p-8", !isMobile && "bg-gray-100")}>
          <ImageWithZoom
            imageSrc={props.block.image}
            className={clsx("lg:mt-0 max-w-full object-contain block", isMobile ? "max-h-[50dvh]" : "max-h-[80dvh]")}
            onLoad={() => {
              // onImageLoaded("image");
            }}
          />
        </div>
        <div className="flex flex-col items-center justify-center w-full h-full px-4 flex-1 p-6 md:p-8">
          {isAIMessageLoading ? (
            <Loader />
          ) : (
            <div className="ai-question__container w-full flex flex-col justify-center flex-1 max-w-[440px]">
              <div className="ai-question__title w-full text-lg">{questionText}</div>
              <div className="ai-question__input w-full">
                <Input
                  singleLine={false}
                  placeholder={(interfaceText.test as any)[locale()].inputPlaceholder}
                  id={`${props.blockId}-response`}
                  handler={(_: string, value: string) => onTextChanged(value)}
                  designConfig={{}}
                  className="test-textarea"
                  initialValue={message}
                />
              </div>
              <div className="my-4 w-full">
                <Button
                  className="ai-question__button"
                  id="nextButton"
                  type="primary"
                  name={(interfaceText.test as any)[locale()].nextButton}
                  handler={() => onButtonClicked()}
                  large
                  disabled={!message}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
}