import React, { useState, useRef } from "react";
import parse from "html-react-parser";
import _ from "lodash";
import "../../../../../tailwind.generated.css";
import clsx from "clsx";

import { PreferenceBlock, Block } from "../../../../../models/Test";
import { IconUploadimage } from "../../../../../icons";
import { Stepper } from "./ContentBlock/Stepper";
import TaskSidebar from "../TaskSidebar";
import PreferenceSelect from "./PreferenceSelect";
import Button from "../../../../Button";

import useIsMobile from "../hooks/useIsMobile";

import { locale, interfaceText } from "../../../../../helpers";

type TaskProps = {
  blockData: PreferenceBlock & Block;
};

function Task(props: TaskProps) {
  return (
    <div>
      <span className="block font-medium mb-2">{(interfaceText as any).test[locale()].task}</span>
      <div className="text-lg flex-shrink-0 break-words flex flex-col w-full">
        <div>{parse(props.blockData.questionHtml || props.blockData.text)}</div>
      </div>
    </div>
  );
}

type PreferenceProps = {
  blockData: PreferenceBlock & Block;
  blockAnswer: any;
  handleBlockAnswer: any;
  nextStepOnClick: any;
  withProgress?: boolean;
  totalSteps?: number;
  currentStep?: number;
  responseStart?: any;
  setResponseStart?: any;
};

function Preference(props: PreferenceProps) {
  const isMobile = useIsMobile();

  const [sidebarState, setSidebarState] = useState<"hidden" | "default" | "overlay">("overlay");

  const taskStarted = !!props.responseStart;
  const isValid = props.blockAnswer?.selectedOptions && props.blockAnswer?.selectedOptions.length > 0;

  const taskContainerRef = useRef<HTMLDivElement | null>(null);

  return (
    <>
      <div
        className={clsx("flex-1 flex max-h-full h-full overflow-y-auto w-full", isMobile ? "flex-col" : "flex-row")}
        ref={taskContainerRef}
      >
        <TaskSidebar
          sidebarState={sidebarState}
          setSidebarState={setSidebarState}
          parentRef={taskContainerRef}
          sidebarContent={
            <div className="h-full flex flex-col">
              {props.withProgress && <Stepper totalSteps={props.totalSteps} currentStep={props.currentStep} noPadding />}
              <div className="flex flex-col flex-1 justify-between">
                <Task blockData={props.blockData} />
                <div className="border-t-1 border-gray-300 pt-6">
                {taskStarted ? (
                  <Button
                    type="primary"
                    name={(interfaceText as any).test[locale()].allDone}
                    handler={() => {
                      props.nextStepOnClick();
                    }}
                    disabled={!isValid}
                    className="whitespace-nowrap"
                    fullWidth
                    large
                  />
                ) : (
                  <Button
                    type="primary"
                    icon={<IconUploadimage className="fill-current text-white w-5 h-5 mr-2" />}
                    name={(interfaceText as any).preferenceTesting[locale()].showImages}
                    handler={() => {
                      props.setResponseStart(_.now());
                      if (isMobile) {
                        setSidebarState("hidden");
                      } else {
                        setSidebarState("default");
                      }
                    }}
                    className="whitespace-nowrap"
                    large
                  />
                )}
                </div>
              </div>
            </div>
          }
          content={
            <>
              {taskStarted && isMobile && (
                <div className="py-4 px-6">
                  <Task blockData={props.blockData} />
                </div>
              )}
              <PreferenceSelect
                data={props.blockData.replies}
                isRandomized={props.blockData.randomized}
                handler={props.handleBlockAnswer}
                responseStart={!!props.responseStart}
              />
              {taskStarted && isMobile && (
                <div className="w-full border-t-1 border-gray-300 sticky bottom-0 bg-white">
                  <Button
                    type="primary"
                    name={(interfaceText as any).test[locale()].allDone}
                    handler={() => {
                      props.nextStepOnClick();
                    }}
                    disabled={!isValid}
                    className="whitespace-nowrap mx-4 my-4"
                    fullWidth
                    large
                  />
                </div>
              )}
            </>
          }
        ></TaskSidebar>
      </div>
    </>
  );
}

export default Preference;
