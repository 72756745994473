import React from "react";
import "../tailwind.generated.css";

import { ReactComponent as WarningIcon } from "../icons/error.svg";

import clsx from "clsx";

const Warning = ({
  text,
  header,
  className,
}: {
  text: string;
  header: string;
  className?: string;
}) => {
  return (
    <div
      className={clsx(
        "p-4 w-full text-gray-800 bg-orange-200 rounded-lg text-sm",
        className && className
      )}
    >
      <div className="mr-2 flex items-center">
        <WarningIcon
          className="fill-current text-gray-800 mr-2"
          width={24}
          height={24}
        />
        {header && <span className="font-medium block">{header}</span>}
      </div>
      <span className="block mt-2 font-normal">{text}</span>
    </div>
  );
};

export default Warning;
