import React, { useEffect, useRef } from "react";
import "../../tailwind.generated.css";
import _ from "lodash";
import { FIGMA_PROTOTYPES_BASE_URL } from "./constants";
import useFigmaContext from "./hooks/useFigmaContext";
import { IPrototypeOptions } from './Models';
import { CanvasSize } from '../../models/Response';

const DEFAULT_PROTOTYPE_BG_COLOR = "000000";
const DEFAULT_PROTOTYPE_SCALING = "scale-down";

const getPrototypeUrl = (options: IPrototypeOptions) => {
  const { fileId, startNodeId, fileVersion, scaling = DEFAULT_PROTOTYPE_SCALING, bg = DEFAULT_PROTOTYPE_BG_COLOR } = options;
  let url = `${FIGMA_PROTOTYPES_BASE_URL}/proto/${fileId}/fileName?starting-point-node-id=${encodeURIComponent(startNodeId)}&hide-ui=1&hotspot-hints=0&scaling=${scaling}&version-id=${''}&disable-default-keyboard-nav=1&bg-color=${bg}&node-id=${encodeURIComponent(startNodeId)}`;
  if (options.debug) url += "&debug=true";
  if (options.watchscroll) url += "&watchscroll=true";
  if (options.video) url += "&video=true";
  if (options.fps) url += `&fps=${options.fps}`;
  url += `&pwhost=${window.location.host}`;
  return url;
};

interface FigmaIFrameProps {
  options: IPrototypeOptions;
  width: number | string;
  height: number | string;
  className?: string;
  onPresentedNodeChanged?: (data?: any) => void;
  onClick?: (data?: any) => void;
  onLoad?: () => void;
  onUploadComplete?: () => void;
  onUploadFailed?: () => void;
  onSizeRetrieved?: (data: CanvasSize) => void;
};

const FigmaIFrame = ({
  onPresentedNodeChanged,
  onClick,
  onLoad,
  onUploadComplete,
  onUploadFailed,
  ...props
}: FigmaIFrameProps) => {

  const prototypeUrl = getPrototypeUrl(props.options);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const figmaContext = useFigmaContext();

  useEffect(() => {
    if (figmaContext && !figmaContext.sendMessageHandler) {
      figmaContext?.setCallback(onMessageCallback);
    }

    return () => figmaContext?.removeCallback();
  }, [figmaContext]);

  useEffect(() => {
    window.addEventListener("message", messageHandler);
    return () => {
      window.removeEventListener("message", messageHandler);
    };
  }, [onPresentedNodeChanged, onClick, onLoad, onUploadComplete, onUploadFailed, figmaContext]);

  function onMessageCallback(message: any) {
    iframeRef?.current?.contentWindow?.postMessage(message, "*");
  }

  function messageHandler(event: MessageEvent) {
    const { type, data: eventData } = event.data;

    if (type === "MOUSE_PRESS_OR_RELEASE") {
      if (!eventData.targetNodeId) return;
      try {
        const event = {
          clickData: {
            nodeId: eventData.targetNodeId,
            x: !!eventData.nearestScrollingFrameMousePosition ? eventData.nearestScrollingFrameMousePosition.x : eventData.targetNodeMousePosition.x,
            y: !!eventData.nearestScrollingFrameMousePosition ? eventData.nearestScrollingFrameMousePosition.y : eventData.targetNodeMousePosition.y,
            ...eventData
          }
        }
        onClick?.(event);
      } catch (error) {
        console.error(error, eventData);
      }
    }
    if (type === "INITIAL_LOAD") {
      onLoad?.();
    }
    if (type === "PW_CANVAS_SIZE") {
      props.onSizeRetrieved?.(eventData as any);
    }

    if (type === "PRESENTED_NODE_CHANGED") {
      onPresentedNodeChanged?.(eventData);
      figmaContext?.triggerOnScreenChanged(eventData);
    }

    if (type === "UPLOAD_COMPLETE") {
      console.log("UPLOAD_COMPLETE", eventData);
      onUploadComplete?.();
      figmaContext?.recordUploadComplete(eventData.recordId);
    }
    if (type === "UPLOAD_FAILED") {
      onUploadFailed?.();
      figmaContext?.recordUploadFailed(eventData.recordId, eventData.error);
    }

    if (type === "PW_READY") {
      figmaContext?.triggerOnPrototypeReady();
    }

    if (type === "PW_SCROLL") {
      figmaContext?.scroll(eventData);
    }

    if (type === "PW_GET_NODE_RESPONSE") {
      figmaContext?.onGetNodeResponse(eventData);
    }

    if (type === "PW_KEYDOWN") {
      figmaContext?.onKeydown(eventData);
    }

    if (type === "PW_GET_RENDER_DATA_RESPONSE") {
      figmaContext?.onGetRenderData(eventData);
    }
  }

  return <iframe id={props.options.fileId + "_figma"} className={props.className} height={props.height} width={props.width} src={prototypeUrl} ref={iframeRef} />;
};

export default FigmaIFrame;
