import { IActionResult } from '../AppContext';
import { ApiException } from '../Common/Exceptions/ApiException';
import { INextTestDTO } from '../DTO/SplitGroups/INextTestDTO';
import { makeAuthorizedRequest, makeRequest } from '../http/Client';
import { ISplitGroup } from '../models/Test';


const splitGroupsApiPath = '/api/v1/tests/splitgroup';

export type SplitGroupUpdate = Partial<Pick<ISplitGroup, 'folderId' | 'name'>>;

export class SplitGroupAPI {

	static async createSplitGroup(projectId: string, folderId: string, name: string, testIds?: string[], signal?: AbortSignal): Promise<IActionResult<ISplitGroup>> {
		const response = await makeAuthorizedRequest(`${splitGroupsApiPath}?projectId=${projectId}`, 'POST', {
			name: name,
			folderId,
			testIds
		}, signal);

		if (response.ok) {
			return {
				type: "CREATE_SPLIT_GROUP",
				payload: {
					data: await response.json() as ISplitGroup,
				},
			};
		}
		throw new Error('http_error')
	}

	public static async listSplitGroups(folderId: string, projectId: string = '', signal?: AbortSignal): Promise<IActionResult<ISplitGroup[]>> {
		const response = await makeAuthorizedRequest(`${splitGroupsApiPath}?projectId=${projectId}&folderId=${folderId}`, 'GET', undefined, signal);

		if (response.ok) {
			return {
				type: "GET_SPLIT_GROUPS",
				payload: {
					data: await response.json() as ISplitGroup[],
				}
			};
		}
		throw new Error('http_error')
	}

	public static async updateSplitGroup(splitGroupId: number | string, projectId: string, update: SplitGroupUpdate, signal?: AbortSignal): Promise<IActionResult<ISplitGroup>> {
		const response = await makeAuthorizedRequest(`${splitGroupsApiPath}/${splitGroupId}?projectId=${projectId}`, 'PUT', {
			...update
		}, signal);

		if (response.ok) {
			return {
				type: "UPDATE_SPLIT_GROUP",
				payload: {
					data: await response.json() as ISplitGroup,
				},
			};
		}

		throw new Error('http_error');
	}

	public static async deleteSplitGroup(splitGroupId: number, signal?: AbortSignal): Promise<IActionResult<number>> {
		const response = await makeAuthorizedRequest(`${splitGroupsApiPath}/${splitGroupId}`, 'DELETE', undefined, signal);

		if (response.ok) {
			return {
				type: "DELETE_SPLIT_GROUP",
				payload: {
					data: splitGroupId
				}
			};
		}

		throw new Error('http_error');
	}

	public static async getSplitGroup(splitGroupId: number, signal?: AbortSignal): Promise<IActionResult<ISplitGroup>> {
		const response = await makeAuthorizedRequest(`${splitGroupsApiPath}/${splitGroupId}`, 'GET', undefined, signal);

		if (response.ok) {
			return {
				type: "GET_SPLIT_GROUP",
				payload: {
					data: await response.json() as ISplitGroup,
				},
			};
		}
		throw new Error('http_error');
	}

	public static async getNextTest(splitGroupCode: string, meta: any, urlParams: Record<string, string>, panelParams: { respId: string | null, panelId: string | null }) {
		const response = await makeRequest(`${splitGroupsApiPath}/${splitGroupCode}/nexttest`, 'POST', { meta, urlParams, ...panelParams });
		if (response.ok) {
			return {
				type: "GET_NEXT_TEST",
				payload: {
					data: await response.json() as INextTestDTO,
				}
			};
		}
		else if (response.status === 400) {
			const result = await response.json();
			throw new ApiException(result.error_code, result.message, response);
		}
		throw new Error('http_error', { cause: response });
	}

}