import { useState, useEffect } from 'react';

import { AIBlock, AssistantAiChatMessage, BaseAiChatMessage, ButtonAddon, ChatMessage, ImageAddon, UserAiChatMessage } from '../../../../../models/Test';

import { useMutation, useQuery } from 'react-query';
import { ChatsAPI } from '../../../../../actions/ChatsAPI';
import { ChatMessageDTO, ChatStatus, CreateChatRequestDTOSchema, MessageDTO } from '../../../../../Common/schema/Chats/CreateChatRequestDTO';
import _ from 'lodash';

interface ChatState { chat: ChatMessageDTO, messages: BaseAiChatMessage[] }

function useAIChat(options: { testId: string, answerId: string, blockId: string, isPreview?: boolean}) {
    const [isStarted, setIsStarted] = useState(false);
    const [isCompleted, setIsCompleted] = useState(false);
    const [chat, setChat] = useState<null | ChatState>(null);

    const chatQuery = useQuery({
        queryKey: ['chats', options],
        queryFn: (ctx) => ChatsAPI.createChat(options),
        enabled: CreateChatRequestDTOSchema.isValidSync(options) && isStarted,
        staleTime: Infinity,
        onSuccess: handleChatQuerySuccess,
        retry: false
    });

    const sendMessageQuery = useMutation({
        mutationKey: ['chats', 'sendMessage', options],
        mutationFn: (message: string) => {
            if (chat) {
                return ChatsAPI.addMessage(chat.chat.id, { text: message });
            }
            // TODO: handle error
            return Promise.reject(null);
        },
        onSuccess(data, variables, context) {
            handleChatQuerySuccess(data);
        },
    })

    function handleChatQuerySuccess(data: ChatMessageDTO) {
        if (data.message !== null) {
            addAssistantMessage(data as ChatMessageDTO & { message: MessageDTO });
        }
        if (data.status === ChatStatus.Completed) {
            updateChat(data);
        }
        return data;
    }

    function addAssistantMessage(chat: ChatMessageDTO & { message: MessageDTO }) {
        const assistantMessage = new AssistantAiChatMessage(chat.message.text);
        updateChat(chat);
        addMessage(assistantMessage);
    }

    function updateChat(chat: ChatMessageDTO) {
        setChat(current => {
            const newState = current ? _.cloneDeep(current) : { chat, messages: [] } as ChatState;
            newState.chat = chat;
            return newState;
        })
    }

    function addUserMessage(message: string) {
        const userMessage = new UserAiChatMessage(message);
        addMessage(userMessage);
    }

    function addMessage(message: BaseAiChatMessage) {
        setChat(current => {
            if (!current) {
                console.warn('Adding chat message while chat is not initialized');
                return current;
            }

            const newState = _.cloneDeep(current);
            newState.messages.push(message);
            return newState;
        });
    }

    function sendMessage(message: string) {
        addUserMessage(message);
        sendMessageQuery.mutate(message);
    }

    function startChat() {
        setIsStarted(true);
    }

    // async function getAIMessage() {
    //     setIsAIMessageLoading(true);
    //     setTimeout(() => {
    //         const aiMessage = {
    //             id: generateId(),
    //             content: 'This is a mock AI response.',
    //             createdAt: new Date().toISOString(),
    //             text: 'This is a mock AI response.',
    //             isUser: false,
    //             withAvatar: false,
    //         };
    //         setChat((current: typeof chat) => ({
    //             ...current,
    //             messages: [...current.messages, aiMessage]
    //         }));
    //         setIsAIMessageLoading(false);
    //     }, 2000);
    // }

    // useEffect(() => {
    //     getAIMessage();
    // }, []);

    return {
        chat,
        isStarted,
        isCompleted,
        sendMessage,
        isAIMessageLoading: chatQuery.isLoading || sendMessageQuery.isLoading,
        startChat
    };
}

export default useAIChat;