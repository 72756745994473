import { useMutation, useQuery, useQueryClient } from 'react-query';
import { makeAuthorizedRequest } from '../../../http/Client';
import { useAuth, useSendNotification } from '../../../hooks';
import { getUserData } from '../../../actions';
import { useContext, useEffect } from 'react';
import { useLocalStorage } from '../../Common/Hooks/useLocalStorage';
import AppContext from '../../../AppContext';
import { IUser } from '../../../models/User';
import { IAccount, ITariffPlan } from '../../../models/Account/IAccount';
import { IProject } from '../../../models/Project/IProject';
import { useTranslation } from 'react-i18next';

export interface ICreditsOrderRequest {
  quantity: number;
  paymentMethodCode?: 'tinkoff_rf';
  paymentMethodId?: number;
}

export interface ISubscriptionOrderRequest {
  tariffId: number;
  paymentMethodCode?: 'tinkoff_rf';
  paymentMethodId?: number;
}

export interface IOrderResponse {
  result?: 'error';
  status: 'success' | 'confirmation_required',
  payment_url?: string
}

export interface ICurrentUserData {
  user: IUser;
  account: IAccount;
  currentProject: IProject | null;
  credits: any;
  subscription: any;
  usage: {
    tests: number;
    responses: number;
    users: number;
    exceededAnswersLimit: boolean;
    exceededTestsLimit: boolean;
  };
}

export function useAccount() {
  const setNotification = useSendNotification();
  const user = useAuth();
  const queryClient = useQueryClient()
  const localStorage = useLocalStorage();
  const context = useContext(AppContext);
  const { t } = useTranslation('translation', { keyPrefix: 'settings' });

  const currentUser = useQuery<ICurrentUserData>('user', {
    queryFn: () => getUserData(user).then(d => {
      Object.assign(d.payload.usage, getUsageWarnings(d.payload.account.currentSubscription.tariff, d.payload.usage));
      context.dispatch(d);
      return d.payload;
    }).catch((e) => {
      if (e.cause?.status === 504) {
        setNotification("error", "Oops, something went wrong. Maybe you`re offline?");
      } else {
        setNotification("error", "Oops, something went wrong.");
      }
      console.error(e);
    }),
    enabled: !!user,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: false
  });

  const cancelSubscription = useMutation({
    mutationFn: () => makeAuthorizedRequest('/api/v1/account/subscription', 'PUT', { isRecurring: false }).then(toJson),
    onSuccess: () => { queryClient.invalidateQueries('user') },
    onError: (error) => {
      console.log(error);
      setNotification('error', t('subscription.cancel.error_notification'));
    },
  });

  const resumeSubscription = useMutation({
    mutationFn: () => makeAuthorizedRequest('/api/v1/account/subscription', 'PUT', { isRecurring: true }).then(toJson),
    onSuccess: () => { queryClient.invalidateQueries('user') },
    onError: (error) => {
      console.log(error);
      setNotification('error', t('subscription.resume.error_notification'));
    },
  });

  const upgradeSubscription = useMutation<{ id: number }, Error, ISubscriptionOrderRequest>({
    mutationFn: async (request) => {
      const orderRequest = { productCode: 'subscription', ...request };
      localStorage.setItem('last_order_request', orderRequest);
      const response = await makeAuthorizedRequest('/api/v1/orders', 'POST', orderRequest).then(toJson);
      if (response.result === 'error') {
        setNotification('error', t('subscription.buy.error_notification'));
      }
      return response;
    },
    onError: (error) => {
      console.log(error);
      setNotification('error', t('subscription.buy.error_notification'));
    },
  });

  const buyCredits = useMutation<{ id: number }, Error, ICreditsOrderRequest>({
    mutationFn: (request) => {
      const orderRequest = { productCode: 'credits', ...request };
      localStorage.setItem('last_order_request', orderRequest);
      return makeAuthorizedRequest('/api/v1/orders', 'POST', orderRequest).then(toJson);
    },
    onError: (error) => {
      console.log(error);
      setNotification('error', t('credits.purchase.error_notification'));
    },
  });

  const addPaymentMethod = useMutation<{ id: number }, Error>({
    mutationFn: () => {
      const request = { productCode: 'add_payment_method', paymentMethodCode: 'tinkoff_rf' };
      localStorage.setItem('last_order_request', request);
      console.log(request);
      return makeAuthorizedRequest('/api/v1/orders', 'POST', request).then(toJson);
    },
    onError: (error) => {
      console.log(error);
      setNotification('error', t('payment_methods.add.error_notification'));
    }
  });

  const capturePayment = useMutation<IOrderResponse, Error, number>({
    mutationFn: (paymentId: number) => makeAuthorizedRequest(`/api/v1/orders/${paymentId}/capture`, 'POST').then(toJson),
    onError: (error) => {
      console.log(error);
      setNotification('error', t('payment.error_notification'));
    },
    onSuccess: (capture) => {
      if (capture.result === 'error') {
        setNotification('error', t('payment.error_notification'));
        return;
      }

      if (capture.status === 'success') {
        queryClient.invalidateQueries('user');
      }
      if (capture.status === 'confirmation_required') {
        window.open(capture.payment_url, '_blank');
      }
    }
  });

  const removePaymentMethod = useMutation({
    mutationFn: (paymentMethodId: number) => makeAuthorizedRequest(`/api/v1/account/payment_methods/${paymentMethodId}`, 'DELETE').then(toJson),
    onSuccess: (response) => {
      if (response.result === 'error') {
        throw response;
      }
      queryClient.invalidateQueries('user');
      setNotification('warning', t('payment_methods.remove.success_notification'));
    },
    onError: (error) => {
      console.log(error);
      setNotification('error', t('payment_methods.remove.error_notification'));
    },
  });


  return {
    cancelSubscription,
    resumeSubscription,
    upgradeSubscription,
    buyCredits,
    capturePayment,
    currentUser,
    addPaymentMethod,
    removePaymentMethod
  }


}

function toJson(response: any) {
  return response.json();
}

export function getUsageWarnings(tariff: ITariffPlan, usage: { tests: number; responses: number; users: number; }) {
  return {
    exceededAnswersLimit: (tariff.responsesLimit > 0)
      ? usage.responses >= tariff.responsesLimit
      : false,
    exceededTestsLimit: (tariff.testsLimit > 0)
      ? usage.tests >= tariff.testsLimit
      : false,
  };
}