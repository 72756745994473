import axios from 'axios';
import { AddChatMessageRequestDTO, ChatMessageDTO, CreateChatRequestDTO } from '../Common/schema/Chats/CreateChatRequestDTO';

export const basePath = '/api/v1/chats';

export class ChatsAPI {

	public static async createChat(request: CreateChatRequestDTO) {
		const response = await axios.post<ChatMessageDTO>(basePath, request);

		return response.data;
	}

	public static async addMessage(chatId: number, request: AddChatMessageRequestDTO) {
		const response = await axios.post<ChatMessageDTO>(`${basePath}/${chatId}/messages`, request);

		return response.data;
	}


}

