import React from "react";
import { v4 as uuidv4 } from "uuid";

export const replyTypes = [
  { name: "Free text input", type: "freeform" },
  { name: "Single Choise", type: "single" },
  { name: "Multiple Choise", type: "multi" },
  { name: "Scale 0 to 5", type: "scale" },
];

export function isGlobalApp() {
  return window.pwconfig.firebase_config.projectId === "pathway-global";
}

function generateId() {
  return uuidv4();
}

export const initialContent = () => {
  const getReplies = (withImage: boolean) => {
    return [
      { id: generateId(), replyValue: "", ...(withImage ? { image: "" } : {}) },
      { id: generateId(), replyValue: "", ...(withImage ? { image: "" } : {}) },
    ];
  };

  return {
    openquestion: {
      image: "",
      text: "",
      type: "openquestion",
      isOptional: false,
    },
    choice: {
      image: "",
      replies: getReplies(false),
      replyType: "single",
      randomized: false,
      other: false,
      isOptional: false,
      text: "",
      type: "choice",
    },
    preference: {
      replies: getReplies(true),
      replyType: "single",
      randomized: true,
      text: "",
      type: "preference",
      doNotShowZoomedImage: false,
    },
    firstclick: {
      image: "",
      text: "",
      type: "firstclick",
    },
    fiveseconds: {
      image: "",
      time: 5,
      withCustomInstruction: false,
      type: "fiveseconds",
    },
    figma: {
      fileId: "",
      text: "",
      goalNode: [],
      type: "figma",
      nodeImages: {},
      nodeNames: {},
      scaling: "scale-down-width",
    },
    context: {
      image: "",
      text: "",
      type: "context",
    },
    cardsort: {
      text: "",
      cards: [],
      categories: [],
      shuffleCards: true,
      shuffleCategories: true,
      doNotRequireToSortAll: false,
      withImages: false,
      withDescription: false,
      type: "cardsort",
    },
    matrix: {
      text: "",
      rows: [],
      columns: [],
      replyType: "single",
      type: "matrix",
    },
    scale: {
      image: "",
      text: "",
      replyType: "numeric",
      from: "1",
      to: "5",
      selections: "5",
      startLabel: "",
      endLablel: "",
      isOptional: false,
      type: "scale",
    },
    ai: {
      type: "ai",
      text: "",
      instruction: "",
      isOptional: false,
    }
  };
};

export function getLocale() {
  const sessionLanguage = window.sessionStorage.getItem("language");
  if (sessionLanguage && sessionLanguage.length > 0 && sessionLanguage !== "auto") {
    return sessionLanguage;
  } else {
    const lang = navigator.language;
    // flag for the demo test
    const setEn = window.location.href.includes("pEGg41U2IsIDXQqvEot0");
    if (setEn === true) {
      return "en";
    }
    if (lang.includes("ru")) {
      return "ru";
    } else if (lang.includes("uz")) {
      return "uz";
    } else if (lang.includes("en" || "UK" || "US")) {
      return "en";
    } else {
      return "en";
    }
  }
}

export const locale = getLocale;

// export const translate = (text) => {
//   const locale = getLocale();
//   if (locale !== "en") {
//     return localizationTexts[text] ? localizationTexts[text] : text;
//   } else {
//     return text;
//   }
// };

const localizationTexts = {};

export const interfaceText = {
  preferenceTesting: {
    ru: {
      showImages: "Показать изображения",
    },
    en: {
      showImages: "Show images",
    },
    uz: {
      showImages: "Rasmni ko'rsatish",
    },
  },
  firstclickTesting: {
    ru: {
      confirmButton: "Подтвердить",
      cancelButton: "Отмена",
      showImage: "Показать изображение",
      task: "Задание",
    },
    en: {
      confirmButton: "Confirm click",
      cancelButton: "Cancel",
      showImage: "Show image",
      task: "Task",
    },
    uz: {
      confirmButton: "Tasdiqlash",
      cancelButton: "Bekor qilish",
      showImage: "Rasmni ko'rsatish",
    }
  },
  figmaTestingIntro: {
    ru: {
      header: "Задание с прототипом",
      instruction: "Инструкция",
      text: "Сейчас вы увидите макеты с элементами, на которые можно нажимать. Некоторые элементы на макетах могут не работать или быть недоступными для нажатия.",
      button: "Хорошо",
    },
    en: {
      header: "Prototype task",
      instruction: "Instruction",
      text: "You'll see a prototype with interactive elements. It’s not a final product, some elements may not work or be clickable.",
      button: "Got it",
    },
    uz: {
      header: "Prototip bilan vazifa",
      instruction: "Qo'llanma",
      text: "Siz interaktiv elementlarga ega prototipni ko'rasiz. Bu yakuniy mahsulot emas, ba'zi elementlar ishlamaydi yoki bosib bo'lmaydi.",
      button: "Tushunarli",
    }
  },
  figmaTestingMission: {
    ru: {
      header: "Ваше задание",
      button: "Начать",
      buttonWhenMissionStarted: "Продолжить",
      loading: "Подождите немного...",
    },
    en: {
      header: "Your task",
      button: "Let's start",
      buttonWhenMissionStarted: "Back to task",
      loading: "Hold on for a second...",
    },
    uz: {
      header: "Sizning vazifangiz",
      button: "Boshlash",
      buttonWhenMissionStarted: "Vazifaga qaytish",
      loading: "Bir ozgina kuting...",
    }
  },
  figmaTesting: {
    ru: {
      showTaskTip: "Если вы забыли задание или хотите сдаться — нажмите на синюю полоску",
      showTaskTipButton: "Хорошо",
      giveUpButton: "Не могу выполнить задание",
      missionButton: "Показать задание",
    },
    en: {
      showTaskTip: "In case you forgot the task or want to give up — click on this blue stripe.",
      showTaskTipButton: "Ok",
      giveUpButton: "Give up",
      missionButton: "Show mission",
    },
    uz: {
      showTaskTip: "Agar vazifani unutib qolsangiz yoki vazifani bajaramasangiz — ko'k chiziqni bosing.",
      showTaskTipButton: "Tushunarli",
      giveUpButton: "Vazifani bajara olmayman",
      missionButton: "Vazifani ko'rsatish",
    }
  },
  figmaTestingSuccess: {
    ru: {
      header: "Поздравляем!",
      button: "Далее",
      text: "Вы успешно справились с заданием.",
    },
    en: {
      header: "Congratulations",
      button: "Next",
      text: "You successfully accomplished the mission.",
    },
    uz: {
      header: "Tabriklaymiz!",
      button: "Keyingi",
      text: "Siz vazifani muvaffaqiyatli bajardingiz.",
    }
  },
  thankYou: {
    ru: "Спасибо за участие",
    en: "Thank you for participating",
    uz: "Qatnashganingiz uchun rahmat",
  },
  thankYouButton: {
    ru: "Завершить тест",
    en: "Complete the test",
    uz: "Testni yakunlash",
  },
  pageNotFound: {
    ru: { header: "Страница не найдена", text: "Простите, здесь ничего нет" },
    en: { header: "Page Not Found", text: "Sorry, nothing here" },
    uz: { header: "Sahifa topilmadi", text: "Kechirasiz, bu erda hech narsa yo'q" },
  },
  testIsntAcceptingNewResponses: {
    ru: "Этот тест больше не принимает новые ответы.",
    en: "This test isn't accepting new responses.",
    uz: "Bu test yangi javoblar qabul qilmaydi.",
  },
  requestFailed: {
    ru: { header: "Не удалось загрузить данные", button: "Попробовать еще" },
    en: { header: "Failed to load data", button: "Try again" },
    uz: { header: "Ma'lumotlar yuklanmadi", button: "Qayta urinib ko'ring" },
  },
  verificationCode: {
    ru: "Код подтверждения:",
    en: "Verification code:",
    uz: "Tasdiqlash kodi:",
  },
  deviceIsNotAllowed: {
    ru: {
      mobile: "Этот тест доступен для прохождения только на мобильном устройстве. Пожалуйста, откройте его на телефоне.",
      desktop: "Этот тест доступен для прохождения только на десктопном устройстве. Пожалуйста, откройте его на компьютере.",
    },
    en: {
      mobile: "This test is available only on mobile devices. Please, open it on your phone.",
      desktop: "This test is available only on desktop devices. Please, open it on your PC.",
    },
    uz: {
      mobile: "Bu testni faqat mobil qurilmada o'tish mumkin. Iltimos, uni telefonda oching.",
      desktop: "Bu testni faqat desktop qurilmada o'tish mumkin. Iltimos, uni kompyuterda oching.",
    },
  },
  home: {
    ru: {
      settings: "Настройки",
      needHelp: "Нужна помощь?",
      createTest: "+ Новый тест",
      signOut: "Выйти",
      myProjects: "Мои тесты",
    },
    en: {
      settings: "Settings",
      needHelp: "Need help?",
      createTest: "+ New test",
      signOut: "Sign out",
      myProjects: "My tests",
    },
    uz: {
      settings: "Sozlamalar",
      needHelp: "Yordam kerakmi?",
      createTest: "+ Yangi test",
      signOut: "Chiqish",
      myProjects: "Mening testlarim",
    },
  },
  test: {
    ru: {
      followUpQuestion: "Дополнительный вопрос",
      fiveSecondsHeader: "Сосредоточьтесь на изображении",
      fiveSecondsText:
        "Вы будете просматривать изображение в течение ограниченного времени. Постарайтесь понять, о чем это изображение, и запомните как можно больше информации.",
      inputPlaceholder: "Введите текст ответа",
      nextButton: "Далее",
      preferenceNextButton: "Выбрать",
      preferenceZoomedImageCloseButton: "Закрыть",
      multipleChoiceTip: "Вы можете выбрать несколько вариантов ответа.",
      multipleChoiceWithLimitTip: "Вы можете выбрать до {{count}} вариантов ответа.",
      other: "Другое",
      otherPlaceholder: "Введите текст ответа",
      cardSortFinished: "Все карточки отсортированы",
      cardSortCategories: "Категории",
      cardSortCards: "Карточки",
      cardSortLeft: "осталось",
      cardSortNextButton: "Завершить сортировку",
      cardSortStart: "Начать",
      cardSortTask: (
        <>
          Пожалуйста, отсортируйте карточки по представленным категориям. <br />
          <br />В этом задании нет правильных или неправильных ответов, просто распределите карточки так, как вам кажется наиболее
          подходящим.
        </>
      ),
      cardSort: "Сортировка карточек",
      dragCardsToCreateCategories: "Перетащите карточки, чтобы создать категорию",
      dragCardHereToCreateCategory: "Перетащите сюда, чтобы создать категорию",
      enterCategoryName: "Введите название категории",
      dragCardHereToAddToCategory: "Перетащите сюда, чтобы добавить в категорию",
      cardSortTaskTitle: "Задание",
      cards: "Карточки",
      allSorted: "Все карточки отсортированы",
      allDone: "Готово",
      openSortInstuction: "Отсортируйте каждую карточку в категорию, которая вам кажется наиболее подходящей. Перетащите карточки в правую часть страницы, чтобы создать категории.",
      closedSortInstuction: "Отсортируйте каждую карточку в категорию, которая вам кажется наиболее подходящей.",
      doWhatComesNaturally: "Просто делайте то, что кажется вам наиболее подходящим, нет правильных или неправильных ответов.",
      start: "Начать",
      instruction: "Инструкция",
      task: "Задание",
    },
    en: {
      followUpQuestion: "Follow-up question",
      fiveSecondsHeader: "Focus on the image",
      fiveSecondsText:
        "You will view an image for a limited time. Try to understand what the image is about and remember as much information as you can.",
      inputPlaceholder: "Enter reply here",
      nextButton: "Next",
      preferenceNextButton: "Choose this one",
      preferenceZoomedImageCloseButton: "Сlose",
      multipleChoiceTip: "You can select multiple options.",
      multipleChoiceWithLimitTip: "You can select up to {{count}} options.",
      other: "Other",
      otherPlaceholder: "Enter reply here",
      cardSortFinished: "You've sorted all of the cards",
      cardSortCategories: "Categories",
      cardSortCards: "Cards",
      cardSortLeft: "left",
      cardSortNextButton: "Finish sorting",
      cardSortStart: "Let's start",
      cardSortTask: (
        <>
          Sort each card into the category that makes sense to you.
          <br />
          <br />
          Do what comes naturally, there is no right or wrong answers.
        </>
      ),
      cardSort: "Card sort",
      dragCardsToCreateCategories: "Drag cards here to create categories",
      dragCardHereToCreateCategory: "Drag card here to create category",
      enterCategoryName: "Enter category name",
      dragCardHereToAddToCategory: "Drag card here to add to category",
      cardSortTaskTitle: "Task",
      cards: "Cards",
      allSorted: "You've sorted all of the cards",
      allDone: "I'm done",
      openSortInstuction: "Sort each card into the category that makes sense to you. Drag and drop cards to the right part of the page to create categories.",
      closedSortInstuction: "Sort each card into the category that makes sense to you.",
      doWhatComesNaturally: "Do what comes naturally, there is no right or wrong answers.",
      start: "Start",
      instruction: "Instruction",
      task: "Task",
      preferenceTitle: "Image choice",
    },
    uz: {
      fiveSecondsHeader: "Rasmga e'tibor bering",
      fiveSecondsText:
        "Siz rasmni cheklangan vaqt davomida ko'rasiz. Ushbu rasm nima haqida ekanligini tushunishga harakat qiling va iloji boricha ko'proq ma'lumotni eslab qoling.",
      inputPlaceholder: "Javob matnini kiriting",
      nextButton: "Keyingi",
      preferenceNextButton: "Tanlash",
      preferenceZoomedImageCloseButton: "Yopish",
      multipleChoiceTip: "Siz bir nechta javob variantlarini tanlashingiz mumkin.",
      multipleChoiceWithLimitTip: "Siz {{count}} tanovchi tanlashingiz mumkin.",
      other: "Boshqa",
      otherPlaceholder: "Javob matnini kiriting",
      cardSortFinished: "Barcha kartochkalar saralangan",
      cardSortCategories: "Toifalar",
      cardSortCards: "Kartochkalar",
      cardSortLeft: "qoldi",
      cardSortNextButton: "Saralashni yakunlash",
      cardSortStart: "Boshlash",
      cardSortTask: (
        <>
          Iltimos, kartochkalarni ko'rsatilgan toifalar bo'yicha saralang. <br />
          <br />Bu vazifada to'g'ri yoki noto'g'ri javoblar yo'q, shunchaki kartalarni sizga eng mos keladigan tarzda taqsimlang.
        </>
      ),
      cardSort: "Kartochkalar saralash",
      dragCardsToCreateCategories: "Toifa yaratish uchun kartochkalarni bir joydan ikkinchi joyga olib o’ting",
      dragCardHereToCreateCategory: "Toifa yaratish uchun kartochkani bu joyga olib o’ting",
      enterCategoryName: "Toifa nomini kiriting",
      dragCardHereToAddToCategory: "Toifaga qo’shish uchun kartochkani bu joyga olib o’ting",
      cardSortTaskTitle: "Vazifa",
      cards: "Kartochkalar",
      allSorted: "Barcha kartochkalar saralangan",
      allDone: "Tayyor",
      openSortInstuction: "Har bir kartochkani sizga eng mos keladigan toifaga saralang. Toifalar yaratish uchun kartochkalarni sahifani o’ng qismiga olib o’ting.",
      closedSortInstuction: "Har bir kartochkani sizga eng mos keladigan toifaga saralang.",
      doWhatComesNaturally: "Sizga eng mos keladigan narsani qiling, to'g'ri yoki noto'g'ri javoblar yo'q.",
      start: "Boshlash",
      instruction: "Qo’llanma",
      task: "Vazifa",
    },
  },
};

export const supportLink = "https://help.pthwy.design/";
