import uniqid from "uniqid";
import * as firestore from "firebase/firestore";
import { makeAuthorizedRequest, makeRequest } from "../http/Client";
import { generateVerificationCode } from "../utils";
import { db } from "../firebase";
import { ITest } from "../models/Test";
import { IActionResult } from "../AppContext";
import { pushToGTM } from "../utils/gtm";
import i18n from "../i18n/config";

const testsApiPath = "/api/v1/tests";

function getInitialTestData(userId: string, folderId: string, projectId?: string) {
	const test: ITest = {
		answers: {},
		name: i18n.t("New test"),
		status: "draft",
		userId: userId,
		createdAt: firestore.Timestamp.now() as any,
		verificationCode: generateVerificationCode(),
		sharingToken: uniqid(),
		content: [],
		contentPreview: [],
		publishedContent: null,
		backgroundColor: "#FFFFFF",
		buttonsColor: "#0163E1",
		buttonsTextColor: "#FFFFFF",
		folderId: folderId,
		answersCounter: 0,
		ownerId: projectId || userId,
		...(projectId ? { projectId: projectId } : {}),
	};

	return test;
}

export class TestsActions {
	public static async banTester(testId: string, testerId: string) {
		const testResponse = await makeAuthorizedRequest(`/api/v1/tests/${testId}/testers/${testerId}/block`, "POST");

		if (testResponse.ok) {
			return {
				type: "BAN_TESTER",
				payload: {
					data: {
						testId,
						testerId,
					},
				},
			};
		}

		throw new Error("http_error");
	}
	public static async approveTester(testId: string, testerId: string) {
		const testResponse = await makeAuthorizedRequest(`/api/v1/tests/${testId}/testers/${testerId}/approve`, "POST");

		if (testResponse.ok) {
			return {
				type: "APPROVE_TESTER",
				payload: {
					data: {
						testId,
						testerId,
					},
				},
			};
		}

		throw new Error("http_error");
	}
	public static async getTesters(testId: string, sharingToken: string) {
		const testResponse = await makeAuthorizedRequest(`/api/v1/tests/${testId}/testers?sharingToken=${sharingToken}`);

		if (testResponse.ok) {
			return {
				type: "GET_TESTERS",
				payload: {
					data: {
						[testId]: await testResponse.json(),
					},
				},
			};
		}

		throw new Error("http_error");
	}

	public static async getTest(testId: string) {
		const testResponse = await makeAuthorizedRequest(`/api/v1/tests/${testId}`);

		if (testResponse.ok) {
			return {
				type: "GET_TEST",
				payload: {
					testId: testId,
					data: await testResponse.json(),
				},
			};
		}

		throw new Error("http_error", { cause: testResponse });
	}

	public static async getTestWithSharingToken(testId: string, sharingToken: string) {
		const testResponse = await makeRequest(`/api/v1/tests/${testId}?sharingToken=${sharingToken}`);

		if (testResponse.ok) {
			return {
				type: "GET_TEST",
				payload: {
					testId: testId,
					data: await testResponse.json(),
				},
			};
		}

		throw new Error("http_error");
	}

	public static async getTestResponsesWithSharingToken(testId: string, sharingToken: string, lastCreatedAt: string) {
		const testResponse = await makeRequest(
			`/api/v1/tests/${testId}/answers?sharingToken=${sharingToken}&lastCreatedAt=${lastCreatedAt || ""}`
		);

		if (testResponse.ok) {
			return await testResponse.json();
		}

		throw new Error("http_error", { cause: testResponse });
	}

	public static async createTest(
		userId: string,
		folderId: string,
		projectId: string
	): Promise<IActionResult<Record<string, ITest>>> {
		const testInitialData = getInitialTestData(userId, folderId, projectId);
		// todo make it using API and check limits
		const testDoc = await db.collection("tests").add(testInitialData);

		testInitialData.id = testDoc.id;

		pushToGTM({
			event: "Test Created",
			userId: testInitialData.userId,
			projectId: projectId,
		});

		return {
			type: "CREATE_TEST",
			payload: {
				data: { [testDoc.id]: testInitialData as ITest },
			},
		};
	}

	public static async createTestInSplitGroup(
		splitGroupId: string | number,
		name: string,
		projectId: string = "",
		signal?: AbortSignal
	) {
		const response = await makeAuthorizedRequest(
			`${testsApiPath}?projectId=${projectId}`,
			"POST",
			{
				name,
				splitGroupId,
			},
			signal
		);

		if (response.ok) {
			const data = await response.json();
			return {
				type: "CREATE_TEST",
				payload: {
					data: { [data.id]: data },
				},
			};
		} else if (response.status === 400) {
			const error = await response.json();
			throw new Error("bad_request", { cause: error });
		}

		throw new Error("http_error");
	}

	public static async listTests(folderId: string, signal?: AbortSignal) {
		const testsResponse = await makeAuthorizedRequest("/api/user/getTests", "POST", { folderId }, signal);

		if (testsResponse.ok) {
			return {
				type: "GET_TESTS",
				payload: {
					tests: await testsResponse.json(),
				},
			};
		}
		throw new Error("http_error");
	}
}
