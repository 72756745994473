import clsx from 'clsx';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IMessageTag } from '../../../../Common/schema/Chats/UpdateChatMessageDTO';
import { BTextAccent } from '../../../Common/Text';
import { MessageTag } from '../Chat/MessageTag';
import { useTags } from '../useTags';
import { TestBlockReportContext } from '../../ReportContext';
import { useChatReport } from '../useChatReport';
import { IconDropdown, IconTag } from '../../../../icons';
import { ListWithSeparators } from '../../../Common/Containers/ListWithSeparators';
import { CollapsibleContainer } from '../../../Common/Containers/CollapsibleContainer';
import Loader from '../../../Loader';

const minVisibleCount = 5;

export function AiChatTags(props: {
	filterTags: Record<number, boolean>;
	chats: ReturnType<typeof useChatReport>['taggedChats'];
	className?: string;
	onTagSelect?: (tag: IMessageTag) => void;
}) {
	const { t } = useTranslation();
	const reportContext = useContext(TestBlockReportContext);
	const tags = useTags({ testId: reportContext?.testId || '', blockId: reportContext?.block.blockId || '' });
	const chatsCount = props.chats?.length || 0;

	const [isCollapsed, setIsCollapsed] = useState(true);

	const filteredTags = tags.list.data?.filter(t => !props.filterTags[t.id])
		.map(t => ({ tag: t, stats: getPercentOfChatsCount(t) }))
		.sort((a, b) => b.stats.count - a.stats.count);

	const tagsCount = filteredTags?.length || 0;

	function getPercentOfChatsCount(tag: IMessageTag) {
		if (!chatsCount) return { count: 0, percent: 0 };
		const tagChatsCount = Object.keys(tag.chats).length;
		return {
			count: tagChatsCount,
			percent: Math.round((tagChatsCount / chatsCount) * 100)
		};
	}


	return <div className={clsx("ai-chat-tags", props.className)}>
		<div className="ai-chat-tags__header mb-4">
			<BTextAccent>{t("Topics")}</BTextAccent>
		</div>
		<div className="ai-chat-tags__content">
			{tags.list.isLoading && <Loader />}
			{!tags.list.isLoading && !tagsCount && <div className='text-gray-600 w-full flex flex-col items-center justify-center text-sm my-12 gap-4'>
				<IconTag className="w-10 h-10 shrink-0 mr-2 fill-current text-gray-500" />
				{t('Tag some messages for analytics.')}
			</div>}

			{!!tagsCount && <CollapsibleContainer className='mb-4' isCollapsed={isCollapsed} minH={minVisibleCount * 50} maxH={tagsCount * 50}>
				<ListWithSeparators className='ai-chat-tags-list w-full'>
					{filteredTags?.map(tagModel => {
						return <div key={tagModel.tag.id} className='ai-chat-tags-list__item flex flex-row items-center justify-between'>
							<MessageTag
								className={clsx("cursor-pointer", {
									"animate-pulse": tags.untagMessage.variables?.tagId === tagModel.tag.id && tags.untagMessage.isLoading
								})}
								colorClassName={tags.getTagStyle(tagModel.tag.color)}
								withContextMenu={!reportContext?.sharedByLink}
								onClick={() => props.onTagSelect?.(tagModel.tag)}
								onDeleteClick={() => tags.untagMessage.mutate({ tagId: tagModel.tag.id, request: {} })}>
								<span>{tagModel.tag.name}</span>
							</MessageTag>
							<div className='font-semibold'>
								{tagModel.stats.count} <span className='text-gray-500'>({tagModel.stats.percent}%)</span>
							</div>
						</div>
					})}
				</ListWithSeparators>
			</CollapsibleContainer>}

			{tagsCount > minVisibleCount && <a className="text-gray-600 flex flex-row items-center gap-2" onClick={() => setIsCollapsed(!isCollapsed)}>
				{isCollapsed ? t('Show all topics ({{count}})', { count: tagsCount }) : t('Show less')}
				<IconDropdown className={clsx("w-4 h-4 transition-transform duration-200 ease-in-out", { "rotate-180": !isCollapsed })} />
			</a>}
		</div>
	</div>;
}

