import { useMutation, useQuery, useQueryClient } from 'react-query';
import { ReportChatAPI } from '../../../actions/ReportChatAPI';
import { CreateMessageTagDTO, DeleteMessageTagDTO } from '../../../Common/schema/Chats/UpdateChatMessageDTO';

export const TAG_COLORS_TO_STYLES_MAP: Record<string, string> = {
	'purple': 'bg-purple-300 text-purple-800',
	'orange': 'bg-orange-300 text-orange-900',
	'lightblue': 'bg-blue-200 text-blue-800',
	'lightred': 'bg-red-200 text-red-900',
	'darkblue': 'bg-blue-800 text-blue-100',
	'green': 'bg-green-400 text-emerald-800',
	'gray': 'bg-gray-400 text-gray-800',
};

export function useTags(options: { testId: string, blockId: string, messageId?: number }) {
	const queryClient = useQueryClient();

	const list = useQuery({
		queryKey: ['tags', options.testId, options.blockId],
		queryFn: () => {
			return ReportChatAPI.getTags(options.testId, options.blockId)
		},
		enabled: !!options.testId && !!options.blockId,
		staleTime: 1000 * 60 * 2
	});

	const tagMessage = useMutation({
		mutationKey: ['tagMessage', options.testId, options.blockId, options.messageId],
		mutationFn: (request: CreateMessageTagDTO) => {
			return ReportChatAPI.tagMessage(options.testId, options.blockId, request);
		},
		onSettled: () => {
			return queryClient.invalidateQueries(['tags', options.testId, options.blockId]);
		}
	});

	const untagMessage = useMutation<any, Error, { tagId: number, request: DeleteMessageTagDTO }>({
		mutationFn: (data) => {
			return ReportChatAPI.unTagMessage(options.testId, options.blockId, data.tagId, data.request);
		},
		onSuccess: () => {
			return queryClient.invalidateQueries(['tags', options.testId, options.blockId]);
		}
	});

	function getNextColor() {
		const colors = Object.keys(TAG_COLORS_TO_STYLES_MAP);
		const randomIndex = Math.floor(Math.random() * colors.length);
		return colors[randomIndex];
	}

	function getTagStyle(color: string) {
		return TAG_COLORS_TO_STYLES_MAP[color] || 'bg-blue-200';
	}

	return {
		list,
		tagMessage,
		untagMessage,
		getNextColor,
		getTagStyle
	};
}