import React, { useEffect, useState, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import lodash from "lodash";
import lstorage from "store";

import { fetchTest, createAnswer, sendAnswer, aproveAnswer, fetchPrototype } from "../../../../actions";

import { getFormattedPrototype } from "../../../../utils/figma";
import { getIsTestCompleted, getRedirectUrl } from "./lstorage";
import { Environment } from '../../../../utils/env';
import {
  getTesterSource, getUrlParams, hasCustomPanelParams,
  getFormattedPanelUrl, checkParticipantDevice, TesterSource, getRespondentParams,
} from "../../../../utils/tests";
import { locale, interfaceText } from "../../../../helpers";

import { testValidator } from "../../../TestValidator";


import Loader from "../../../Loader";
import PageNotFound from "../../../PageNotFound";
import Button from "../../../Button";

import { useLogic } from '../../../Common/Hooks/useLogic';
import { usePanelHelpers } from "./hooks/usePanelHelpers";
import { Block, FigmaBlock, ITest, isFigmaBlock } from '../../../../models/Test';
import { ITestAnswer } from '../../../Admin/Testers';
import { IBlockTestResponse } from '../../../../models/Response';

import FinalStep from './components/FinalStep';
import ContentBlock from './components/ContentBlock/ContentBlock';
import DeviceIsNotAllowed from './components/DeviceIsNotAllowed';
import AnswersLimitExceeded from './components/AnswersLimitExceeded';
import { useSessionStorage } from '../../../Common/Hooks/useSessionStorage';

type DeviceType = "any" | "desktop" | "mobile";

const isTestPublished = (test: ITest, source: string) => {
  if (test.status === "published") {
    return true;
  }

  if (source !== "link") {
    return true;
  }

  return false;
};

const Test = ({ isPreview }: { isPreview: boolean }) => {
  const { testId } = useParams() as { testId: string };

  const source = getTesterSource();
  const history = useHistory<{ answer?: ITestAnswer }>();
  const sessionStore = useSessionStorage(testId);
  sessionStore.setItem('', true);

  const showVerificationCode = window.location.search.includes("code");
  const getVerificationCode = () => {
    if (!showVerificationCode) return undefined;
    const localStorageCode = lodash.get(lstorage.get(testId), "code");
    return localStorageCode ? localStorageCode : verificationCode;
  };

  const [asyncProcessesNum, setAsyncProcessesNum] = useState(0);
  const failedOperatons = useRef<Function[]>([]);

  const processOperation = async (run: Function) => {
    // Increase processes counter
    setAsyncProcessesNum(asyncProcessesNum => asyncProcessesNum + 1);

    try {
      await run();
    } catch (error) {
      failedOperatons.current.push(() => {
        if (Environment.isProduction) {/* TODO: Log error to Sentry */ }
        else console.error(error);
        processOperation(run);
      });
    }

    // Decrise processes counter
    setAsyncProcessesNum(asyncProcessesNum => asyncProcessesNum - 1);
  };

  const [testData, setTestData] = useState<ITest>();
  const [isLoading, setIsLoading] = useState(true);
  const [content, setContent] = useState<Block[] | null>(null);
  const [isPublished, setIsPublished] = useState<boolean | null>(null);
  const [progressBar, setProgressBar] = useState(null);
  const [isDeviceAllowed, setIsDeviceAllowed] = useState(true);
  const [device, setDevice] = useState<DeviceType>("any");
  const [answerId, setAnswerId] = useState(history.location?.state?.answer?.answerId);
  const [verificationCode, setVerificationCode] = useState<string | null>(null);
  const [designConfig, setDesignConfig] = useState({
    backgroundColor: null,
    buttonsColor: null,
    buttonsTextColor: null,
    textColor: null,
  });
  const [isAnswersLimitExceeded, setIsAnswersLimitExceeded] = useState(null);
  const [plan, setPlan] = useState(undefined);
  const [isLinkDisabled, setIsLinkDisabled] = useState(null);

  const customPanelRedirectUrl = useRef(getRedirectUrl(testId));

  const { currentStep, ...logic } = useLogic(content as Block[], isPreview ? undefined : sessionStore);
  const panelHelpers = usePanelHelpers(testId, testData, answerId)

  const isAdmin = localStorage.getItem("isAdmin") === "true";
  const isTestCompleted = getIsTestCompleted(testId);
  const isFinalStep = logic.isLastBlock || (isTestCompleted && !isAdmin && !isPreview);

  useEffect(() => {
    window.Intercom("update", {
      hide_default_launcher: true,
    });
    return () => {
      window.Intercom("update", {
        hide_default_launcher: false,
      });
    };
  }, []);

  useEffect(() => {
    processOperation(async () => {
      const sharingToken = new URLSearchParams(window.location.search).get("sharingToken");

      const fetchTestResponse = await fetchTest(testId, sharingToken);

      if (!fetchTestResponse.ok) {
        setIsLoading(false);
        if (fetchTestResponse.status >= 500) {
          throw "Something wrong with server";
        }
        return;
      }

      const test = await fetchTestResponse.json();

      setTestData(test)

      setDesignConfig({
        backgroundColor: test.backgroundColor,
        buttonsColor: test.buttonsColor,
        buttonsTextColor: test.buttonsTextColor,
        textColor: test.textColor,
      });

      setIsPublished(isTestPublished(test, source));
      setProgressBar(test.settings?.progressBar);

      //language
      const language = lodash.get(test, "settings.language", "auto");
      window.sessionStorage.setItem("language", language);

      //device
      const userDevice = lodash.get(test, "settings.device", "any");
      setIsDeviceAllowed(checkParticipantDevice(userDevice));
      setDevice(userDevice);

      if (source === TesterSource.CustomPanel && hasCustomPanelParams(test)) {
        setContent([...test.customPanelScreeningQuestions, ...test.publishedContent]);
      } else {
        setContent(isPreview ? test.content : test.publishedContent);
      }

      if (!isPreview && !answerId) {
        const preservedAnswerId = sessionStore.getItem<string>('_answer_id');
        const preservedAnswerCode = sessionStore.getItem<string>('_answer_code');
        if (preservedAnswerId) {
          setAnswerId(preservedAnswerId);
          setVerificationCode(preservedAnswerCode)
        }
        else {
          const urlParams = getUrlParams();
          const respondentParams = getRespondentParams();
          const createAnswerResponse = await createAnswer(testId, { source: source, userAgent: navigator.userAgent }, urlParams, respondentParams);

          if (!createAnswerResponse.ok) {
            setIsLoading(false);
            throw "Answer creation is failed";
          }

          const answerData = await createAnswerResponse.json();
          setAnswerId(answerData.answerId);
          // TODO: verification code is always null for split group tests
          setVerificationCode(answerData.code);

          sessionStore.setItem('_answer_id', answerData.answerId);
          sessionStore.setItem('_answer_code', answerData.code);
        }
      }
      setIsAnswersLimitExceeded(test.isAnswersLimitExceeded);
      setPlan(test.plan);
      setIsLinkDisabled(test.settings?.disableLink && source === TesterSource.Link);

      setIsLoading(false);
      await new Promise((r) => setTimeout(r, 100));
    });
  }, [testId]);

  useEffect(() => {
    if (!designConfig.backgroundColor) return;
    document.body.style.backgroundColor = designConfig.backgroundColor;
    return () => {
      document.body.style.backgroundColor = "#F5F5F5";
    };
  }, [designConfig]);

  useEffect(() => {
    if ((isFinalStep || (isAnswersLimitExceeded && showVerificationCode)) && !isTestCompleted && !isPreview) {
      processOperation(async () => {
        if (showVerificationCode) {
          const aproveAnswerResponse = await aproveAnswer(answerId, testId, isAnswersLimitExceeded);
          if (!aproveAnswerResponse.ok) throw "Approval of the answer failed";
          lstorage.set(testId, { code: verificationCode });
        } else if (source === TesterSource.CustomPanel) {
          const aproveAnswerResponse = await aproveAnswer(answerId, testId);
          if (!aproveAnswerResponse.ok) throw "Approval of the answer failed";
          const { redirectUrl } = await aproveAnswerResponse.json();

          const formattedRedirectUrl = getFormattedPanelUrl(redirectUrl);
          lstorage.set(testId, {
            code: "PASSED",
            redirectUrl: formattedRedirectUrl,
          });
          customPanelRedirectUrl.current = formattedRedirectUrl;
        } else {
          lstorage.set(testId, { code: "PASSED" });
        }
      });
    }
  }, [currentStep, isAnswersLimitExceeded]);

  useEffect(() => {
    const block = lodash.get(content, [currentStep - 1], {});
    if (!('type' in block)) return;

    if (isFigmaBlock(block) && block.prototypeId && !block.prototypeData) {
      processOperation(async () => {
        const fetchPrototypeResponse = await fetchPrototype(block.prototypeId);

        if (!fetchPrototypeResponse.ok) throw "Unable to load the prototype";

        const prototypeData = getFormattedPrototype(await fetchPrototypeResponse.json());
        setContent((content) => {
          const newContent = lodash.cloneDeep(content) as Block[];
          (newContent[currentStep - 1] as FigmaBlock).prototypeData = prototypeData;
          return newContent;
        });
      });
    }
  }, [currentStep, content]);

  function handleSendAnswer(value: any, blockId: string, currentBlockAnswer: IBlockTestResponse | null = null) {
    return processOperation(async () => {
      // setAnswerData((data) => ({ ...data, [blockId]: value }));
      if (!isPreview) {
        const response = await sendAnswer(value, answerId, blockId, testId);
        if (!response.ok)
          throw "Invalid response";
      }

      /** SCREENING LOGIC STARTS
       * 
       * Here we track the end of panel screening, then we ask 
       * the server if the current respondent has been screened.
       */

      const currentBlockId = logic.getCurrentBlockId()
      const nextBlockId = logic.getNextBlockId(currentBlockAnswer)

      if (
        panelHelpers.isItScreeningBlock(currentBlockId) === true &&
        panelHelpers.isItScreeningBlock(nextBlockId) === false
      ) {
        const isRespondentScreenedIn = await panelHelpers.screenRespondent(nextBlockId)

        if (!isRespondentScreenedIn) {
          logic.goFinalStep()
          return;
        }
      }

      /** SCREENING LOGIC ENDS */

      if (currentBlockAnswer) {
        showNextBlock(currentBlockAnswer);
        await new Promise((r) => setTimeout(r, 100));
      }
    });
  }

  function showNextBlock(blockAnswer?: IBlockTestResponse) {
    // console.log('ANSEWR DATA', currentStep, answerData);
    logic.goNextStep(blockAnswer);
  }

  const goToNextBlockWithoudAnswer = () =>
    processOperation(async () => {
      showNextBlock();
      await new Promise((r) => setTimeout(r, 100));
    })


  // Loader
  if (asyncProcessesNum > 0 || isLoading) {
    return <Loader />;
  }

  // Repeat request screen
  if (failedOperatons.current.length > 0) {
    return (
      <div className="container mx-auto h-full flex justify-center items-center">
        <div className="text-center">
          <div className="mb-4 text-3xl tracking-tighter">{interfaceText.requestFailed[locale() as 'ru' | 'en'].header}</div>
          <Button
            name={interfaceText.requestFailed[locale() as 'ru' | 'en'].button}
            large
            className="inline-block mx-auto"
            handler={() => {
              failedOperatons.current.forEach((fn) => fn());
              failedOperatons.current = [];
            }} />
        </div>
      </div>
    );
  }

  // If link is disabled
  if (isLinkDisabled) {
    return <AnswersLimitExceeded />;
  }

  // If answers limit is exceeded
  if (isAnswersLimitExceeded) {
    return <AnswersLimitExceeded verificationCode={getVerificationCode()} />;
  }

  // If invalid :testId param received
  if (isPublished === null) {
    return <PageNotFound />;
  }

  // If trying to access an unpublished test
  if (!isPreview && !isPublished) {
    return <PageNotFound />;
  }

  // If trying to preview an invalid test
  // pass screening questions on case one of the blocks uses them in logic
  if (isPreview && (!content || testValidator([...(testData?.customPanelScreeningQuestions || []), ...content]).isValid === false)) {
    return <PageNotFound />;
  }

  // set device settings
  if (!isDeviceAllowed && device !== "any" && source !== TesterSource.Toloka) {
    return <DeviceIsNotAllowed device={device} />;
  }

  if (isFinalStep) {
    return (
      <FinalStep
        redirectUrl={customPanelRedirectUrl.current}
        verificationCode={getVerificationCode()}
        testId={testId}
      />
    );
  }

  return (
    <ContentBlock
      withProgress={progressBar && logic.totalSteps > 2}
      currentStep={currentStep}
      totalSteps={logic.totalSteps}
      data={content?.[currentStep - 1]}
      blockId={content?.[currentStep - 1].blockId}
      testId={testId}
      showNextBlock={goToNextBlockWithoudAnswer}
      answerId={answerId}
      isPreview={isPreview}
      designConfig={designConfig}
      plan={plan}
      sendAnswer={handleSendAnswer}
    />
  );
};

export default Test;
