import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import parse from "html-react-parser";
import "../../../../../../tailwind.generated.css";
import uniqid from "uniqid";
import clsx from "clsx";

import Select from "../Select";
import Preference from "../Preference";
import Firstclick from "../Firstclick";
import Button from "../../../../../Button";
import FirstClickPointer from "../../../../../Test/FirstClickPointer";
import Loader from "../../../../../Loader";
import Input from "../Input";
import CardSort from "../CardSort";
import MatrixSelect from "../MatrixSelect";
import ScaleSelect from "../ScaleSelect";
import { responseValidator } from "../ResponseValidator";
import ImageWithZoom from "../../../../../ImageWithZoom";

import { IconImage, IconCheck, IconCancel } from "../../../../../../icons";

import { getFirstClickCoordinates } from "../../../../../../utils";
import { locale, interfaceText } from "../../../../../../helpers";

import useIsMobile from "../../hooks/useIsMobile";

import { pushToGTM } from "../../../../../../utils/gtm";
import Figma from "../Figma";
import { BLOCKS_WITH_IMAGES, BlockType } from "../../../../../../models/Test";
import { ContentWrapper } from './ContentWrapper';
import { Preview } from './Preview';
import { Stepper } from './Stepper';
import { FigmaContextProvider } from '../../../../../Figma/FigmaContextProvider';
import AiOpenQuestion from '../AiOpenQuestion/AiOpenQuestion';

const hasImagePreloader = (type) => BLOCKS_WITH_IMAGES.includes(type);


export const ImagesPreloader = ({ showImagesLoader, blockId }) => {
  const [currentBlockId, setCurrentBlockId] = useState(blockId);

  useEffect(() => {
    setCurrentBlockId(blockId);
  }, [blockId]);

  if (showImagesLoader || currentBlockId !== blockId) {
    return (
      <div className="fixed inset-0 z-10" style={{ backgroundColor: "#F5F5F5" }}>
        <Loader />
      </div>
    );
  }

  return null;
};


const ContentBlock = ({
  currentStep,
  totalSteps,
  data,
  blockId,
  testId,
  answerId,
  showNextBlock,
  designConfig,
  sendAnswer,
  withProgress,
  isPreview,
  plan,
}) => {

  function handleFigmaGiveUp(figmaData) {
    submitFigmaBlockAnswer(figmaData, true);
    setBlockAnswer({});
  }

  function handleFigmaSuccess(figmaData) {
    submitFigmaBlockAnswer(figmaData, false);
    setBlockAnswer({});
  }

  function submitFigmaBlockAnswer(figmaData, givenUp) {
    const now = _.now();

    const totalTimeSpent = Object.keys(figmaData.nodeEventData).reduce((acc, key) => {
      return acc + figmaData.nodeEventData[key].timeSpent || 0;
    }, 0);

    const answerData = {
      type: BlockType.figma,
      withVideo: figmaData.withVideo ? true : false,
      devicePixelRatio: figmaData.devicePixelRatio,
      size: figmaData.size || null,
      givenUp: !!givenUp,
      submittedAt: now,
      time: totalTimeSpent,
      responseTime: totalTimeSpent,
      path: figmaData.path,
      nodeEventData: figmaData.nodeEventData
    };


    sendAnswer(answerData, blockId, answerData);

    setBlockAnswer({});
  }

  const [isLoading, setIsLoading] = useState(true);
  const [blockAnswer, setBlockAnswer] = useState({});
  const [responseStart, setResponseStart] = useState(null);
  const [showFiveSecondsImage, setShowFiveSecondsImage] = useState(null);

  const hasImage = data.image && data.image !== "";
  const isOptional = data.isOptional;

  const [imageStatuses, setImageStatus] = useState({});
  const isBlockImagesLoaded = Object.values(imageStatuses).every((s) => s === "loaded");

  useEffect(() => {
    if (hasImagePreloader(data.type) && isBlockImagesLoaded === true) {
      setResponseStart(_.now());
    }
  }, [isBlockImagesLoaded]);

  useEffect(() => {
    pushToGTM({ event: "openQuestion", blockId, testId, answerId });
    setIsLoading(true);
    // setting responseStart to null for "firstclick" to prevent showing image immediately
    // setting responseStart to null for "preference" to prevent starting task immediately
    setResponseStart(data.type === BlockType.firstclick || data.type === BlockType.preference ? null : _.now());
    setShowFiveSecondsImage(null);
    setImageStatus(getInitialImageStatuses(data)); // preload images
  }, [blockId, testId, answerId]);

  function getInitialImageStatuses(block) {
    const statuses = {};

    if (hasImagePreloader(block.type)) {
      if (block.type === BlockType.preference) {
        block.replies.forEach((reply) => {
          statuses[`reply.${reply.id}.image`] = undefined;
        });
      }
      if (block.image) {
        statuses["image"] = undefined;
      }
    }

    return statuses;
  }

  function onImageLoaded(imagePath) {
    setImageStatus((state) => ({
      ...state,
      [imagePath]: "loaded",
    }));
  }

  function handleBlockAnswer(key, value) {
    setBlockAnswer((current) => {
      return { ...current, [key]: value };
    });
  }

  const isAnswerValid = responseValidator(blockAnswer, data).isValid;

  function nextStepOnClick() {
    if (isAnswerValid) {
      handleSubmitBlockAnswer();
    } else if (isOptional) {
      showNextBlock();
    }
  }

  function handleSubmitBlockAnswer() {
    const answerData = {
      ...blockAnswer,
      submittedAt: _.now(),
      type: data.type,
      responseTime: _.now() - responseStart
    };
    // if (hasImagePreloader(data.type)) {
    //   answerData.responseTime = _.now() - responseStart;
    // }

    const nextBlock = { ...blockAnswer };
    sendAnswer(answerData, blockId, nextBlock);
    setBlockAnswer({});
    responseStart && setResponseStart(null);
  }

  const isMobile = useIsMobile();

  if (data.type === BlockType.openquestion) {
    return (
      <ContentWrapper
        hasButton={true}
        currentStep={currentStep}
        totalSteps={totalSteps}
        withProgress={withProgress}
        isPreview={isPreview}
        nextStepOnClick={nextStepOnClick}
        isValidated={isAnswerValid}
        isOptional={isOptional}
        showImagesLoader={hasImagePreloader(data.type) && !isBlockImagesLoaded}
        block={data}
        image={
          <ImageWithZoom
            imageSrc={data.image}
            alt=""
            className={clsx("lg:mt-0 max-w-full object-contain block", isMobile ? "max-h-[50dvh]" : "max-h-[80dvh]")}
            onLoad={() => {
              onImageLoaded("image");
            }}
          />
        }
        hasImage={hasImage}
        content={
          <>
            <div className="w-full text-lg my-2 flex items-start" style={{ color: designConfig.textColor }}>
              <div>{parse(data.questionHtml || data.text)}</div>
            </div>
            <div className="w-full flex justify-center">
              <Input
                singleLine={data.isShort}
                placeholder={interfaceText.test[locale()].inputPlaceholder}
                id={`${blockId}-response`}
                handler={handleBlockAnswer}
                designConfig={designConfig}
                className="test-textarea"
              />
            </div>
          </>
        }
      />
    );
  }

  if (data.type === BlockType.context) {
    return (
      <ContentWrapper
        hasButton={true}
        currentStep={currentStep}
        totalSteps={totalSteps}
        contentType={data.type}
        withProgress={withProgress}
        isPreview={isPreview}
        nextStepOnClick={() => showNextBlock()}
        showImagesLoader={hasImagePreloader(data.type) && !isBlockImagesLoaded}
        isValidated={true}
        designConfig={designConfig}
        block={data}
        image={
          <ImageWithZoom
            imageSrc={data.image}
            alt=""
            className={clsx("lg:mt-0 max-w-full object-contain block", isMobile ? "max-h-[50dvh]" : "max-h-[80dvh]")}
            onLoad={() => {
              onImageLoaded("image");
            }}
          />
        }
        hasImage={hasImage}
        content={
          <div className="text-xl w-full">
            <div className="whitespace-pre-line" style={{ color: designConfig.textColor }}>
              <div>{parse(data.questionHtml || data.text)}</div>
            </div>
          </div>
        }
      />
    );
  }

  if (data.type === BlockType.choice) {
    return (
      <ContentWrapper
        hasButton={true}
        currentStep={currentStep}
        totalSteps={totalSteps}
        contentType={data.type}
        withProgress={withProgress}
        isPreview={isPreview}
        nextStepOnClick={nextStepOnClick}
        isValidated={isAnswerValid}
        isOptional={isOptional}
        designConfig={designConfig}
        block={data}
        showImagesLoader={hasImagePreloader(data.type) && !isBlockImagesLoaded}
        image={
          <ImageWithZoom
            imageSrc={data.image}
            alt=""
            className={clsx("lg:mt-0 max-w-full object-contain block", isMobile ? "max-h-[50dvh]" : "max-h-[80dvh]")}
            onLoad={() => {
              onImageLoaded("image");
            }}
          />
        }
        hasImage={hasImage}
        content={
          <>
            <div className="w-full text-lg my-2 flex items-start" style={{ color: designConfig.textColor }}>
              <div>{parse(data.questionHtml || data.text)}</div>
            </div>

            <div className="my-2 w-full">
              <Select
                type={data.replyType}
                data={data.replies}
                isRandomized={data.randomized}
                withOther={data.other}
                handler={handleBlockAnswer}
                selectedOptions={blockAnswer.selectedOptions || []}
                hasChoiceLimit={data.hasChoiceLimit}
                choiceLimit={data.choiceLimit || 2}
              />
            </div>
          </>
        }
      />
    );
  }

  if (data.type === BlockType.preference) {
    return (
      <div className="flex flex-col h-[100dvh] max-h-[100dvh] items-center">
        {isPreview && <Preview />}
        <Preference withProgress={withProgress} currentStep={currentStep} totalSteps={totalSteps} blockData={data} blockAnswer={blockAnswer} handleBlockAnswer={handleBlockAnswer} nextStepOnClick={nextStepOnClick} onImageLoaded={onImageLoaded} responseStart={responseStart} setResponseStart={setResponseStart} />
      </div>
    );
  }

  if (data.type === BlockType.cardsort) {
    return (
      <div className="flex flex-col h-[100dvh] max-h-[100dvh]">
        {isPreview && <Preview />}
        {withProgress && <Stepper currentStep={currentStep} totalSteps={totalSteps} />}
        <CardSort
          blockData={_.cloneDeep(data)}
          responseStart={responseStart}
          setResponseStart={setResponseStart}
          isValidated={isAnswerValid}
          nextStepOnClick={nextStepOnClick}
          blockAnswer={blockAnswer}
          handleBlockAnswer={handleBlockAnswer}
        />
      </div>
    );
  }

  if (data.type === BlockType.matrix) {
    return (
      <ContentWrapper
        hasButton={true}
        currentStep={currentStep}
        totalSteps={totalSteps}
        contentType={data.type}
        withProgress={withProgress}
        isPreview={isPreview}
        nextStepOnClick={nextStepOnClick}
        isValidated={isAnswerValid}
        isOptional={isOptional}
        designConfig={designConfig}
        block={data}
        showImagesLoader={hasImagePreloader(data.type) && !isBlockImagesLoaded}
        hasImage={false}
        content={
          <MatrixSelect blockData={data} blockAnswer={blockAnswer} handleBlockAnswer={handleBlockAnswer} />
        }
      />
    );
  }

  if (data.type === BlockType.scale) {
    return (
      <ContentWrapper
        hasButton={true}
        currentStep={currentStep}
        totalSteps={totalSteps}
        contentType={data.type}
        withProgress={withProgress}
        isPreview={isPreview}
        nextStepOnClick={nextStepOnClick}
        isValidated={isAnswerValid}
        isOptional={isOptional}
        designConfig={designConfig}
        block={data}
        showImagesLoader={hasImagePreloader(data.type) && !isBlockImagesLoaded}
        image={
          <ImageWithZoom
            imageSrc={data.image}
            alt=""
            className={clsx("lg:mt-0 max-w-full object-contain block", isMobile ? "max-h-[50dvh]" : "max-h-[80dvh]")}
            onLoad={() => {
              onImageLoaded("image");
            }}
          />
        }
        hasImage={hasImage}
        content={
          <>
            <div className="w-full text-xl my-2 flex items-start">
              <div>{parse(data.questionHtml || data.text)}</div>
            </div>

            <div className="my-2 w-full">
              <ScaleSelect blockData={data} blockAnswer={blockAnswer} handleBlockAnswer={handleBlockAnswer} />
            </div>
          </>
        }
      />
    );
  }

  if (data.type === BlockType.firstclick) {
    return (
      <div className="flex flex-col h-[100dvh] max-h-[100dvh] items-center">
        {isPreview && <Preview />}
        <Firstclick
          withProgress={withProgress}
          currentStep={currentStep}
          totalSteps={totalSteps}
          blockData={data}
          blockAnswer={blockAnswer}
          handleBlockAnswer={handleBlockAnswer}
          nextStepOnClick={nextStepOnClick}
          onImageLoaded={onImageLoaded}
          responseStart={responseStart}
          setResponseStart={setResponseStart}
          isAnswerValid={isAnswerValid}
        />
      </div>
    );
  }

  // type === "fiveseconds"

  if (data.type === BlockType.fiveseconds) {
    const withCustomInstruction = data.withCustomInstruction;
    return (
      <div className="flex flex-col items-center min-h-full">
        <ImagesPreloader showImagesLoader={hasImagePreloader(data.type) && !isBlockImagesLoaded} blockId={data.blockId} />
        {isPreview && <Preview />}
        {withProgress && <Stepper currentStep={currentStep} totalSteps={totalSteps} />}
        <div className="flex items-center justify-center text-xl flex-1">
          <div className="w-full">
            <div
              className={`text-center text-xl max-w-md mx-auto px-2 md:px-4 whitespace-pre-line ${!showFiveSecondsImage && "flex flex-col items-center justify-center"
                }`}
              style={{ color: designConfig.textColor }}
            >
              {!showFiveSecondsImage && (
                <>
                  {(data.questionHtml || data.text) && (data.questionHtml.length || data.text.length) ? (
                    <p className="text-center mb-2">{parse(data.questionHtml || data.text)}</p>
                  ) : (
                    <>
                      <p className="text-center font-medium mb-2">{interfaceText.test[locale()].fiveSecondsHeader}</p>
                      <p className="text-center text-base">{interfaceText.test[locale()].fiveSecondsText}</p>
                    </>
                  )}
                  <Button
                    name={interfaceText.firstclickTesting[locale()].showImage}
                    handler={() => {
                      setShowFiveSecondsImage(true);
                      setTimeout(() => showNextBlock(), data.time * 1000);
                    }}
                    large
                    className="mt-4 mx-auto"
                    icon={<IconImage width={24} height={24} className="fill-current text-white mr-2" />}
                  />
                </>
              )}
            </div>
            <div className={`flex justify-center items-center mx-auto p-2 md:p-4 ${showFiveSecondsImage ? "block" : "hidden"}`}>
              <div className="inline">
                <img
                  id="firstClickImage"
                  src={data.image}
                  alt=""
                  className="lg:mt-0 max-w-full transition-all duration-150 ease-in"
                  onLoad={() => onImageLoaded("image")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // Figma prototype testing
  if (data.type === BlockType.figma) {
    return (
      <FigmaContextProvider>
        <Figma
          data={data}
          plan={plan}
          testId={testId}
          answerId={answerId}
          isPreview={isPreview}
          withProgress={withProgress}
          currentStep={currentStep}
          totalSteps={totalSteps}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          handleFigmaGiveUp={handleFigmaGiveUp}
          handleFigmaSuccess={handleFigmaSuccess}
        />
      </FigmaContextProvider>
    );
  }

  if (data.type === BlockType.ai) {
    return (
      <AiOpenQuestion
        data={data}
        block={data}
        testId={testId}
        answerId={answerId || ('answer-' + uniqid() + '-preview')}
        blockId={blockId}
        isPreview={isPreview}
        withProgress={withProgress}
        currentStep={currentStep}
        totalSteps={totalSteps}
        onChatComplete={(chatId) => {
          handleBlockAnswer("chatId", chatId);
          nextStepOnClick();
        }}
      />
    );
  }

  return null;
};

export default ContentBlock;
