import React, { PropsWithChildren, useEffect } from "react";
import ReactDOM from "react-dom";
import Tabs from "../FirstClickReport/Tabs";
import _ from "lodash";
import { IconClose, IconFigmaActual } from "../../../icons";
import clsx from "clsx";

export interface IFigmaScreenModalProps extends PropsWithChildren {
	onArrowRight: () => void;
	onArrowLeft: () => void;
	onClose: () => void;
	resetEventListeners?: any
}

export default function FigmaScreenModal(props: IFigmaScreenModalProps) {
	// loop through props.children and find all with type of ModalHeader and ModalBody
	const children = React.Children.toArray(props.children);
	const header = children.find((child) => (child as any).type === FigmaScreenHeader) as React.ReactNode;
	const body = children.find((child) => (child as any).type === FigmaScreenBody);

	useEffect(() => {
		document.addEventListener("keydown", onKeydown);
		document.body.style.overflow = "hidden";

		return () => {
			document.removeEventListener("keydown", onKeydown);
			document.body.style.overflow = "visible";
		};
	}, []);

	function onKeydown({ key }: any) {

		if (key === "Escape") props.onClose();

		if (key === "ArrowRight") props.onArrowRight();

		if (key === "ArrowLeft") props.onArrowLeft();
	}

	return ReactDOM.createPortal(
		<div className="modal-container block" style={{ backgroundColor: "rgba(30, 30, 30, 0.97)" }}>
			<div className="figma-modal-body h-screen flex flex-col w-full">
				{header}
				{body}
			</div>
		</div>,
		document.body
	);
}

export function FigmaScreenHeader(props: {
	name: string;
	caption: string;
	onClose: () => void;
	tabs: any;
}) {
	return (
<div className="flex justify-between w-full p-6 text-white items-start">
    <div className="flex flex-col w-full pr-6 text-white h-full">
        <div className="font-medium flex items-center">
            <IconFigmaActual width={14} height={21} className="mr-2" />
            {props.name} <div className="text-gray-700 ml-2">({props.caption})</div>
        </div>
        <div className="my-2">
            <Tabs tabs={props.tabs} />
        </div>
    </div>
    <div className={clsx("min-w-[230px]", "flex justify-end items-center")}>
        <IconClose
            className="text-white opacity-75 fill-current cursor-pointer flex-shrink-0 transition-all duration-75 ease-in hover:opacity-100"
            onClick={props.onClose}
            width={32}
            height={32}
        />
    </div>
</div>
	);
}

export function FigmaScreenBody(props: PropsWithChildren & { className?: string }) {
	return <div className={`figma-modal-body__container flex px-6 pb-6 text-white relative h-full flex-1 ${props.className || ""}`}>
		{props.children}
	</div>
}

