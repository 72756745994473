import React from "react";
import clsx from "clsx";

export function Stepper({ currentStep, totalSteps, noPadding }: { currentStep: number | undefined; totalSteps: number | undefined, noPadding?: boolean  }) {

  if (!totalSteps || !currentStep) {
    return null;
  }

  return (
    <div className={clsx("flex flex-col items-center justify-center w-full z-30 relative flex-shrink-0", !noPadding && "px-6" )}>
      <div className={clsx("bg-gray-300 rounded-full h-1 w-full", !noPadding && "mt-6")}>
        <div
          className="bg-green-600 h-1 rounded-full transition-all duration-75 ease-in"
          style={{ width: Math.round((currentStep / totalSteps) * 100) + "%" }}
        ></div>
      </div>
      <div className="text-xs font-medium text-gray-600 mt-2">
        {currentStep} / {totalSteps}
      </div>
    </div>
  );
}
