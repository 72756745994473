import { useContext, useEffect, useState } from "react";
import {
  SplitGroupAPI,
  SplitGroupUpdate,
} from "../../../actions/SplitGroupAPI";
import { AppContext, IActionResult } from "../../../AppContext";
import { useSendNotification } from "../../../hooks";
import { ISplitGroup } from "../../../models/Test";
import { useMutation, useQuery, useQueryClient } from 'react-query';

export interface IUseSplitGroupActionsArgs {
  /**
   * ИД папки для которой подгрузится список сплит-групп
   *
   * Загрузка произойдет только если параметр указан
   */
  folderId?: string;

  /**
   * ИД сплит-группы, которая подгрузится
   *
   * Загрузка произойдет только если параметр указан
   */
  splitGroupId?: number;
}

export function useSplitGroupActions({
  folderId,
  splitGroupId,
}: IUseSplitGroupActionsArgs) {
  const { dispatch } = useContext(AppContext);
  const queryClient = useQueryClient();
  const setNotification = useSendNotification();

  const handleError = (e: Error) => {
    if (e instanceof DOMException && e.name === "AbortError") return;
    setNotification("error", "Oops, something went wrong");
  };

  const getSplitGroup = useQuery<IActionResult<ISplitGroup>, Error>({
    queryKey: ['splitGroup', splitGroupId],
    queryFn: ({ signal }) => SplitGroupAPI.getSplitGroup(splitGroupId as number, signal),
    onSuccess: (result) => dispatch(result),
    onError: handleError,
    enabled: !!splitGroupId
  });

  const create = useMutation<IActionResult<ISplitGroup>, Error, { projectId: string, folderId: string, name: string, testIds?: string[] }>({
    mutationKey: ['splitGroup', 'create'],
    mutationFn: (data) => SplitGroupAPI.createSplitGroup(data.projectId, data.folderId, data.name, data.testIds),
    onSuccess: (result) => {
      dispatch(result);
      queryClient.invalidateQueries('user');
    },
    onError: handleError
  });

  /** Получить список сплит групп для текущей папки */
  const list = useQuery<IActionResult<ISplitGroup[]>, Error>({
    queryKey: ['splitGroups', folderId],
    queryFn: ({ queryKey, signal }) => {
      const [, folderId] = queryKey;
      return SplitGroupAPI.listSplitGroups(folderId as string, undefined, signal);
    },
    onSuccess: (result) => dispatch(result),
    onError: handleError,
    enabled: !!folderId
  });

  const update = useMutation<
    IActionResult<ISplitGroup>,
    Error,
    { splitGroupId: number, projectId: string, update: SplitGroupUpdate }
  >({
    mutationKey: ['splitGroup', 'update'],
    mutationFn: (data) => SplitGroupAPI.updateSplitGroup(data.splitGroupId, data.projectId, data.update),
    onSuccess: (result) => {
      dispatch(result)
    },
    onError: handleError
  });

  const deleteSplitGroup = useMutation<IActionResult<number>, Error, { splitGroupId: number, name: string }>({
    mutationKey: ['splitGroup', 'delete'],
    mutationFn: (data) => SplitGroupAPI.deleteSplitGroup(data.splitGroupId),
    onSuccess: (result, data) => {
      dispatch(result);
      setNotification("warning", `Split group ${data.name} deleted.`);
      queryClient.invalidateQueries('user');
    },
    onError: handleError
  });

  return {
    list,
    getSplitGroup,
    create,
    update,
    delete: deleteSplitGroup,

  };
}
