import React from "react";
import { IconDelete } from "../../../icons";
import { IClickmapProps } from "../Clickmaps/Clickmap";
import { AreaWithTargetClicks } from './useClickAreaStats';
import { useTranslation } from 'react-i18next';

export type IFigmaAreasProps = Pick<IClickmapProps, "onAreaAdded"> & {
	areas: AreaWithTargetClicks[];
	testersCount: number;
};

export default function FigmaAreas(props: IFigmaAreasProps) {
	const { t } = useTranslation();

	const removeArea = (index: number) => {
		const newAreas = props.areas.filter((a) => a.index !== index).map((a, i) => ({ ...a, index: i + 1 }));
		props.onAreaAdded(newAreas);
	};

	return (
		<div>
			<div className=" text-white font-medium mt-4">{t("Click Areas")}</div>

			{props.areas.length === 0 && (
				<div className="text-gray-600 mt-2 text-sm">Draw a rectangle on image to create a click area</div>
			)}

			{props.areas.map((area) => {
				return (
					<div className="flex items-start mt-3" key={area.index}>
						<div
							className="rounded-full bg-white text-gray-800 px-2 mr-3 text-center"
							style={{ minWidth: 32, lineHeight: "32px" }}
						>
							{area.index}
						</div>
						<div>
							<div>
								{t("{{count}} click", { count: area.targetClicks?.length || 0 })}
							</div>
							<div>
								{t("{{count}} missclick", { count: area.missedClicks?.length || 0 })}
							</div>
							<div className="flex">
								<div className="mr-1">
									{!('testersShare' in area) && t("{{count}} tester", {
										count: area.testers?.length || 0
									})
									}
									{('testersShare' in area) && t("{{count}} of {{total}} tester", {
										count: area.testers?.length || 0,
										total: props.testersCount || 0
									}
									)}
								</div>
								<div className="text-gray-600">({area.testersShare}%)</div>
							</div>
						</div>
						<div className="ml-auto">
							<IconDelete
								className={`transition duration-75 ease-in fill-current text-gray-600 cursor-pointer hover:text-gray-400`}
								onClick={() => removeArea(area.index)}
							/>
						</div>
					</div>
				);
			})}
		</div>
	);
}
