import React, { useState, useEffect } from "react";
import clsx from "clsx";
import _ from "lodash";
import "../../../../../tailwind.generated.css";

import SmartInput from "../../../../SmartInput";

import { IconCheck } from "../../../../../icons";

import { locale, interfaceText } from "../../../../../helpers";
import { IOptionReply } from '../../../../../models/Test';

type ReplyType = "single" | "multi";

export interface ISelectProps {
  type: ReplyType;
  data: IOptionReply[];
  handler: (key: string, value: any) => void;
  isRandomized: boolean;
  withOther: boolean;
  selectedOptions: { id: string, value: string }[];
  hasChoiceLimit?: boolean;
  choiceLimit?: number;
}

const Select = ({
  type,
  data,
  handler,
  isRandomized,
  withOther,
  selectedOptions,
  ...props
}: ISelectProps) => {
  const [options, setOptions] = useState<IOptionReply[] | null>(null);
  const [showOtherInput, setShowOtherInput] = useState(false);
  const [otherValue, setOtherValue] = useState<string | null>(null);

  const isMobile = window.screen.width <= 480;
  const otherPlaceholder = (interfaceText.test as any)[locale()].otherPlaceholder;

  useEffect(() => {
    setOptions(isRandomized ? getShuffledOptions(data) : data);
  }, [data, isRandomized]);

  useEffect(() => {
    if (otherValue === null) return;
    if (otherValue.length > 0) {
      const option = { id: "other", value: otherValue };
      addSelectedOption(option);
    } else {
      removeSelectedOption("other");
    }
  }, [otherValue]);

  function isOptionSelected(optionId: string) {
    if (!selectedOptions) {
      return false;
    }
    return selectedOptions.some((option) => option.id === optionId);
  }

  function handleSelect(optionId: string) {
    if (withOther && showOtherInput && type === "single") {
      setShowOtherInput(false);
    }

    if (isOptionSelected(optionId)) {
      removeSelectedOption(optionId);
      return;
    }

    if (isSelectedOptionsLimitReached()) return;

    const chosenItem = {
      id: optionId,
      value: options?.find((option) => option.id === optionId)?.replyValue as string,
    };

    addSelectedOption(chosenItem);
  }

  function handleOtherSelect() {
    if (isSelectedOptionsLimitReached()) return;
    setShowOtherInput(true);
  }

  function addSelectedOption(chosenItem: { id: string, value: string }) {
    let newSelectedOptions = null as (typeof chosenItem)[] | null;
    switch (type) {
      case "single":
        newSelectedOptions = [chosenItem];
        break;
      case "multi":
        newSelectedOptions = [...selectedOptions.filter((option) => option.id !== chosenItem.id), chosenItem];
        break;
      default:
        break;
    }
    handler("selectedOptions", newSelectedOptions);
  }

  function removeSelectedOption(optionId: string) {
    handler("selectedOptions", [...selectedOptions.filter((option) => option.id !== optionId)]);
  }

  function handleOtherValueChange(value: string) {
    setOtherValue(value);
  }

  function onOtherInputBlur(value: string) {
    if (value.length === 0) {
      setShowOtherInput(false);
    }
  }

  function isSelectedOptionsLimitReached() {
    return type === 'multi' && props.hasChoiceLimit && selectedOptions.length >= props.choiceLimit!;
  }

  return (
    <>
      {options && options.map((item) => <ChoiceOption
        className={clsx({ "hover:border-blue-600": !isMobile })}
        key={item.id}
        value={item.replyValue}
        isSelected={isOptionSelected(item.id)}
        onClick={() => handleSelect(item.id)}
        withCheckMark={type === "multi"}
      />)}

      {withOther && <ChoiceOption
        className={clsx({ "hover:border-blue-600": !isMobile })}
        key={'other'}
        value={showOtherInput ?
          (<div className="flex-1">
            <SmartInput
              className="w-full"
              id="other-input"
              placeholder={otherPlaceholder}
              initialValue=""
              onChange={handleOtherValueChange}
              onBlur={onOtherInputBlur}
              focusOnMount
            />
          </div>) :
          (interfaceText.test as any)[locale()].other
        }
        isSelected={isOptionSelected("other")}
        onClick={handleOtherSelect}
        withCheckMark={type === "multi"}
      />}

      {type === "multi" && (
        <div className="my-4 text-sm text-gray-700">
          {!props.hasChoiceLimit && (interfaceText.test as any)[locale()].multipleChoiceTip}
          {!!props.hasChoiceLimit && (interfaceText.test as any)[locale()].multipleChoiceWithLimitTip.replace('{{count}}', props.choiceLimit!.toString())}
        </div>
      )}
    </>
  );
};

export default Select;


function ChoiceOption(props: { className?: string, value: string, isSelected: boolean, withCheckMark?: boolean, onClick: () => void }) {
  return <div
    className={clsx('w-full py-1 px-4 text-lg my-3 rounded-lg border-2 cursor-pointer transition-all duration-75 ease-in flex items-center justify-between',
      {
        "border-blue-600": props.isSelected,
        "border-gray-200": !props.isSelected
      },
      props.className
    )}
    onClick={props.onClick}
  >
    {props.value}
    {props.withCheckMark && <ChoiceCheckMark isVisible={props.isSelected} />}
  </div>
}

function ChoiceCheckMark(props: { isVisible: boolean }) {
  return <IconCheck
    className={clsx(props.isVisible ? "visible" : "invisible",
      "flex-shrink-0 fill-current text-gray-800")}
  />
}

function getShuffledOptions(options: IOptionReply[]) {
  const optionsWithoutFixedPosition = options.filter((option) => typeof option.fixedPosition !== 'number');
  const optionsWithFixedPosition = options.filter((option) => typeof option.fixedPosition === 'number');
  // shuffle options without fixed position and insert options with fixed position on their places
  const shuffledOptions = _.shuffle(optionsWithoutFixedPosition);
  optionsWithFixedPosition.forEach((option) => {
    shuffledOptions.splice(option.fixedPosition as number, 0, option);
  });

  return shuffledOptions;
}