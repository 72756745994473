
export function useSessionStorage(prefix: string = 'PATHWAY_') {

	return useStorage(window.sessionStorage, prefix);
}

export type PWStore = ReturnType<typeof useStorage>;

function useStorage(storage: Storage, prefix: string) {

	function setItem(key: string, item: any) {
		storage.setItem(`${prefix}${key}`, JSON.stringify(item));
	}

	function getItem<T>(key: string) {
		const str = storage.getItem(`${prefix}${key}`);
		if (str !== null) {
			try {
				return JSON.parse(str) as T;
			} catch (error) {
				console.warn(`Error while parsing value from key ${key}, value is: \n\n ${str}`);
				return null;
			}
		}
		return str;
	}

	function removeItem(key: string) {
		storage.removeItem(`${prefix}${key}`);
	}

	return {
		getItem,
		setItem,
		removeItem
	}
}