import React from "react";
import _ from "lodash";
import {
	IReportTestData,
	IReportFilter,
	IBlockFilterCondition,
	IUrlFilterCondition,
	ISourceFilterCondition,
	isBlockFilterCondition,
	isUrlFilterCondition,
	isSourceFilterCondition,
	isPanelStatusFilterCondition,
	FilterConditionOperator,
	IReportFilterCondition,
	IPanelStatusFilterCondition,
	IUrlTags,
	IAnswerMetaData,
} from "../../models/Report";
import { BlockType, FigmaBlock, IFigmaPrototype, ITest, ScaleBlock } from "../../models/Test";
import {
	IBlockTestResponse,
	isScaleResponse,
	isOpenQuestionsResponse,
	isChoiceResponse,
	isPreferenceResponse,
	IIndividualTestResponse,
	ICardSortResponse,
	isFigmaResponse,
} from "../../models/Response";
import { ITestAnswer } from "../../models/Report/ITestAnswer";

import { formatDate } from "../../i18n/utils";
import i18n from "../../i18n/config";

const isInvalidAnswer = (invalidAnswers: string[], answerId: string) => {
	return invalidAnswers.some((id) => id === answerId);
};

const isDeletedAnswer = (deletedAnswers: string[], answerId: string) => {
	return deletedAnswers.some((id) => id === answerId);
};

export const removeInvalidOrDeletedAnswers = (testData: IReportTestData | undefined | null) => {
	if (!testData) return {};
	const invalidAnswers = testData.invalidAnswers || [];
	const deletedAnswers = testData.deletedAnswers || [];

	const answers = { ...testData.answers };

	Object.keys(answers).forEach((blockId) => {
		if (!testData.publishedContent?.find((block) => block.blockId === blockId)) {
			delete answers[blockId];
			return;
		}
		// todo array.filter
		answers[blockId].responses.forEach((answer, i) => {
			if (isDeletedAnswer(deletedAnswers, answer.answerId) || isInvalidAnswer(invalidAnswers, answer.answerId)) {
				answers[blockId].responses.splice(i, 1);
				return;
			}
		});
	});

	return answers;
};

export const getTotalUniqueResponses = (data: IReportTestData | null | undefined) => {
	if (!data) return { answerIdData: [], total: 0 };
	const publishedContent = data.publishedContent || [];
	const answers = data.answers;
	const counter: { answerIdData: string[]; total: number } = {
		answerIdData: [],
		total: 0,
	};

	if (!Object.keys(answers).length) return counter;

	publishedContent.forEach((block) => {
		if (answers[block.blockId]) {
			answers[block.blockId].responses.forEach((response) => {
				const isInvalidAnswer = data.invalidAnswers?.some((id) => id === response.answerId);
				const isDeletedAnswer = data.deletedAnswers?.some((id) => id === response.answerId);
				if (
					!counter.answerIdData.some((answerId) => answerId === response.answerId) &&
					!isInvalidAnswer &&
					!isDeletedAnswer
				) {
					counter.answerIdData.push(response.answerId);
					counter.total++;
				}
			});
		}
	});
	return counter;
};

export type TestAnswersMap = Record<string, { responses: Required<IBlockTestResponse>[] }>;
export const getConditionFilteredResponses = (data: IReportTestData | undefined | null, filter: IReportFilter, answers: TestAnswersMap) => {

	if (!data) return [];
	const answerIdData = getTotalUniqueResponses(data).answerIdData;
	if (_.isEmpty(filter.conditions)) {
		return answerIdData;
	}
	const answersMetaData = data.answersMetaData;

	const resolveSourceCondition = (condition: ISourceFilterCondition) => {
		// check if condition is valid, if not return all answers
		if (!condition.value) {
			return answerIdData;
		}
		return answerIdData.filter((answerId) => {
			const answerMetaData = answersMetaData[answerId];
			return _.get(answerMetaData, "source") === condition.value;
		});
	};

	const resolvePanelStatusCondition = (condition: IPanelStatusFilterCondition) => {
		// check if condition is valid, if not return all answers
		if (!condition.value) {
			return answerIdData;
		}
		return answerIdData.filter((answerId) => {
			const answerMetaData = answersMetaData[answerId];
			return _.get(answerMetaData, "panelRespondentStatus") === condition.value;
		});
	};

	const resolveUrlCondition = (condition: IUrlFilterCondition) => {
		// check if condition is valid, if not return all answers
		if ((!condition.value && condition.condition !== "inAnswer") || !condition.condition || !condition.tag)
			return answerIdData;

		switch (condition.condition) {
			case "inAnswer":
				return answerIdData.filter((answerId) => {
					const answerMetaData = answersMetaData[answerId];
					const urlTags = answerMetaData.urlTags;
					if (!urlTags || Object.keys(urlTags).length === 0) return false;
					return Object.keys(urlTags).some((tag) => tag === condition.tag);
				});
			case "is":
				return answerIdData.filter((answerId) => {
					const answerMetaData = answersMetaData[answerId];
					const urlTags = answerMetaData.urlTags;
					if (!urlTags) return false;
					return Object.keys(urlTags).some((tag) => tag === condition.tag && urlTags[tag] === condition.value);
				});
			case "contains":
				return answerIdData.filter((answerId) => {
					const answerMetaData = answersMetaData[answerId];
					const urlTags = answerMetaData.urlTags;
					if (!urlTags) return false;
					return Object.keys(urlTags).some((tag) => {
						return _.includes(_.toLower(urlTags[tag]), _.toLower(condition.value)) && tag === condition.tag;
					});
				});
		}
	};

	const resolveBlockCondition = (condition: IBlockFilterCondition) => {
		// check if condition is valid
		if (!condition.blockId || !condition.condition || (condition.condition !== "hasAnswer" && !condition.value)) {
			// returning all data, condition is not valid
			return answerIdData;
		}

		const filteredAnswersIds: string[] = [];
		const block = data.publishedContent.find((block) => block.blockId === condition.blockId);
		if (!block) {
			return answerIdData;
		}
		const blockAnswers = answers[condition.blockId];
		const blockType = block.type;

		switch (condition.condition) {
			case "hasAnswer":
				blockAnswers.responses.forEach((response) => {
					filteredAnswersIds.push(response.answerId);
				});
				return filteredAnswersIds;
			case "is":
				switch (blockType) {
					case BlockType.scale:
						blockAnswers.responses.forEach((response) => {
							if (isScaleResponse(response)) {
								if (response.selectedOption === condition.value) {
									filteredAnswersIds.push(response.answerId);
								}
							}
						});
						return filteredAnswersIds;
				}
			case "contains":
				switch (blockType) {
					case BlockType.openquestion:
						blockAnswers.responses.forEach((response) => {
							if (isOpenQuestionsResponse(response)) {
								if (_.includes(_.toLower(response.textValue), _.toLower(condition.value))) {
									filteredAnswersIds.push(response.answerId);
								}
							}
						});
						return filteredAnswersIds;
					case BlockType.choice:
						blockAnswers.responses.forEach((response) => {
							if (isChoiceResponse(response)) {
								if (response.selectedOptions.find((option) => option.id === condition.value)) {
									filteredAnswersIds.push(response.answerId);
								}
							}
						});
						return filteredAnswersIds;
					case BlockType.preference:
						blockAnswers.responses.forEach((response) => {
							if (isPreferenceResponse(response)) {
								if (response.selectedOptions.find((option) => option.id === condition.value)) {
									filteredAnswersIds.push(response.answerId);
								}
							}
						});
						return filteredAnswersIds;
					case BlockType.figma:
						blockAnswers.responses.forEach((response) => {
							if (isFigmaResponse(response) && response.path?.length && response.path.includes(condition.value || '')) {
								filteredAnswersIds.push(response.answerId);
							}
						});
						return filteredAnswersIds;
				}
				return filteredAnswersIds;
			case "doesNotContain":
				switch (blockType) {
					case BlockType.openquestion:
						filteredAnswersIds.push(...answerIdData);
						blockAnswers.responses.forEach((response) => {
							if (isOpenQuestionsResponse(response) && condition.value) {
								if (response.textValue.includes(condition.value)) {
									const answerIdIndex = filteredAnswersIds.findIndex((el) => el === response.answerId);
									filteredAnswersIds.splice(answerIdIndex, 1);
								}
							}
						});
						return filteredAnswersIds;
					case BlockType.choice:
						filteredAnswersIds.push(...answerIdData);
						blockAnswers.responses.forEach((response) => {
							if (isChoiceResponse(response)) {
								if (response.selectedOptions.find((option) => option.id === condition.value)) {
									const answerIdIndex = filteredAnswersIds.findIndex((el) => el === response.answerId);
									filteredAnswersIds.splice(answerIdIndex, 1);
								}
							}
						});
						return filteredAnswersIds;
					case BlockType.preference:
						filteredAnswersIds.push(...answerIdData);
						blockAnswers.responses.forEach((response) => {
							if (isPreferenceResponse(response)) {
								if (response.selectedOptions.find((option) => option.id === condition.value)) {
									const answerIdIndex = filteredAnswersIds.findIndex((el) => el === response.answerId);
									filteredAnswersIds.splice(answerIdIndex, 1);
								}
							}
						});
						return filteredAnswersIds;
				}
			case "completedOn":
				switch (blockType) {
					case BlockType.figma:
						blockAnswers.responses.forEach((response) => {
							if (isFigmaResponse(response) && response.path?.length && _.last(response.path) === condition.value) {
								filteredAnswersIds.push(response.answerId);
							}
						});
						return filteredAnswersIds;
				}
			case "notCompleted":
				switch (blockType) {
					case BlockType.figma:
						blockAnswers.responses.forEach((response) => {
							if (isFigmaResponse(response) && response.givenUp) {
								filteredAnswersIds.push(response.answerId);
							}
						});
						return filteredAnswersIds;
				}
		}
	};

	const resolveCondition = (condition: IReportFilterCondition) => {
		if (isBlockFilterCondition(condition)) {
			return resolveBlockCondition(condition);
		}
		if (isSourceFilterCondition(condition)) {
			return resolveSourceCondition(condition);
		}
		if (isUrlFilterCondition(condition)) {
			return resolveUrlCondition(condition);
		}
		if (isPanelStatusFilterCondition(condition)) {
			return resolvePanelStatusCondition(condition);
		}
	};

	return Object.keys(filter.conditions).reduce((acc, conditionId) => {
		const conditionResult = resolveCondition(filter.conditions[conditionId]);
		if (!conditionResult) {
			return acc;
		}
		return filter.conditions[conditionId].operator === FilterConditionOperator.and
			? (acc = _.intersection(conditionResult, acc))
			: (acc = _.uniq([...conditionResult, ...acc]));
	}, answerIdData);
};

export const calcMedian = (numbers: number[]) => {
	const sorted = Array.from(numbers).sort((a, b) => a - b);
	const middle = Math.floor(sorted.length / 2);

	if (sorted.length % 2 === 0) {
		return (sorted[middle - 1] + sorted[middle]) / 2;
	}

	return sorted[middle];
};

export const calcMean = (numbers: number[]) => {
	return numbers.reduce((acc, curr) => acc + curr, 0) / numbers.length;
};

export const msToSeconds = (ms: number) => {
	return (ms / 1000).toFixed(1);
};

export const getScaleRepliesList = (block: ScaleBlock) => {
	const emojiRepliesList: { name: JSX.Element; value: string }[] = [
		{ name: <>&#128544;</>, value: "-2" },
		{ name: <>&#128577;</>, value: "-1" },
		{ name: <>&#128528;</>, value: "0" },
		{ name: <>&#128578;</>, value: "1" },
		{ name: <>&#128525;</>, value: "2" },
	];

	if (block.replyType === "emoji") {
		return block.selections === "3" ? emojiRepliesList.slice(1, -1) : emojiRepliesList;
	} else {
		return _.range(parseInt(block.from), parseInt(block.to) + 1).map((num) => {
			const name = num.toString();
			return { name: name, value: name };
		});
	}
};

export function getFigmaCopmletedOnFilterOptions(block: FigmaBlock, prototype?: IFigmaPrototype | null) {
	if (typeof block.goalNode === 'string') {
		return [{ name: getPrototypeScreenName(block, prototype, block.goalNode), value: block.goalNode }];
	}
	if (Array.isArray(block.goalNode)) {
		return block.goalNode.map((nodeId) => {
			return {
				name: getPrototypeScreenName(block, prototype, nodeId),
				value: nodeId,
			};
		});
	}
	return [{ name: block.nodeNames[block.goalNode], value: block.goalNode }];
}

function getPrototypeScreenName(block: FigmaBlock, prototype: IFigmaPrototype | null | undefined, nodeId: string) {
	if (block.nodeNames?.[nodeId]) {
		return block.nodeNames[nodeId];
	}
	if (block.prototypeScreens?.length) {
		return block.prototypeScreens.find((screen) => screen.id === nodeId)?.name || nodeId;
	}
	if (prototype?.nodesForHtml?.[nodeId]) {
		return prototype.nodesForHtml[nodeId].name;
	}

	return nodeId;
}

export function getFigmaContainsFilterOptions(block: FigmaBlock, prototype: IFigmaPrototype | null | undefined) {
	if (block.nodeNames && Object.keys(block.nodeNames).length > 0) {
		return Object.entries(block.nodeNames).map(([nodeId, nodeName]) => {
			return {
				name: nodeName,
				value: nodeId,
			};
		});
	} else if (block.prototypeScreens?.length) {
		return block.prototypeScreens.map((screen) => ({ name: screen.name, value: screen.id }));
	}
	if (prototype) {
		return Object.entries(prototype.nodesForHtml)
			.filter(([_, node]) => !!node.name)
			.map(([nodeId, node]) => ({ name: node.name, value: nodeId }));
	}
	return [];
}

export const findReplyNameById = (id: string, repliesList: { name: any; value: string }[]) => {
	return repliesList.find((reply) => reply.value === id)?.name;
};

export function searchResponses(query: string | null, responses: IIndividualTestResponse[]) {
	if (!query) {
		return responses;
	}

	function containsSubstring(query: string | undefined, text: string | undefined) {
		if (!text || !query) {
			return false;
		}
		return text.toLowerCase().includes(query.toLowerCase());
	}

	function searchInBlockAnswerData(data: IBlockTestResponse): boolean {
		if (isOpenQuestionsResponse(data)) {
			return containsSubstring(query as string, data.textValue);
		}
		return false;
	}

	function searchInUrlTags(urlTags: IUrlTags | {} | undefined | null): boolean {
		function isIUrlTags(obj: {} | IUrlTags): obj is IUrlTags {
			return typeof obj === "object" && !Array.isArray(obj);
		}

		if (!urlTags || !isIUrlTags(urlTags)) {
			return false;
		}
		return Object.keys(urlTags).some((tag: string) => containsSubstring(query as string, urlTags[tag]));
	}

	return responses.filter((response) => {
		return response.blockAnswers.some(({ data }) => searchInBlockAnswerData(data) || searchInUrlTags(response.urlTags));
	});
}

export function getAnswersByBlocksWithMetadata(rawAnswers: ITestAnswer[]) {
	const answersMetaData: Record<string, IAnswerMetaData> = {};
	const answersByBlocks: Record<string, { responses: IBlockTestResponse[] }> = {};

	rawAnswers.forEach((answer) => {
		const answerId = answer.answerId;

		if (answer.meta) {
			answersMetaData[answerId] = answer.meta;
		}

		if (answer.panelRespondentStatus) {
			_.set(answersMetaData[answerId], "panelRespondentStatus", answer.panelRespondentStatus);
		}

		if (answer.blocks) {
			Object.entries(answer.blocks).forEach(([blockId, blockResponse]: any[]) => {
				if (!answersByBlocks[blockId]) {
					answersByBlocks[blockId] = { responses: [] };
				}

				answersByBlocks[blockId].responses.push({
					...blockResponse,
					answerId,
				});
			});
		}
	});

	return {
		answersMetaData,
		answers: answersByBlocks,
	};
}

export function patchTestWithScreeningQuestions(test: ITest) {
	const patchedWithScreeningReportTestData = _.cloneDeep(test) as ITest & {
		answersMetaData: Record<string, IAnswerMetaData>;
	};

	const screeningQuestions = patchedWithScreeningReportTestData.customPanelScreeningQuestions;

	if (screeningQuestions) {
		patchedWithScreeningReportTestData.content = [
			...screeningQuestions,
			...patchedWithScreeningReportTestData.content,
		];
		patchedWithScreeningReportTestData.publishedContent = [
			...screeningQuestions,
			...patchedWithScreeningReportTestData.publishedContent,
		];
	}

	return patchedWithScreeningReportTestData;
};

export const isMobile = (userAgent: string | undefined) => {
	if (!userAgent) return false;
	return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(userAgent);
}

export const getResponseDate = (answer: any): Date | undefined => {
    try {
        const answerBlocks = answer.blockAnswers;
        if (!answerBlocks || answerBlocks.length === 0) return undefined;

        const lastBlock = answerBlocks[answerBlocks.length - 1];
        if (!lastBlock || !lastBlock.data || !lastBlock.data.completedAt) return undefined;

        const lastBlockCompletedAt = lastBlock.data.completedAt;
        const responseDate = new Date(lastBlockCompletedAt);

        if (isNaN(responseDate.getTime())) return undefined;

        return responseDate;
    } catch (error) {
        return undefined;
    }
};

export const getFormattedDate = (date: Date | undefined): string | undefined => {
	if (!date) return undefined;
    try {
        if (!date || isNaN(date.getTime())) return undefined;
        const formattedDate = formatDate(date, i18n.language);
        return formattedDate;
    } catch (error) {
        return undefined;
    }
};

export const getSummaryData = (reportTestData: IReportTestData | null | undefined, filteredAnswers: string[], selectedBlockId: string | null, setSelectedBlockId: (selectedBlockId: string) => void) => {
    if (!reportTestData) return null;

    const filteredAnswersMap = new Map<string, boolean>();
    filteredAnswers?.forEach((answerId) => filteredAnswersMap.set(answerId, true));

    const blocks = reportTestData.publishedContent.map((block) => {
        const blockResponses = reportTestData.answers[block.blockId]?.responses.filter(
            (response: Required<IBlockTestResponse>) => (filteredAnswersMap.size > 0 && filteredAnswersMap.has(response.answerId)) || filteredAnswersMap.size === 0
        );

        return {
            ...block,
            responses: blockResponses || []
        };
    }).filter(block => block.responses.length > 0);

	const currentBlock = blocks.find(e => e.blockId === selectedBlockId);



	if (!selectedBlockId || !blocks.find(e => e.blockId === selectedBlockId)) {
		setSelectedBlockId(blocks[0]?.blockId);
	}

	return { blocks, currentBlock }
}

export const getResponsesData = (reportTestData: IReportTestData | null | undefined, filteredAnswers: string[], searchQuery: string | null, selectedAnswerId: string | null, setSelectedAnswerId: (selectedAnswerId: string) => void) => {

	const responseData: IIndividualTestResponse[] = [];

	if (!reportTestData) return null;
  
	const filteredAnswersMap = new Map<string, boolean>();
	filteredAnswers?.forEach((answerId) => filteredAnswersMap.set(answerId, true));
  
	const getResponseIndexById = (answerId: string) => {
	  const index = responseData.findIndex((e) => e.answerId === answerId);
	  return index > -1 ? index : null;
	};
  
	reportTestData.publishedContent.forEach((block) => {
	  const blockId = block.blockId;
	  const blockType = block.type;
	  if (blockType !== "context" && blockType !== "fiveseconds" && reportTestData.answers[blockId]) {
		reportTestData.answers[blockId].responses.forEach((response) => {
		  if (filteredAnswersMap.size > 0 && !filteredAnswersMap.has(response.answerId)) return;
  
		  const blockResponse = { ...response };
		  const responseIndex = getResponseIndexById(response.answerId);
		  const responseInfo = {
			data: blockResponse,
			blockId,
			blockType,
		  };
  
		  if (responseIndex !== null) {
			responseData[responseIndex].blockAnswers.push(responseInfo);
		  } else {
			responseData.push({
			  answerId: response.answerId,
			  userAgent: reportTestData.answersMetaData[response.answerId]?.userAgent,
			  urlTags: reportTestData.answersMetaData[response.answerId]?.urlTags,
			  blockAnswers: [responseInfo],
			});
		  }
		});
	  }
	});

	const responses = searchResponses(searchQuery, responseData).sort((a, b) => {
		const dateA = getResponseDate(a);
		const dateB = getResponseDate(b);
		return (dateB?.getTime() ?? 0) - (dateA?.getTime() ?? 0);
	});
	const currentResponse = responses.find(e => e.answerId === selectedAnswerId);


	if (!selectedAnswerId || !responses.find(e => e.answerId === selectedAnswerId)) {
		setSelectedAnswerId(responses[0]?.answerId);
	}
  
	if (!responses) {
	  return null;
	}

	return { responses, currentResponse }

}

