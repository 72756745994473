import { makeRequest } from '../http/Client';

export class PrototypesAPI {
	public static async fetchPrototype(protoId: string, signal?: AbortSignal) {
		const response = await makeRequest(`/api/prototypes/${protoId}`, 'GET', undefined, signal);
		if (response.ok) {
			const data = await response.json();
			return data;
		}
		else if (response.status === 400) {
			const error = await response.json();
			throw new Error('bad_request', { cause: error });
		}
		throw new Error('http_error', { cause: response });
	}
}