import React, { useState, useEffect } from "react";
import "../../../../../tailwind.generated.css";
import clsx from "clsx";

import Modal from "../../../../Modal";
import Button from "../../../../Button";

import _ from "lodash";

import { locale, interfaceText } from "../../../../../helpers";

import { IconInfo } from "../../../../../icons";

const Task = ({ text, onStart, onGiveUp, isLoading, blockId, withProgress, progress }) => {
  const [isTaskModalOpen, setIsTaskModalOpen] = useState(true);
  const [step, setStep] = useState(1);
  const [isTipPopoverOpen, setIsTipPopoverOpen] = useState(false);
  const [wasTipPopoverSeen, setWasTipPopoverSeen] = useState(false);
  const [isMissionInProgress, setIsMissionInProgress] = useState(false);

  useEffect(() => {
    setIsTaskModalOpen(true);
    setIsMissionInProgress(false);
  }, [blockId]);

  const isMobile = window.screen.width <= 480;

  return (
    <>
      <div
        className="absolute top-2 w-full cursor-pointer z-30"
        onClick={() => {
          setIsTaskModalOpen(true);
        }}
      >
        <div className="bg-blue-600 rounded-full mx-2 h-2"></div>
      </div>
      <div
        className={`task-tooltip taskTooltip absolute top-6 z-30 rounded-2xl text-white text-xl p-4 leading-0 mx-auto transition-all duration-75 ease-in ${
          isTipPopoverOpen ? "visible" : "invisible"
        }`}
      >
        {interfaceText.figmaTesting[locale()].showTaskTip}
        <Button
          className="task-tooltip__button mt-2"
          name={interfaceText.figmaTesting[locale()].showTaskTipButton}
          handler={() => {
            setIsTipPopoverOpen(false);
            setWasTipPopoverSeen(true);
          }}
          large
          type="primary"
        />
      </div>
      <Modal isOpen={isTaskModalOpen} setIsOpen={setIsTaskModalOpen} width="440px" disallowClickOutside background>
        <div className="h-full flex flex-col">
          {withProgress && progress}

          <div className={`p-6 flex flex-col flex-1`} style={{ maxWidth: "480px" }}>
            <div className={clsx(isMobile ? "justify-between" : "justify-center", "flex h-full flex-col")}>
              <div>
                <div className="figma-task__header flex text-xl font-bold font-display">
                  {interfaceText.figmaTestingIntro[locale()].header}
                </div>

                <div className={clsx(step === 2 && "prototypeTestingIntro", "mt-4 text-lg")}>
                  <div className="flex items-center my-4">
                    <IconInfo className="mr-2" />
                    <span className="font-bold font-display text-base">{interfaceText.figmaTestingIntro[locale()].instruction}</span>
                  </div>
                  <div>
                    <div className="leading-1">{interfaceText.figmaTestingIntro[locale()].text}</div>
                  </div>
                </div>

                {step === 2 && (
                  <div>
                    <div className="mt-4">
                      <div className="flex">
                        <div>
                          <div className="flex items-center">
                            {/* <IconTask className="mr-2" /> */}
                            <span className="font-bold font-display">{interfaceText.figmaTestingMission[locale()].header}</span>
                          </div>
                          <span className="block text-lg mt-2">{text}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {step === 1 && (
                <div>
                  <Button
                    className="figma-task__button-ok mt-4"
                    name={interfaceText.figmaTestingIntro[locale()].button}
                    handler={() => {
                      setStep(2);
                    }}
                    large
                    type="primary"
                  />
                </div>
              )}
              {step === 2 && (
                <div className="figma-task__controls">
                  <Button
                    className={`figma-task__button-start mt-4 ${isLoading ? "" : "active"}`}
                    name={
                      isLoading
                        ? interfaceText.figmaTestingMission[locale()].loading
                        : isMissionInProgress
                        ? interfaceText.figmaTestingMission[locale()].buttonWhenMissionStarted
                        : interfaceText.figmaTestingMission[locale()].button
                    }
                    handler={() => {
                      if (!isLoading) {
                        setIsTaskModalOpen(false);
                        setIsMissionInProgress(true);
                        if (!wasTipPopoverSeen) {
                          setIsTipPopoverOpen(true);
                        }
                        if (!isMissionInProgress) {
                          onStart();
                        }
                      }
                    }}
                    large
                    disabled={isLoading}
                    type="primary"
                  />
                  {isMissionInProgress && (
                    <span
                      className="block text-gray-600 font-medium text-xl mt-4 cursor-pointer hover:opacity-60"
                      onClick={() => {
                        onGiveUp();
                      }}
                    >
                      {interfaceText.figmaTesting[locale()].giveUpButton}
                    </span>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Task;
