import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import "../tailwind.generated.css";

type ModalProps = {
  isOpen: boolean;
  width: string;
  height?: string;
  setIsOpen: (isOpen: boolean) => void;
  background?: boolean;
  children: React.ReactNode;
  disallowClickOutside?: boolean;
  backgroundColor?: string;
  overlayColor?: string;
  noRoudnedCorners?: boolean;
  noHeightLimit?: boolean;
  backdrop?: boolean;
  style?: React.CSSProperties;
  slow?: boolean;
};

const Modal = ({
  isOpen,
  width,
  height = undefined,
  setIsOpen,
  background = true,
  children,
  disallowClickOutside = undefined,
  backgroundColor = "#FFFFFF",
  overlayColor = "rgba(30, 30, 30, 0.925)",
  noRoudnedCorners = undefined,
  noHeightLimit,
  backdrop = false,
  style = {},
  slow = false,
}: ModalProps) => {
  const isMobile = window.screen.width <= 480;

  const modalBackgroundColor = () => {
    if (!background) {
      return {};
    } else {
      return { backgroundColor: backgroundColor ? backgroundColor : "#FFFFFF" };
    }
  };

  const handleClickOutside = (e: Event) => {
    if ((e.target as HTMLElement).classList.contains("modal-container") && !disallowClickOutside) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
      document.body.style.overflow = "hidden";

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
        document.body.style.overflow = "visible";
      };
    }
  }, [isOpen]);

  return ReactDOM.createPortal(
    isOpen ? (
      <div
        className="modal-container"
        style={{
          ...(backdrop ? { backdropFilter: "blur(8px)" } : {}),
          ...(overlayColor ? { backgroundColor: overlayColor } : { backgroundColor: "rgba(30, 30, 30, 0.925)" }),
        }}
      >
        <div
          data-test-id="modalDialog"
          className={slow ? "modal-dialog-slow" : "modal-dialog"}
          style={{
            ...(isMobile ? { width: "100%", height: "100%" } : { width: width }),
            ...modalBackgroundColor(),
            ...(height && !isMobile && { height: height }),
            ...(!height && !isMobile && !noHeightLimit && { maxHeight: "90vh" }),
            ...(noRoudnedCorners ? {} : isMobile ? {} : { borderRadius: "1rem" }),
            ...style,
          }}
        >
          {children}
        </div>
      </div>
    ) : null,
    document.body
  );
};

export default Modal;
