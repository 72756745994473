import React, { useState } from "react";
import clsx from "clsx";
import "../tailwind.generated.css";

import {
	useFloating,
	autoUpdate,
	offset,
	flip,
	shift,
	useClick,
	useDismiss,
	useInteractions,
	FloatingPortal,
	FloatingFocusManager
} from "@floating-ui/react";

type PopoverProps = {
	content: string | JSX.Element;
	anchorClassName?: string;
	children: JSX.Element;
	position?: "top" | "bottom" | "left" | "right" | "left-end" | "left-start" | "top-end" | "top-start" | "bottom-end" | "bottom-start" | "right-end" | "right-start";
};

const Popover = ({
	content,
	anchorClassName,
	children,
	position
}: PopoverProps) => {
	const [isVisible, setIsVisible] = useState(false);

	const { x, y, strategy, refs, context } = useFloating({
		placement: position || "top",
		open: isVisible,
		onOpenChange: setIsVisible,
		middleware: [offset(10), flip(), shift()],
		whileElementsMounted: autoUpdate,
	});

	const click = useClick(context);
	const dismiss = useDismiss(context);

	const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss]);

	return (
		<>
		<div
			className={clsx("cursor-pointer", anchorClassName)}
			ref={refs.setReference}
			{...getReferenceProps()}
		>
			{children}
		</div>
			{isVisible && (
				<FloatingPortal>
					<div
						ref={refs.setFloating}
						style={{
							position: strategy,
							top: y ?? 0,
							left: x ?? 0,
							width: "max-content",
							zIndex: 999,
						}}
						{...getFloatingProps()}
						className="bg-white text-gray-800 rounded-lg p-3 border-gray-300 border-1 shadow-md z-999"
					>
						{content}
					</div>
				</FloatingPortal>
			)}
		</>
	);
};

export default Popover;