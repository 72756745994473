import React, { useState } from "react";
import clsx from "clsx";
import "../tailwind.generated.css";

import {
	useFloating,
	autoUpdate,
	offset,
	flip,
	shift,
	useHover,
	useFocus,
	useDismiss,
	useRole,
	useInteractions,
	FloatingPortal,
} from "@floating-ui/react";

type TooltipProps = {
	content: string | JSX.Element;
	anchorClassName?: string;
	children: JSX.Element;
	showOnlyIf?: boolean;
	position?: "top" | "bottom" | "left" | "right";
};

const Tooltip = ({
	content,
	anchorClassName,
	children,
	showOnlyIf = true, // set default to true so the tooltip shows by default when the prop is not provided
	position
}: TooltipProps) => {
	const [isVisible, setIsVisible] = useState(false);

	const { x, y, strategy, refs, context } = useFloating({
		placement: position || "top",
		open: isVisible,
		onOpenChange: (openState) => {
			if (showOnlyIf) {
				setIsVisible(openState);
			}
		},
		middleware: [offset(10)],
		whileElementsMounted: autoUpdate,
	});

	const hover = useHover(context, { move: false });
	const focus = useFocus(context);
	const dismiss = useDismiss(context);
	const role = useRole(context, { role: "tooltip" });

	const { getReferenceProps, getFloatingProps } = useInteractions([hover, focus, dismiss, role]);

	return (
		<div
			className={clsx("cursor-pointer", anchorClassName && anchorClassName)}
			ref={refs.setReference}
			{...getReferenceProps()}
		>
			{children}
			{isVisible && showOnlyIf && (
				<FloatingPortal>
					<div
						ref={refs.setFloating}
						style={{
							position: strategy,
							top: y ?? 0,
							left: x ?? 0,
							width: "max-content",
							zIndex: 9999,
						}}
						{...getFloatingProps()}
						className="bg-gray-900 text-white rounded-lg p-3 text-sm tooltip font-medium z-999"
					>
						{content}
					</div>
				</FloatingPortal>
			)}
		</div>
	);
};

export default Tooltip;
