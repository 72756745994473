import React, { useRef, useState } from "react";
import "../../../tailwind.generated.css";

import ClickViews from "./ClickViews";
import { Heatmap } from "../Clickmaps";
import TooltipWithIcon from "../../TooltipWithIcon";

import { IconExport, IconHeatmap, IconShow, IconZoom } from "../../../icons";

import { calcMedian } from "../utils";
import { IFirstClickResponse } from "../../../models/Response";
import { ClickmapContext } from "../Clickmaps";
import { useTranslation } from "react-i18next";
import Button from "../../Button";

import ImageWithZoom from "../../ImageWithZoom";
import Modal from "../../Modal";
import useDimensions from "../../Common/Hooks/useDimensions";

import { IconDownload } from "../../../icons";

export interface IFirstClickReportProps {
  text: string;
  imageUrl: string;
  responses: IFirstClickResponse[];
  testId: string;
  blockId: string;
}

function FirstClickReport(props: IFirstClickReportProps) {
  const { t } = useTranslation();
  const { responses } = props;

  const [isClickMapModalOpen, setIsClickMapModalOpen] = useState(false);

  // calc average response time
  const averageResponseTime = (
    Math.floor(
      responses.reduce((acc, curr) => {
        return acc + curr.clickData.responseTime;
      }, 0) / responses.length
    ) / 1000
  ).toFixed(2);

  // calculate median response time

  const numbers = responses.map((response) => response.clickData.responseTime);

  const medianResponseTime = (calcMedian(numbers) / 1000).toFixed(2);

  const clickMapPreviewRef = useRef<HTMLDivElement>(null);
  const clickMapPreviewWidth = useDimensions({ ref: clickMapPreviewRef }).width;

  const previewWidth = clickMapPreviewWidth;

  return (
    <>
      <div className="flex flex-col gap-4">
        <div className="firstclick-report__question">
          <span className="block caption">{t("Question")}</span>
          <div className="flex justify-start items-start gap-2 mb-4">
            {/* {props.imageUrl && <ImageWithZoom imageSrc={props.imageUrl} className="rounded-default mr-6 w-12 h-12" />} */}
            <div className="text-gray-700">{props.text}</div>
          </div>
        </div>
        <div className="firstclick-report__clickmap-preview flex items-center justify-start gap-8 bg-cover h-[96px] bg-gray-900 rounded-lg relative overflow-hidden">
          <div className="w-full flex items-center justify-between z-[60] px-6">
            <div className="firstclick-report__metrics flex items-center gap-6 text-white">
              <div className="firstclick-report__metric flex flex-col">
                <span className="header3">
                  {averageResponseTime} {t("s", { context: "seconds" })}
                </span>
                <span className="text-xs font-medium">{t("Average res. time")}</span>
              </div>
              <div className="firstclick-report__metric flex flex-col">
                <span className="header3">
                  {medianResponseTime} {t("s", { context: "seconds" })}
                </span>
                <span className="text-xs font-medium flex">
                  {t("Median res. time")}
                  <TooltipWithIcon
                    className="ml-1 inline text-white"
                    size={16}
                    text={
                      <>
                        {t("Median is the value separating the higher half from the lower half of testers responses.")}
                        <br />
                        <br />
                        {t("It is generally unaffected by outliers: responses with a very long response time.")}
                      </>
                    }
                  />
                </span>
              </div>
            </div>
            <div className="firstclick-report__buttons flex items-center justify-start gap-3">
              <div className="flex items-center justify-start gap-3 font-medium hover:opacity-75 transition-opacity cursor-pointer" onClick={() => setIsClickMapModalOpen(true)}>
                <IconZoom className="w-8 h-8 fill-current text-gray-800 bg-white rounded-full p-1" />
                <span className="text-white">{t("Show clickmap")}</span>
              </div>
            </div>
          </div>
          <img src={props.imageUrl} alt={props.text} className="absolute top-0 left-0 w-full opacity-[0.65] blur-[6px] scale-110" />
        </div>
      </div>
      <Modal isOpen={isClickMapModalOpen} setIsOpen={setIsClickMapModalOpen} width="90%" background={false}>
        <ClickmapContext clickmapOwnerId={`firstclick-${props.testId}-${props.blockId}`} imageUrl={props.imageUrl}>
          {(clickmap, updateClickmapAreas, figmaImage, isLoading) => (
            <ClickViews
              blockId={props.blockId}
              imageUrl={props.imageUrl}
              responses={props.responses}
              loading={isLoading}
              clickmap={clickmap}
              updateClickmapAreas={updateClickmapAreas}
              title={props.text}
              onClose={() => setIsClickMapModalOpen(false)}
            />
          )}
        </ClickmapContext>
      </Modal>
    </>
  );
}

export default FirstClickReport;
