import React, { useState, useEffect, useRef } from "react";
import clsx from "clsx";
import Button from "../../../../../Button";
import Loader from "../../../../../Loader";
import { locale, interfaceText } from "../../../../../../helpers";
import { ImagesPreloader } from './ContentBlock';
import { Stepper } from "./Stepper";
import { Preview } from "./Preview";
import { BlockType } from '../../../../../../models/Test';

import useIsMobile from "../../hooks/useIsMobile";

export interface IContentWrapperProps {
  hasButton: boolean;
  hasImage: boolean;
  image: any;
  content: React.ReactElement;
  nextStepOnClick: () => void;
  isValidated: boolean;
  isOptional: boolean;
  currentStep: number;
  totalSteps: number;
  /**
   * @obsolete not used
   */
  contentType: string;
  withProgress: boolean;
  showImagesLoader: boolean;
  block: any;
  isPreview: boolean;
}

export function ContentWrapper(props: IContentWrapperProps) {
  const { block } = props;

  const [honeypotLoader, setHoneypotLoader] = useState(block.honeypotDelay ? true : false);

  const isWide = block.type === BlockType.matrix;

  const isMobile = useIsMobile();

  useEffect(() => {
    setHoneypotLoader(block.honeypotDelay ? true : false);
  }, [block.blockId]);

  useEffect(() => {
    if (props.showImagesLoader === false && block.honeypotDelay) {
      setTimeout(() => {
        setHoneypotLoader(false);
      }, block.honeypotDelay);
    }
  }, [props.showImagesLoader]);

  const contentWrapperRef = useRef<HTMLDivElement>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    const checkOverflow = () => {
      if (contentWrapperRef.current) {
        const isContentOverflowing =
          contentWrapperRef.current.scrollHeight > contentWrapperRef.current.clientHeight;
        setIsOverflowing(isContentOverflowing);
      }
    };
    // todo: fix this logic - setTimeout is more like a hack
    setTimeout(checkOverflow, 100);
    window.addEventListener("resize", checkOverflow);
    return () => window.removeEventListener("resize", checkOverflow);
  }, []);

  if (!props.hasImage) {
    return (<div className="h-[100dvh] flex flex-col items-center">
      {props.isPreview && <Preview />}
      {props.withProgress && <Stepper currentStep={props.currentStep} totalSteps={props.totalSteps} />}
      <div id={`content-wrapper-${block.blockId}`} className={clsx("h-full w-full overflow-y-auto", isWide ? "md:w-4/5 lg:w-3/5" : "md:w-3/5 lg:w-1/2 xl:w-1/3")}>
        <div className={clsx("flex flex-col items-center justify-center min-h-full px-4 flex-1 w-full")}          >
          {props.content}
          {props.hasButton && (
            <div className="my-2 self-start">
              <Button
                id="nextButton"
                type="primary"
                name={(interfaceText.test as any)[locale()].nextButton}
                handler={() => props.nextStepOnClick()}
                large
                disabled={!props.isValidated && !props.isOptional} />
            </div>
          )}
        </div>
      </div>
    </div>);
  }
  return (
    <div className="h-[100dvh] flex flex-col items-center">
      <ImagesPreloader showImagesLoader={props.showImagesLoader} blockId={block.blockId} />
      {props.isPreview && <Preview />}
      {props.withProgress && <Stepper currentStep={props.currentStep} totalSteps={props.totalSteps} />}
      <div className={clsx("grid grid-auto grid-cols-1 lg:grid-rows-1 lg:grid-cols-2 w-full flex-1 overflow-y-auto")}>
        <div className="flex justify-center items-center p-8">
          {honeypotLoader && <Loader />}
          <div className={clsx(honeypotLoader && "hidden")}>{props.image}</div>
        </div>
        <div ref={contentWrapperRef} className={clsx("flex flex-col items-center w-full h-full px-4 flex-1 max-h-full p-8", isOverflowing && !isMobile && "overflow-y-auto", !isOverflowing && "justify-center")}>
          <div className="w-full lg:max-w-[80%]">
            {props.content}
            <div className="my-4 w-full">
              {props.hasButton && (
                <Button
                  type="primary"
                  name={(interfaceText.test as any)[locale()].nextButton}
                  handler={() => props.nextStepOnClick()}
                  large
                  disabled={!props.isValidated && !props.isOptional} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
