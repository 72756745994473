import clsx from 'clsx';
import React from 'react';
import { IconCheckMark } from '../../icons';

export default function CheckBox(props: {
	value: boolean;
	onChange: (value: boolean) => void;
	className?: string;
	disabled?: boolean;
}) {
	return (
		<div
			className={clsx(
				"border-1 flex items-center justify-center cursor-pointer z-10  transition-all duration-75 ease-in rounded-[4px] w-5 h-5 min-w-5 min-h-5",
				props.value ? "bg-blue-500 border-transparent" : "bg-white hover:border-gray-500 border-gray-400",
				props.disabled && "opacity-50 cursor-not-allowed",
				props.className && props.className
			)}
			onClick={() => {
				if (!props.disabled) {
					props.onChange(!props.value);
				}
			}}
		>
			{!!props.value && <IconCheckMark width={20} height={20} className="fill-current text-white" />}
		</div>
	);
}