import React, { useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import "../../tailwind.generated.css";
import _ from "lodash";
import clsx from "clsx";

import useDimensions from "../Common/Hooks/useDimensions";

import { getConditionFilteredResponses, getSummaryData, getResponsesData, getTotalUniqueResponses, removeInvalidOrDeletedAnswers } from "./utils";

import Tabs, { TabItem } from "../Tabs";
import Popover from "../Popover";
import Summary from "./Summary";
import Responses from "./Responses";
import Filters from "./Filters/Filters";
import Loader from "../Loader";
import PageNotFound from "../PageNotFound";
import ReportContentList from "./ReportContentList";
import ResponsesList from "./ResponsesList";

import ResponsesSearchInput from "./ResponsesSearchInput";

import { IconLink, IconMore, IconExport } from "../../icons";
import { useReport } from "./hooks/useReport";
import { useTranslation } from "react-i18next";
import { FigmaContextProvider } from "../Figma/FigmaContextProvider";

const Report = ({ sharedByLink }: { sharedByLink: boolean }) => {
  const { t } = useTranslation();
  const { testId }: { testId: string } = useParams();
  const sharingToken = sharedByLink ? new URLSearchParams(window.location.search).get("sharingToken") : undefined;
  const [filter, setFilter] = useState({ conditions: {} });

  const [tab, setTab] = useState<string>("summary");
  const [searchQuery, setSearchQuery] = useState<null | string>(null);
  const [selectedAnswerId, setSelectedAnswerId] = useState<string | null>(null);
  const [selectedBlockId, setSelectedBlockId] = useState<string | null>(null);


  const { isReportTestDataLoading, reportTestData, isHugeReport, deleteAnswer } = useReport({
    testId,
    sharingToken,
    setFilter,
  });

  const handleUpdateFilter = (update: any) => {
    setFilter((current) => {
      return {
        ...current,
        ...update(current),
      };
    });
  };
  // removing deleted and invalid answers
  const answers = useMemo(() => {
    return removeInvalidOrDeletedAnswers(reportTestData);
  }, [reportTestData]);

  // applying filters
  const filteredAnswers = useMemo(() => {
    return getConditionFilteredResponses(reportTestData, filter, answers);
  }, [reportTestData, filter, answers]);

  // calculating total unique responses
  const totalUniqueResponses = useMemo(() => {
    return getTotalUniqueResponses(reportTestData).total;
  }, [reportTestData]);


  const EmptyResponsesListMessage = (
    <div className="mt-4 text-gray-700">
      {totalUniqueResponses > 0 ? t("Nothing here. Try to change your filters.") : t("No responses yet")}
    </div>
  );

  const summaryData = useMemo(() => getSummaryData(reportTestData, filteredAnswers, selectedBlockId, setSelectedBlockId), [reportTestData, filteredAnswers, selectedBlockId, setSelectedBlockId]);
  const responseData = useMemo(() => getResponsesData(reportTestData, filteredAnswers, searchQuery, selectedAnswerId, setSelectedAnswerId), [reportTestData, filteredAnswers, searchQuery, selectedAnswerId, setSelectedAnswerId]);

  const navigationElDimensions = useDimensions({ id: "testpage__navigation" });

  if (isReportTestDataLoading || !reportTestData) {
    return (
      <div className="w-full h-screen flex items-center justify-center">
        <div>
          <Loader />
          {isHugeReport && (
            <span className="text-gray-700 mt-4 block text-sm">
              {t("Crunching a lot of data for you. This might take a bit longer than usual.")}
            </span>
          )}
        </div>
      </div>
    );
  }

  if (reportTestData === null) {
    return <PageNotFound />;
  }

  return (
    <div
      className={clsx("report__wrapper px-5 gap-12 w-full grid grid-cols-[240px_minmax(0,_1fr)] align-items-start", sharedByLink && "max-w-[1024px] mx-auto")}
      tabIndex={0}
    >
      <div className="report__navigation w-[240px] grow-1 overflow-hidden sticky top-0 py-6" style={{ height: `calc(100dvh - ${navigationElDimensions.height}px)` }}>
        <div className="flex justify-between items-start">
          <div className="report__name text-xl font-bold grow-default">{reportTestData.name}</div>
          <Popover
            styleMode="light"
            position="bottom-right"
            getContent={() => (
              <div className="w-auto px-1">
                <div className="flex flex-col items-start">
                  <a
                    className="cursor-pointer flex items-center justify-center my-1 hover:opacity-75 transition-all duration-75 ease-in"
                    href={`/api/tests/${testId}/export?sharingToken=${reportTestData.sharingToken}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <IconExport width={24} height={24} className="mr-2 fill-current text-gray-800" />
                    {t("Export .csv")}
                  </a>
                  {!sharedByLink && (
                    <a
                      className="cursor-pointer flex items-center justify-center my-1 hover:opacity-75 transition-all duration-75 ease-in"
                      href={`/${testId}/report?sharingToken=${reportTestData.sharingToken}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <IconLink className="mr-2 fill-current text-gray-800" />
                      {t("Share")}
                    </a>
                  )}
                </div>
              </div>
            )}
            anchorClassName="shrink-0 mt-1"
          >
            <IconMore width={24} height={24} className="fill-current text-gray-800" />
          </Popover>
        </div>
        <div className="report__filters flex items-center gap-3">
          <Filters data={reportTestData} filter={filter} handleUpdateFilter={handleUpdateFilter} />
        </div>
        <Tabs
          className="flex flex-col w-full"
          tabContentClassName="h-full flex flex-col grow-default w-full"
          onTabClick={(tab: string) => setTab(tab)}
        >
          <TabItem id="summary" label={t("Summary", { context: "tab" })}>
            {summaryData && filteredAnswers.length > 0 ? (
              <ReportContentList
                blocks={summaryData?.blocks}
                selectedBlockId={selectedBlockId}
                selectBlock={setSelectedBlockId}
              />
            ) : (
              EmptyResponsesListMessage
            )}
          </TabItem>
          <TabItem id="responses" label={t("Responses ({{responsesNum}})", { responsesNum: responseData?.responses.length })}>
            <ResponsesSearchInput
              placeholder={t("Search responses")}
              id="searchResponses"
              className="search-input__wrapper h-[36px] w-full bg-gray-300"
              onChange={(value: string) => {
                if (value === "") {
                  setSearchQuery(null);
                } else {
                  setSearchQuery(value);
                }
              }}
            />
            {responseData && responseData?.responses.length > 0 ? (
              <div className="flex flex-col gap-4 w-full grow-default">
                <ResponsesList
                  answers={responseData?.responses}
                  selectedAnswerId={selectedAnswerId}
                  selectAnswer={setSelectedAnswerId}
                />
              </div>
            ) : (
              EmptyResponsesListMessage
            )}
          </TabItem>
        </Tabs>
      </div>
      <div className="report__content h-full min-h-full w-full grow-0 max-w-full shrink-0 py-6">
        <FigmaContextProvider>
          {tab === "summary" &&
            (filteredAnswers.length > 0 && (
              <Summary
                testId={testId}
                currentBlock={summaryData?.currentBlock}
                totalBlocks={summaryData?.blocks.length}
                metadata={reportTestData.answersMetaData}
                sharedByLink={sharedByLink}
                sharingToken={sharingToken as string | undefined}
                i={summaryData?.blocks.findIndex(block => block.blockId === selectedBlockId)}
              />
            ))}
          {tab === "responses" &&
            (filteredAnswers.length > 0 && (
              <Responses
                testId={testId}
                testData={reportTestData}
                response={responseData?.currentResponse}
                handleDeleteResponse={(answerId: string) => deleteAnswer(answerId)}
                sharedByLink={sharedByLink}
                sharingToken={sharingToken as string | undefined}
              />
            ))}
        </FigmaContextProvider>
      </div>
    </div>
  );
};

export default Report;
