import React from "react";
import { IFormattedPrototype } from "../../../utils/figma";

// TODO: this is a temporary solution for unlocking the possibility to add logic for different Goal Screens.
// Here we create a common context for the Editor to keep all of the prototypes in there.

type PrototypesEditorContextType = {
  prototypes: { [key: string]: IFormattedPrototype };
  setPrototypes: any;
};

export const PrototypesEditorContext = React.createContext<PrototypesEditorContextType>({
  prototypes: {},
  setPrototypes: () => {},
});
