import React, { useRef, useState, useEffect } from "react";
import Button from "../../Button";
import Modal from "../../Modal";



import { getFigmaBlockGoalNode as getFigmaBlockGoalNodes } from "../../../utils/tests";
import { FigmaBlock } from '../../../models/Test';
import { useTranslation } from "react-i18next";
import FigmaImport from "./Import/FigmaImport";
import { FigmaPrototypeCodeSetup, FigmaPrototypeLinkSetup } from "./FigmaPrototypeSetup";

const DEFAUL_PROTOTYPE_BOX_HEIGHT = 640;

interface IFigmaEditorViewProps {
  blockData: FigmaBlock;
  updateTestBlock: (data: any, blockId: string) => void;
}

export default function FigmaEditorView(props: IFigmaEditorViewProps) {
  const { t } = useTranslation();
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const goalNodeIds = getFigmaBlockGoalNodes(props.blockData);

  const importButtonRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const url = new URL(window.location.href);
    const urlBlockId = url.searchParams.get('blockId');
    if (urlBlockId && urlBlockId === props.blockData.blockId) {
      if (importButtonRef?.current) {
        importButtonRef.current.scrollIntoView({ behavior: "auto" });
        setIsImportModalOpen(true);
      }
    }
  }, [importButtonRef.current])


  if (props.blockData.prototypeId) {
    return (
      <div className="mt-4">
        <span className="caption">{t("Prototype")}</span>
        <FigmaPrototypeCodeSetup
          prototypeId={props.blockData.prototypeId}
          goalNodeIds={goalNodeIds}
          key={`figma-prototype-${props.blockData.blockId}`}
          updateTestBlock={props.updateTestBlock}
          blockId={props.blockData.blockId} />
      </div>
    );
  }

  if (props.blockData.prototypeLink) {
    return (
      <div className="mt-4">
        <FigmaPrototypeLinkSetup
          block={props.blockData}
          updateTestBlock={props.updateTestBlock}
          blockId={props.blockData.blockId} />
      </div>
    );
  }

  return (
    <div ref={importButtonRef}>
      <Button
        name={t("Add prototype")}
        handler={() => setIsImportModalOpen(true)}
        type="primary"
        fullWidth
        className="mt-6"
      />
      <Modal isOpen={isImportModalOpen} setIsOpen={setIsImportModalOpen} width="480px" background>
        <FigmaImport blockId={props.blockData.blockId} onSubmitCodeImport={(value) => props.updateTestBlock(value, props.blockData.blockId)} updateTestBlock={props.updateTestBlock} setIsImportModalOpen={setIsImportModalOpen} />
      </Modal>
    </div>
  );
}