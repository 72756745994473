import React, { useState } from "react";
import clsx from "clsx";
import _ from "lodash";

import { IconHire, IconClose, IconEdit, IconUp, IconDown, IconShow, IconHide } from "../../../icons";

import SearchInput from "../../Common/SearchInput";
import CheckBox from "../../Common/Checkbox";
import Button from "../../Button";
import Input from "../../Input";
import Modal from "../../Modal";
import Switcher from "../../Switcher";
import Tooltip from "../../Tooltip";

import AutoMerge from "./AutoMerge";

import { useTranslation } from "react-i18next";


import { getShare } from "./utils";

import RenderIfVisible from "react-render-if-visible";


type CategoryBlockProps = {
    id: string;
    value: string;
    cards: {
        id: string;
        value: string | undefined;
        description: string | null | undefined;
        count: number;
    }[];
    totalResponses: number;
    selectedCategories: string[];
    setSelectedCategories: any;
    isGroup: boolean;
    updateGroupName: (groupId: string, name: string) => Promise<any>;
    deleteGroup: (groupId: string) => Promise<any>;
	isHidden: boolean;
	showHidden: boolean;
	hideCategory: (categoryId: string) => Promise<any>;
	unhideCategory: (categoryId: string) => Promise<any>;
    isOpenSort: boolean | undefined;
	totalCategories: number;
	sharedByLink?: boolean;
};

const CategoryBlock: React.FC<CategoryBlockProps> = ({ id, value, cards, totalResponses, selectedCategories, setSelectedCategories, isGroup, updateGroupName, deleteGroup, isHidden, showHidden, hideCategory, unhideCategory, isOpenSort, totalCategories, sharedByLink }) => {

    const { t } = useTranslation();

    const [isRenamingGroup, setIsRenamingGroup] = useState(false);
    const [isDeleteGroupModalOpen, setIsDeleteGroupModalOpen] = useState(false);
    const [isDeleteGroupLoading, setIsDeleteGroupLoading] = useState(false);
	const [isHidingCategory, setIsHidingCategory] = useState(false);
	const [showCards, setShowCards] = useState(false);

    const toggleCategorySelection = (categoryId: string) => {
        setSelectedCategories((currentCategories: string[]) => {
            if (currentCategories.includes(categoryId)) {
                return currentCategories.filter((selectedCategoryId: string) => selectedCategoryId !== categoryId);
            } else {
                return [...currentCategories, categoryId];
            }
        });
    }

	const selectionLimit = totalCategories - 1;

	return (
    <>
      <div className="my-4">
        <div className="flex items-center justify-between w-full h-8 group">
          <div className="flex items-center">
            {isOpenSort && !sharedByLink && (
              <CheckBox
                value={selectedCategories.includes(id)}
                onChange={() => {
                  toggleCategorySelection(id);
                }}
                className="mt-[2px] mr-3"
                disabled={selectedCategories.length === selectionLimit && !selectedCategories.includes(id)}
              />
            )}
            {isRenamingGroup ? (
              <Input
                focusOnMount
                selectAllOnFocus
                initialValue={value}
                className="default-input-small w-full"
                id={`${id}_group_name_edit`}
                placeholder={t("Enter category name")}
                onBlur={(value) => {
                  if (value && value !== "") {
                    updateGroupName(id, value || "");
                  }
                  setIsRenamingGroup(false);
                }}
              />
            ) : (
              <span className={clsx("font-medium block", isHidden && "opacity-50")}>{value}</span>
            )}
            {isGroup && !isRenamingGroup && !sharedByLink && (
              <div className="flex items-center ml-2 group-hover:opacity-100 opacity-0 transition-all duration-75 ease-in">
                <Button
                  type="secondary"
                  icon={<IconEdit className="fill-current text-gray-800 w-4 h-4" />}
                  handler={() => {
                    setIsRenamingGroup(true);
                  }}
                  extrasmall
                />
                <Button
                  type="secondary"
                  name={t("Undo merge")}
                  handler={() => {
                    setIsDeleteGroupModalOpen(true);
                  }}
                  className="ml-2"
                  extrasmall
                />
              </div>
            )}
            {!isGroup && !sharedByLink && (
              <div className="flex items-center ml-2 group-hover:opacity-100 opacity-0 transition-all duration-75 ease-in">
                <Button
                  type="secondary"
                  icon={
                    !isHidden ? (
                      <IconShow className="fill-current text-gray-800 w-4 h-4" />
                    ) : (
                      <IconHide className="fill-current text-gray-800 w-4 h-4" />
                    )
                  }
                  handler={async () => {
                    setIsHidingCategory(true);
                    if (isHidden) {
                      await unhideCategory(id);
                    } else {
                      await hideCategory(id);
                    }
                    setIsHidingCategory(false);
                  }}
                  isLoading={isHidingCategory}
                  extrasmall
                />
              </div>
            )}
          </div>
          <div
            className="cursor-pointer text-gray-700 flex item-center justify-center gap-1 hover:opacity-75 transition-all duration-75 ease-in"
            onClick={() => setShowCards(!showCards)}
          >
            <div className="flex items-center">
              {cards.length} {t("cards", { count: cards.length })}
            </div>
            {showCards ? (
              <IconUp className="fill-current text-gray-600 w-6 h-6" />
            ) : (
              <IconDown className="fill-current text-gray-600 w-6 h-6" />
            )}
          </div>
        </div>
        {showCards && (
          <div className="pt-4">
            {cards.map((card) => {
              const showCardDescription = !!(card.description && card.description.length > 0);
              return (
              <div
                key={card.id}
                className="w-full flex justify-between items-center rounded-md px-[10px] py-[6px] my-2 bg-gray-100 relative"
              >
                <div
                  className="absolute inset-0 bg-green-200 rounded-md"
                  style={{ width: `${getShare(card.count, totalResponses)}%` }}
                ></div>
                <div className="font-medium" style={{ zIndex: 1 }}>
                  <Tooltip
                    anchorClassName={clsx(showCardDescription ? "cursor-pointer" : "cursor-default")}
                    content={<div className="w-48 text-xs">{card.description}</div>}
                    showOnlyIf={showCardDescription}
                  >
                    <span>{card.value}</span>
                  </Tooltip>
                </div>
                <div className="flex items-center text-sm" style={{ zIndex: 1 }}>
                  <span className="font-medium mr-2 text-gray-800">{getShare(card.count, totalResponses) + "%"}</span>
                  <span className="text-gray-600">({card.count})</span>
                </div>
              </div>
            )}
            )}
          </div>
        )}
      </div>
      <Modal isOpen={isDeleteGroupModalOpen} setIsOpen={setIsDeleteGroupModalOpen} width="400px" height="auto">
        <div className="p-4">
          <div className="flex text-xl mb-4 font-medium">{t("Undo merging")}</div>
          <div className="mt-4">
            {t("You're about to unmerge")} <span className="font-medium">{value}</span>
          </div>
          <div className="flex items-center justify-between">
            <Button
              type="secondary"
              name={t("Cancel")}
              handler={() => {
                setIsDeleteGroupModalOpen(false);
              }}
              className="mt-4"
            />
            <Button
              name={t("Undo merging")}
              handler={async () => {
                setIsDeleteGroupLoading(true);
                await deleteGroup(id);
                setIsDeleteGroupLoading(false);
                setIsDeleteGroupModalOpen(false);
              }}
              className="mt-4"
              isLoading={isDeleteGroupLoading}
            />
          </div>
        </div>
      </Modal>
    </>
  );

}


type ByCategoryProps = {
    testId: string;
    blockId: string;
    categoriesData: {
        [categoryId: string]: {
            [cardId: string]: number;
        };
    };
    cards: {
        id: string;
        value: string;
        description: string | null | undefined;
        image: string | null | undefined;
    }[];
    categories: {
        id: string;
        value: string | undefined;
        isGroup?: boolean;
        isCustom?: boolean;
    }[];
	hiddenCategories: string[];
    totalResponses: number;
    createGroup: (categories: string[], name: string) => Promise<any>;
    bulkCreateGroups: (categoriesAndNames: {categories: string[], name: string}[]) => Promise<any>;
    deleteGroup: (groupId: string) => Promise<any>;
    updateGroupName: (groupId: string, name: string) => Promise<any>;
	hideCategory: (categoryId: string) => Promise<any>;
	unhideCategory: (categoryId: string) => Promise<any>;
    isOpenSort: boolean | undefined;
	sharedByLink?: boolean;
};

const ByCategory: React.FC<ByCategoryProps> = ({ blockId, categoriesData, cards, categories, hiddenCategories, totalResponses, createGroup, bulkCreateGroups, deleteGroup, updateGroupName, hideCategory, unhideCategory, isOpenSort, sharedByLink }) => {

    const { t } = useTranslation();

    const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
    const [selectedCategories, setSelectedCategories] = useState<string[]>([]);

    const [isCreateGroupModalOpen, setIsCreateGroupModalOpen] = useState(false);
    const [groupName, setGroupName] = useState<string | undefined>(undefined);

    const [isCreateGroupLoading, setIsCreateGroupLoading] = useState(false);

	const [showHidden, setShowHidden] = useState(false);

    const showAutoMerge = !sharedByLink && categories.filter(category => category.isCustom === true).length > 1 && isOpenSort && totalResponses > 1;

  return (
    <>
      <div className="flex items-center justify-between">
        <div className="flex items-center justify-center">
          <SearchInput
            id={`${blockId}-searchCategories`}
            onChange={setSearchValue}
            placeholder={t("Search categories")}
            className="w-[240px]"
          />
          <Switcher
            text={hiddenCategories.length > 0 ? `${t("Show hidden")} (${hiddenCategories.length})` : t("Show hidden")}
            handler={() => {
              setShowHidden(!showHidden);
            }}
            isOn={showHidden}
            className="ml-4 text-gray-700 text-xs"
          />
        </div>
        {showAutoMerge && (
          <AutoMerge
            categoriesData={categoriesData}
            cards={cards}
            categories={categories}
            createGroup={createGroup}
            bulkCreateGroups={bulkCreateGroups}
          />
        )}
      </div>
      <div className="mt-6 relative">
        {Object.entries(categoriesData)
          .filter(([categoryId]) => {
            if (categoryId === "unsorted") return false; // Ignore "unsorted" category
            const category = categories.find((category) => category.id === categoryId);
            const categoryName = category?.value;
            // Filter categories based on searchValue
            return !searchValue || (categoryName && categoryName.toLowerCase().includes(searchValue.toLowerCase()));
          })
          .map(([categoryId, categoryCards], index, filteredCategories) => {
            const category = categories.find((category) => category.id === categoryId);
            const categoryName = category?.value;
            const isGroup = category?.isGroup || false;
            const cardsDataArray = Object.entries(categoryCards).map(([cardId, count]) => {
              const card = cards.find((card) => card.id === cardId);
              const cardName = card?.value;
              const cardDescription = card?.description;
              return {
                id: cardId,
                value: cardName,
                description: cardDescription,
                count: count,
              };
            });
            if (hiddenCategories.includes(categoryId) && !showHidden) {
              return null;
            }
            return (
              <RenderIfVisible key={categoryId} defaultHeight={64} stayRendered={true}>
                <div key={categoryId}>
                  <CategoryBlock
                    id={categoryId}
                    value={categoryName || ""}
                    cards={cardsDataArray}
                    totalResponses={totalResponses}
                    selectedCategories={selectedCategories}
                    setSelectedCategories={setSelectedCategories}
                    isGroup={isGroup}
                    updateGroupName={updateGroupName}
                    deleteGroup={deleteGroup}
                    isHidden={hiddenCategories.includes(categoryId)}
                    showHidden={showHidden}
                    hideCategory={hideCategory}
                    unhideCategory={unhideCategory}
                    isOpenSort={isOpenSort}
                    totalCategories={categories.length}
                    sharedByLink={sharedByLink}
                  />
                  {index !== filteredCategories.length - 1 && <hr className="border-t border-gray-200 my-4" />}
                </div>
              </RenderIfVisible>
            );
          })}
        {selectedCategories.length > 1 && (
          <div className="bottom-4 sticky flex items-center justify-center z-10 h-16 rounded-full">
            <div className="mx-auto flex items-center justify-between px-4 w-auto rounded-full shadow-lg p-4 bg-white">
              <div className="text-xs font-medium mr-4">
                {selectedCategories.length} {t("selected")}
              </div>
              <div className="flex items-center justify-center">
                <Button
                  type="primary"
                  name={t("Merge categories")}
                  // icon={<IconGroup className="fill-current text-gray-800 w-6 h-6 mr-1" />}
                  handler={() => {
                    setIsCreateGroupModalOpen(true);
                  }}
                  extrasmall
                />
                <div
                  className="ml-2 cursor-pointer hover:opacity-75"
                  onClick={() => {
                    setSelectedCategories([]);
                  }}
                >
                  <IconClose className="fill-current text-gray-800 w-6 h-6" />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Modal isOpen={isCreateGroupModalOpen} setIsOpen={setIsCreateGroupModalOpen} width="400px" height="auto">
        <div className="p-4">
          <div className="flex text-xl mb-4 font-medium">{t("Merge categories")}</div>
          <Input
            initialValue={undefined}
            className="default-input w-full"
            id={`${blockId}_group_name`}
            placeholder={t("Enter new category name")}
            onChange={(value: string) => {
              setGroupName(value);
            }}
          />
          <div className="text-sm mt-4">
            <span className="text-gray-700">
              {t("You are merging")} {selectedCategories.length} {t("categories", { count: selectedCategories.length })}
              :{" "}
            </span>
            <span className="font-medium">
              {selectedCategories
                .map((categoryId) => categories.find((category) => category.id === categoryId)?.value)
                .join(", ")}
            </span>
          </div>
          <div className="flex items-center justify-between">
            <Button
              type="secondary"
              name={t("Cancel")}
              handler={() => {
                setIsCreateGroupModalOpen(false);
                setSelectedCategories([]);
              }}
              className="mt-4"
            />
            <Button
              name={t("Merge")}
              handler={async () => {
                if (groupName) {
                  setIsCreateGroupLoading(true);
                  await createGroup(selectedCategories, groupName);
                  setIsCreateGroupLoading(false);
                  setGroupName(undefined);
                  setSelectedCategories([]);
                  setIsCreateGroupModalOpen(false);
                }
              }}
              className="mt-4"
              disabled={groupName === (undefined || "")}
              isLoading={isCreateGroupLoading}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ByCategory;