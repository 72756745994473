import { IFigmaResponse } from '../../../models/Response';
import { calcMedian } from '../utils';

export default function useFigmaReportStats(responses: IFigmaResponse[]) {

	const responseTimes: number[] = [];
	let completionTime = 0;
	let gaveUpTotal = 0;

	for (let index = 0; index < responses.length; index++) {
		const response = responses[index];
		completionTime += response.time;
		responseTimes.push(response.time);

		if (response.givenUp) {
			gaveUpTotal++;
		}
	}

	// calculate average response time
	const averageCompletionTime = (completionTime / responses.length / 1000).toFixed(2);

	// calculate median response time
	const medianCompletionTime = (calcMedian(responseTimes) / 1000).toFixed(2);

	// const gaveUpTotal = responses.reduce((sum, response) => (response.givenUp === true ? sum + 1 : sum), 0);
	const succeedTotal = responses.length - gaveUpTotal;

	return {
		averageCompletionTime,
		medianCompletionTime,
		gaveUpTotal,
		succeedTotal,
	};
}