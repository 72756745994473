import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { auth } from "../firebase";

import Loader from "./Loader";
import PageNotFound from "./PageNotFound";

const AuthByToken = () => {
  const query = new URLSearchParams(useLocation().search);
  const customToken = query.get("customToken");
  const userId = query.get("userId");

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      if (customToken) {
        try {
          await auth.signInWithCustomToken(customToken);
          window.location.replace("/");
        } catch (error) {
          console.error(error);
          setIsLoading(false);
        }
      } else if (userId) {
        const response = await fetch(`/api/admin/customToken?userId=${userId}`, {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        });
  
        if (response.ok) {
          const { customToken } = await response.json();
          await auth.signInWithCustomToken(customToken);
          window.location.replace("/");
        } else {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    })();
  }, [customToken]);

  return isLoading ? <Loader /> : <PageNotFound />;
};

export default AuthByToken;