import React, { useState } from "react";
import "../../tailwind.generated.css";

import { Img } from "react-image";
import Loader from "../Loader";
import Modal from "../Modal";

import { ReactComponent as IconZoom } from "../../icons/zoom.svg";

const PreferenceReport = ({ replies, responses, questionText }) => {
  const [isZoomModalOpen, setIsZoomModalOpen] = useState(false);
  const [zoomedImgUrl, setZoomedImgUrl] = useState(null);

  const findReplyDataById = (id) => {
    const reply = replies.find((element) => element.id === id);
    return { replyValue: reply.replyValue, image: reply.image };
  };

  const data = {};
  const total = {};

  total.totalSelectedOptions = 0;
  replies.forEach((reply) => {
    data[reply.id] = 0;
  });
  responses.forEach((response) => {
    response.selectedOptions.forEach((option) => {
      if (!isNaN(data[option.id])) {
        data[option.id]++;
        total.totalSelectedOptions++;
      } else {
        return;
      }
    });
  });

  return (
    <>
      {Object.keys(data).map((key) => (
        <div className="my-6" key={`preference-icon-${key}`}>
          <div className="flex justify-between w-full items-center mt-2">
            <div className="flex items-center justify-center">
              <div className="preferenceOption relative">
                <div
                  className="preferenceZoomIcon absolute right-1 top-1 bg-gray-600 rounded-full p-1 hover:bg-black cursor-pointer"
                  onClick={() => {
                    setZoomedImgUrl(findReplyDataById(key).image);
                    setIsZoomModalOpen(true);
                  }}
                >
                  <IconZoom
                    width={16}
                    height={16}
                    className="fill-current text-white"
                  />
                </div>
                <Img
                  className="rounded-md w-12 h-12 object-contain bg-gray-200"
                  src={findReplyDataById(key).image}
                  alt=""
                  loader={
                    <div className="bg-gray-200 rounded-lg w-12 h-12">
                      <Loader />
                    </div>
                  }
                />
              </div>
            </div>
            <div className="flex-1 pl-6 mt-1">
              <div className="h-1 bg-gray-300 rounded-full w-full relative">
                <div
                  className="bg-purple-600 h-1 rounded-full"
                  style={{
                    width: `${(data[key] / total.totalSelectedOptions) * 100}%`,
                  }}
                ></div>
              </div>
              <div className="flex justify-between items-center mt-2">
                <div className="font-medium">
                  {findReplyDataById(key).replyValue}
                </div>
                <div className="font-medium">
                  {Math.floor((data[key] / total.totalSelectedOptions) * 100) +
                    "%"}
                  <span className="font-normal text-gray-600 text-xs">
                    &nbsp;({data[key]})
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
      {isZoomModalOpen && (
        <Modal isOpen={isZoomModalOpen} setIsOpen={setIsZoomModalOpen}>
          <div className="p-6 h-auto min-h-full flex items-center justify-center">
            <img src={zoomedImgUrl} alt="" />
          </div>
          <div
            className="absolute text-white top-4 right-4 p-1 rounded-full bg-black"
            onClick={() => {
              setIsZoomModalOpen(false);
            }}
          ></div>
        </Modal>
      )}
    </>
  );
};

export default PreferenceReport;
