import React from "react";
import { fabric } from "fabric";
import lodash from "lodash";
import { IArea, IClickmap } from "./STORAGE";

export interface IClickmapProps {
	// areas: IArea[];
	clickmap: IClickmap;
	wrapperRef?: React.RefObject<HTMLElement>;
	onAreaAdded: (areas: IArea[]) => void;
	imageUrl?: string;
	hideAreas?: boolean;
	figmaPrototype?: React.ReactNode;

	children: (withScale: ScaleFunction, getScaleRate: GetScaleRateFunction) => React.ReactElement;
}

export type ScaleFunction = (x: number) => number;
export type GetScaleRateFunction = (width: number) => number;

export interface ICanvasProps {
	isDown: boolean;
	rect?: fabric.Rect;
	originX?: number;
	originY?: number;
}

const initialCanvasProps: ICanvasProps = {
	isDown: false,
	rect: undefined,
	originX: undefined,
	originY: undefined,
};

class Clickmap extends React.Component<IClickmapProps> {
	wrapperRef: React.RefObject<HTMLDivElement>;
	innerRef: React.RefObject<HTMLDivElement>;
	fabricRef: React.RefObject<HTMLCanvasElement>;
	canvas: any;

	withScaleMemo: Record<string, number> = {};

	constructor(props: IClickmapProps) {
		super(props);
		this.wrapperRef = React.createRef();
		this.innerRef = React.createRef();
		this.fabricRef = React.createRef();
		this.state = { scaleRate: 1 };
	}

	getScaleRate = (width: number) => {
		if (this.wrapperRef.current) {
			const wrapperOffsetWidth = this.wrapperRef.current.offsetWidth;
			return width > wrapperOffsetWidth ? wrapperOffsetWidth / width : 1;
		}
		return 1;
	};

	withScale = (value: number) => {
		const key = `${value}${this.props.clickmap.width}`;
		if (this.withScaleMemo[key]) {
			return this.withScaleMemo[key];
		}

		const scaleRate = this.getScaleRate(this.props.clickmap.width);
		const result = Math.round(value * (value / (value * scaleRate)));
		this.withScaleMemo[key] = result;
		return result;
	};

	initializeAreas = () => {
		const { width, height } = this.props.clickmap;

		const canvasProps = { ...initialCanvasProps } as Required<ICanvasProps>;
		const canvas = new fabric.Canvas(this.fabricRef.current, {
			width: width,
			height: height,
			defaultCursor: "crosshair",
		});
		// canvas.setWidth(width);
		// canvas.setHeight(height);

		canvas.on("mouse:down", ({ e }) => {
			canvasProps.isDown = true;
			const { x, y } = canvas.getPointer(e);

			canvasProps.originX = x;
			canvasProps.originY = y;

			canvasProps.rect = new fabric.Rect({
				selectable: false,
				hoverCursor: "default",
				fill: "rgba(0,0,0,0)",
				rx: 4,
				ry: 4,
				stroke: "#2565D9",
				strokeWidth: this.withScale(4),
				originX: "left",
				originY: "top",
				left: x,
				top: y,
			});

			canvas.add(canvasProps.rect);
		});

		canvas.on("mouse:move", ({ e }) => {
			if (!canvasProps.isDown) return;
			const { x, y } = canvas.getPointer(e);

			const props: any = {
				width: Math.abs(canvasProps.originX - x),
				height: Math.abs(canvasProps.originY - y),
				// left: undefined as number | undefined,
				// top: undefined as number | undefined
			};

			if (canvasProps.originX > x) props.left = Math.abs(x);
			if (canvasProps.originY > y) props.top = Math.abs(y);

			canvasProps.rect.set(props);
			canvas.renderAll();
		});

		canvas.on("mouse:up", () => {
			// save area
			const { width, height } = canvasProps.rect as Required<fabric.Rect>;
			if (width > 0 && height > 0) this.addArea(canvasProps.rect);
			else canvas.remove(canvasProps.rect);

			Object.assign(canvasProps, initialCanvasProps);
			// set initial params
			// Object.entries(initialCanvasProps).forEach(([key, value]) => {
			//   canvasProps[key] = value;
			// });
		});

		this.canvas = canvas;
	};

	componentDidMount() {
		window.addEventListener("resize", this.handleResize);
		this.initializeAreas();
		this.printAreas();
		this.handleResize();
	}

	componentDidUpdate(prevProps: IClickmapProps) {
		if (
			prevProps.clickmap.width !== this.props.clickmap.width ||
			prevProps.clickmap.height !== this.props.clickmap.height
		) {
			this.handleResize();
			this.initializeAreas();
		}

		if (!lodash.isEqual(prevProps.clickmap.areas, this.props.clickmap.areas)) {
			this.printAreas();
		}
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.handleResize);
		this.canvas.__eventListeners = {};
	}

	handleResize = () => {
		const scaleRate = this.getScaleRate(this.props.clickmap.width);

		if (this.innerRef.current) {
			this.innerRef.current.style.transformOrigin = "0 0";
			this.innerRef.current.style.transform = `scale(${scaleRate})`;
			this.innerRef.current.style.height = "auto";
			this.innerRef.current.style.left = "50%";
			this.innerRef.current.style.marginLeft = `-${(this.props.clickmap.width * scaleRate) / 2}px`;
		}
		if (this.wrapperRef.current) {
			this.wrapperRef.current.style.height = `${this.props.clickmap.height * scaleRate}px`;
		}

		this.setState({ scaleRate });
	};

	addArea = async (rect: fabric.Rect) => {
		const { top, left, width, height } = rect as Required<fabric.Rect>;

		this.props.onAreaAdded([
			...this.props.clickmap.areas,
			{
				top,
				left,
				width,
				height,
				index: this.props.clickmap.areas.length + 1,
				clicks: [],
			},
		]);
	};

	printAreas = () => {
		const { areas } = this.props.clickmap;

		this.canvas.clear();

		areas.forEach(({ top, left, width, height, index }) => {
			const rect = new fabric.Rect({
				selectable: false,
				hoverCursor: "default",
				index: index * 10,
				fill: "rgba(0,0,0,0)",
				rx: 4,
				ry: 4,
				stroke: "#2565D9",
				strokeWidth: this.withScale(4),
				originX: "left",
				originY: "top",
				height,
				width,
				left,
				top,
				hasControls: false,
			} as any);

			const number = new fabric.Text(String(index), {
				selectable: false,
				hoverCursor: "default",
				fontFamily: "Favorit",
				fontWeight: "500",
				fontSize: this.withScale(16),
				lineHeight: this.withScale(24),
				textAlign: "center",
				originX: "center",
				originY: "center",
				fill: "#fff",
			});

			const background = new fabric.Rect({
				selectable: false,
				hoverCursor: "default",
				originX: "center",
				originY: "center",
				width: this.withScale(32),
				height: this.withScale(32),
				fill: "#2565D9",
				rx: this.withScale(16),
				ry: this.withScale(16),
				hasControls: false,
			});

			const numberGroup = new fabric.Group([background, number], {
				index: index * 10 + 1,
				left: left - this.withScale(16),
				top: top - this.withScale(16),
				hoverCursor: "default",
				selectable: false,
				hasControls: false,
			} as any);

			this.canvas.add(rect);
			this.canvas.add(numberGroup);
		});

		this.canvas.renderAll();
	};

	render() {
		return (
			<div ref={this.wrapperRef} className="clickmap h-auto overflow-x-hidden">
				<style>
					{`.figma-reports-view {
              counter-reset: scrollblocks;
              counter-increment: scrollblocks;
            }
          
            .figma-reports-view [data-has-scroll]::before {
              counter-increment: scrollblocks;
              content: "№ " counter(scrollblocks);
              font-size: ${this.withScale(16 * 2)}px;
              background: #000c;
              z-index: 50;
              color: #fff;
              display: block;
              position: absolute;
              left: 0;
              bottom: 0;
              top: 0;
              right: 0;
            }`}
				</style>
				<div
					ref={this.innerRef}
					className="clickmap__heatmap-container relative"
					style={{
						width: this.props.clickmap.width,
						height: this.props.clickmap.height,
					}}
				>
					{this.props.figmaPrototype && (
						<div className="prototype-screen-wrapper absolute top-0">{this.props.figmaPrototype}</div>
					)}
					{!this.props.figmaPrototype && !!this.props.imageUrl && (
						<img className="absolute top-0" src={this.props.imageUrl} />
					)}
					{!!this.props.children && (
						<div className="prototype-clicks absolute top-0">
							{this.props.children(this.withScale, this.getScaleRate)}
						</div>
					)}
					<div
						className="prototype-heatmap absolute top-0"
						style={{ display: this.props.hideAreas ? "none" : "block" }}
					>
						<canvas ref={this.fabricRef} width={this.props.clickmap.width} height={this.props.clickmap.height} />
					</div>
				</div>
			</div>
		);
	}
}

export default Clickmap;
