import React, { useState, useEffect } from "react";
import clsx from "clsx";
import _ from "lodash";
import "../../../../tailwind.generated.css";


import useIsMobile from "./hooks/useIsMobile";

type SidebarState = 'hidden' | 'default' | 'overlay';

type TaskSidebarProps = {
  sidebarContent?: React.ReactNode;
  content?: React.ReactNode;
  parentRef: React.RefObject<HTMLDivElement | null>;
  sidebarState: SidebarState;
  setSidebarState: (state: SidebarState) => void;
};

function TaskSidebar(props: TaskSidebarProps) {


  const [parentHeight, setParentHeight] = useState<null | number>(null);

  useEffect(() => {
    const updateParentHeight = () => {
      if (props.parentRef && props.parentRef.current) {
        setParentHeight(props.parentRef.current.offsetHeight);
      }
    };

    updateParentHeight();

    window.addEventListener('resize', updateParentHeight);

    return () => window.removeEventListener('resize', updateParentHeight);
  }, [props.parentRef, props.sidebarState]);

  const isMobile = useIsMobile();

  const sidebarStateClasses = {
    hidden: "hidden",
    default: clsx(!isMobile ? "w-[256px]" : "w-full"),
    overlay: clsx(!isMobile ? "w-[480px]" : "w-full absolute")
  };


  return (
    <div className={clsx("task-sidebar__wrapper w-full h-full max-h-full relative", isMobile ? "flex flex-col" : "flex flex-row")}>
      <div
        className={clsx(
          "task-sidebar__sidebar",
          "h-full max-h-full bg-white shadow-xs z-999 overscroll-y-none flex-shrink-0 p-6 flex-col overflow-y-auto",
          sidebarStateClasses[props.sidebarState]
        )}
      >
        {props.sidebarContent}
      </div>
      <div
        className={clsx(
          "task-sidebar__overlay",
          "w-full h-full absolute z-[990] bg-gray-800 transition-opacity duration-75 ease-in",
          props.sidebarState === "overlay" ? "backdrop-blur-xl bg-opacity-25" : "bg-opacity-0 pointer-events-none invisible"
        )}
        style={parentHeight ? { height: `${parentHeight}px` } : undefined}
      ></div>
      {props.content}
    </div>
  );
}

export default TaskSidebar;