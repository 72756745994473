import React, { useState, useEffect, useRef } from "react";
import "../../../../../tailwind.generated.css";
import clsx from "clsx";

const Input = ({ placeholder, initialValue, handler, id, className, designConfig, singleLine }) => {
  const [value, setValue] = useState(initialValue || "");

  const textareaRef = useRef(null);

  const handleChange = (e) => {
    if (handler) {
      handler("textValue", e.target.value);
    }
    setValue(e.target.value);
  };

  useEffect(() => {
    textareaRef.current.focus();
  }, []);

  useEffect(() => {
    if (value !== "") {
      setValue("");
    }
  }, [id]);

  useEffect(() => {
    if (singleLine) {
      const PreventTextAreaEnter = (e) => {
        if (e.keyCode === 13) {
          e.preventDefault();
        }
      };
      textareaRef.current.addEventListener("keydown", PreventTextAreaEnter);
      return () => {
        if (textareaRef.current) {
          textareaRef.current.removeEventListener("keydown", PreventTextAreaEnter);
        }
      };
    }
  }, []);

  return (
    <textarea
      ref={textareaRef}
      rows={singleLine ? 1 : 3}
      draggable="false"
      className={clsx(className, "border-2 hover:border-blue-600 focus:border-blue-600 transition-all duration-75 ease-in border-gray-300")}
      style={{
        minWidth: "200px",
      }}
      id={id}
      value={value}
      placeholder={placeholder}
      onChange={(e) => {
        handleChange(e);
      }}
    />
  );
};

export default Input;
