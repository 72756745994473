import { Block, BlockType, blockHasText } from "../../models/Test";
import { blockTypeMap } from "../Common";

import i18n from "../../i18n/config";

export function getBlockName(block: Block) {
  if ("instruction" in block && block.instruction.length > 0) {
    return block.instruction;
  } else if ("text" in block && block.text.length > 0) {
    if (block.text.length > 40) {
      return block.text.slice(0, 40) + "...";
    } else {
      return block.text;
    }
  } else {
    return i18n.t(blockTypeMap[block.type].name);
  }
}
