import React from "react";
import Switcher from "../../Switcher";
import { ScreenStats } from "./ScreenStats";
import { IArea } from "../Clickmaps/STORAGE";
import { IconClickarea } from "../../../icons";
import { useTranslation } from "react-i18next";
import FigmaAreas from './FigmaAreas';
import { useClickAreaStats } from './useClickAreaStats';


export function FigmaReportSidebar(props: {
	screenStats: any[];
	showFirstClickControls: boolean;
	showClicksOrderControls?: boolean;
	showClickAreas: boolean;
	onAreaAdded: (areas: IArea[]) => void;
	setShowOnlyFirstClicks: (val: boolean) => void;
	showOnlyFirstClicks: boolean;
	setShowClicksOrder: (val: boolean) => void;
	showClicksOrder: boolean;

	showClickAreaMode?: boolean;
	clickAreaModeOn?: boolean;
	setClickAreaModeOn?: (val: boolean) => void;
	clickAreaStats?: {
		areasWithWithTargetClicks: any[];
		testersCount: number;
	}
}) {

	const { t } = useTranslation();
	const isSettingsAvailable = props.showFirstClickControls || props.showClicksOrderControls;
	return (
		<>
			<ScreenStats stats={props.screenStats} />
			{isSettingsAvailable && (
				<div className="font-medium mt-6">{t("Settings")}</div>
			)}
			{props.showFirstClickControls && (
				<div className="mt-6 text-sm font-medium">
					<Switcher
						text={t("Show only first clicks")}
						handler={props.setShowOnlyFirstClicks}
						isOn={props.showOnlyFirstClicks}
						large />
				</div>
			)}
			{props.showClicksOrderControls && (
				<div className="mt-6 text-sm font-medium">
					<Switcher text={t("Show clicks order")} handler={props.setShowClicksOrder} isOn={props.showClicksOrder} large />
				</div>
			)}
			{props.showClickAreaMode && (
				<>
					<div className="font-medium mt-6">{t("Analyze")}</div>
					<div className="mt-6 text-sm font-medium">
						<Switcher
							text={<span className="flex items-center">
								<IconClickarea className="mr-2 fill-current text-white" />
								{t("Draw click areas")}
							</span>}
							handler={props.setClickAreaModeOn as any}
							isOn={props.clickAreaModeOn as any}
							large />
					</div>
					{props.clickAreaModeOn && (
						<div className="text-sm text-white mt-6">
							{t("To analyze a specific area, draw a rectangle on the prototype.")}{" "}
							{t("Click on area to remove.")}
						</div>
					)}
				</>
			)}
			<div className="sidebar__clickareas pt-3">
				{props.showClickAreas && !!props.clickAreaStats && (
					<FigmaAreas areas={props.clickAreaStats.areasWithWithTargetClicks} testersCount={props.clickAreaStats.testersCount} onAreaAdded={props.onAreaAdded} />
				)}
			</div>
		</>
	);
}
