import React from "react";
import { ReactComponent as IconDelete } from "../../../icons/delete.svg";
import { isWithin } from "./Utils";
import { IFirstClickResponse } from "../../../models/Response";
import { IArea } from "./STORAGE";
import { calcMedian, calcMean, msToSeconds } from "../utils";

import { IconHire, IconTime, IconClickarea } from "../../../icons";

import Tooltip from "../../Tooltip";
import { useTranslation } from "react-i18next";

interface IAreaLegendProps {
	area: IArea;
	index: number;
	removeArea: (index: number) => void;
}

const AreaLegend = ({ area, index, removeArea }: IAreaLegendProps) => {
	const { t } = useTranslation();
	const median = area.median && msToSeconds(area.median);
	const mean = area.mean && msToSeconds(area.mean);

	return (
		<div
			className="flex items-center text-sm justify-between mt-3 group text-white border-1 border-gray-400 border-opacity-[0.15] rounded-lg px-3 py-2 w-full"
			key={index}
		>
			<div className="flex items-center">
				<div className="flex">
					<div className="mr-2 font-medium">{index + 1}. </div>
					<div className="font-medium inline">{area.clicksCount}&nbsp;</div>
					<div className="font-medium inline">({area.share}%)</div>
				</div>
				{mean && (
					<Tooltip
						content={
							<div className="w-96">
								<div className="flex items-center justify-start px-2 py-1">
									<IconTime className="fill-current opacity-60 mr-1" width={16} height={16} />
									<div className="text-xs text-gray-400">{t("Average&colon;")} {mean} {t("s")}</div>
								</div>
								<div className="flex items-center justify-start px-2 py-1">
									<IconTime className="fill-current opacity-60 mr-1" width={16} height={16} />
									<div className="text-xs text-gray-400">{t("Median&colon;")} {median} {t("s")}</div>
								</div>
							</div>
						}
					>
						<div className="flex items-center">
							<IconTime className="ml-2 mr-1 fill-current opacity-60" width={16} height={16} />
							<div className="mr-1 font-medium opacity-60">{mean} {t("s")}</div>
						</div>
					</Tooltip>
				)}
			</div>
			<div className="group-hover:visible invisible">
				<IconDelete
					width={20}
					height={20}
					className={`transition duration-75 ease-in fill-current text-gray-400 cursor-pointer hover:text-white`}
					onClick={() => removeArea(index + 1)}
				/>
			</div>
		</div>
	);
};

export interface IAreasProps {
	width: number;
	height: number;
	areas: IArea[];
	clicks: IFirstClickResponse[];
	onDeleteArea: (areas: IArea[]) => void;
}

export default function Areas(props: IAreasProps) {
	const { t } = useTranslation();
	const { width: imageWidth, height: imageHeight } = props;
	const areasWithClicksCount = props.areas.map((a) => ({ ...a }));

	props.clicks.forEach((click) => {
		const clickX = click.clickData.left * imageWidth;
		const clickY = click.clickData.top * imageHeight;

		areasWithClicksCount.forEach((area) => {
			if (!area.clicksCount) {
				area.clicksCount = 0;
			}

			const areaMinX = area.left;
			const areaMaxX = area.left + area.width;
			const areaMinY = area.top;
			const areaMaxY = area.top + area.height;

			if (isWithin(clickX, areaMinX, areaMaxX) && isWithin(clickY, areaMinY, areaMaxY)) {
				area.clicksCount += 1;
				if (!area.clicks) {
					area.clicks = [];
				}
				area.clicks.push(click);
			}
		});
	});

	areasWithClicksCount.forEach((area) => {
		area.share =
			(area.clicksCount as number) > 0 ? Math.round(((area.clicksCount as number) / props.clicks.length) * 100) : 0;
		if (area.clicks && area.clicks.length > 0) {
			area.median = calcMedian(area.clicks.map((c) => c.clickData.responseTime));
			area.mean = calcMean(area.clicks.map((c) => c.clickData.responseTime));
		}
	});

	const removeArea = (index: number) => {
		const newAreas = props.areas.filter((a) => a.index !== index).map((a, i) => ({ ...a, index: i + 1 }));
		props.onDeleteArea(newAreas);
	};

	return (
		<div className="mt-4">
			<div className="text-gray-500 font-medium flex items-center justify-start">
				<IconClickarea className="fill-current mr-2" width={24} height={24} />
				<span>{t("Click Areas")}</span>
			</div>

			{areasWithClicksCount.length === 0 && (
				<div className="text-gray-600 mt-2 text-sm">{t("Draw a rectangle on the image to analyze an area")}</div>
			)}

			{areasWithClicksCount.map((area, i) => (
				<AreaLegend key={i} area={area} index={i} removeArea={removeArea} />
			))}
		</div>
	);
}
