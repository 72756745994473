import React, { useState, useEffect, useRef } from "react";
import "../../../../../tailwind.generated.css";
import clsx from "clsx";

import _ from "lodash";
import useIsMobile from "../hooks/useIsMobile";


function PreferenceOption(props: any) {
	const { id, replyValue, isCentered, isDragging, density, onReady } = props;
	const withCaption = replyValue && replyValue.length > 0;
	const preferenceOptionContainerRef = useRef<HTMLDivElement | null>(null);

	function onImageLoad(imageElement: HTMLImageElement) {
		  const naturalWidth = imageElement.naturalWidth;
		  const preferenceOptionContainer = preferenceOptionContainerRef?.current;
		  if (!preferenceOptionContainer) return;
		  if (density === "2x") {
		  preferenceOptionContainer.style.maxWidth = `${naturalWidth / 2}px`;
		} else {
			preferenceOptionContainer.style.maxWidth = `${naturalWidth}px`;
		}
		onReady(id);
	  }
  
	return (
	  <div
	  	ref={preferenceOptionContainerRef}
		id={"preferenceOptionContainer_"+id}
		className={clsx(
			"flex items-center justify-center",
			isCentered ? "h-full" : "h-auto")}
		key={id}
	  >
		<div
		  className={clsx(
			"p-2 lg:p-4 rounded-lg border-1 transition-all duration-75 ease-in h-auto relative",
			props.isSelected && "border-blue-600 inset-primary-shadow bg-blue-100",
			!props.isSelected && "border-gray-300",
			isDragging ? "cursor-grabbing" : "cursor-pointer",
		  )}
		  onClick={(e) => {
			e.stopPropagation();
			props.onClick?.(id, replyValue);
		  }}
		>
		  <img
			src={props.image}
			alt=""
			className={clsx(
				"h-auto object-contain"
				)}
			onLoad={(e) => onImageLoad(e.currentTarget)}
		  />
		  {withCaption && <div className={clsx("flex items-start mt-4 text-gray-700 text-sm")}>{replyValue}</div>}
		</div>
	  </div>
	);
  }

export interface PreferenceSelectProps {
	data: { id: string, replyValue: string, image: string }[];
	handler: (key: string, value: any) => void;
	isRandomized: boolean;
	responseStart: boolean;
}

function PreferenceSelect(props: PreferenceSelectProps) {
	const { data, handler: updateAnswerBlock, isRandomized } = props;
	const [options, setOptions] = useState<{ id: string, replyValue: string, image: string, density?: "1x" | "2x" }[] | null>(null);
	const [selectedOptionId, setSelectedOptionId] = useState<string | null>(null);
	const [isCentered, setIsCentered] = useState<Boolean>(true);
	const [showImages, setShowImages] = useState<Boolean>(false);
	const [imagesLoadingState, setImagesLoadingState] = useState<{[key:string]: boolean}>({});


	const isMobile = useIsMobile();

	const preferenceContainerRef = useRef<HTMLDivElement | null>(null);

	function onPreferenceImageReady (id: string) {
		setImagesLoadingState((current) => {
			return {
			...current,
			[id]: true
			}
		});
	}

	useEffect(() => {
    // Check if all images are loaded
    const allImagesLoaded = options && options.every((option) => imagesLoadingState[option.id]);

    if (allImagesLoaded) {
      if (isMobile) {
		setIsCentered(false);
        setShowImages(true);
        return;
      }
      // Check for overflow
      const isOverflowing = options?.some((option) => {
        const container = document.getElementById(`preferenceOptionContainer_${option.id}`);
        return container && container.scrollHeight > container.clientHeight;
      });

      if (isOverflowing) {
        setIsCentered(false);
      }
      setShowImages(true);
    }
  }, [imagesLoadingState, options, isMobile]);


	useEffect(() => {
		setOptions(isRandomized ? _.shuffle(data) : data);
		setSelectedOptionId(null);
	}, [data]);

	useEffect(() => {
		if (!selectedOptionId) return;
		// update selected option in answer block
		const replyValue = options?.find((option) => option.id === selectedOptionId)?.replyValue;
		updateAnswerBlock("selectedOptions", [{ id: selectedOptionId, replyValue }]);
	}, [selectedOptionId]);

	function onOptionSelected(id: string) {
		setSelectedOptionId(id);
	}

	// here is the logic for dragging the preference options

	const [isDragging, setIsDragging] = useState(false);
	const [startX, setStartX] = useState(0);
	const [scrollLeft, setScrollLeft] = useState(0);
  
	const onDragStart = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
	  setIsDragging(true);
	  setStartX(e.pageX - preferenceContainerRef.current!.offsetLeft);
	  setScrollLeft(preferenceContainerRef.current!.scrollLeft);
	};
  
	const onDragEnd = () => {
	  setIsDragging(false);
	};
  
	const onDragMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
	  if (!isDragging) return;
	  e.preventDefault();
	  const x = e.pageX - preferenceContainerRef.current!.offsetLeft;
	  const walk = (x - startX) * 1.5; // the number 2 will determine the sensitivity of the scroll
	  preferenceContainerRef.current!.scrollLeft = scrollLeft - walk;
	};


	return (
    <>
      <div className={clsx("h-full w-full max-h-full overflow-y-auto flex items-center justify-center")}>
        <div
          ref={preferenceContainerRef}
          className={clsx("h-full max-w-full overflow-x-auto overflow-y-hidden flex px-4", !showImages && "invisible")}
          style={{ cursor: isDragging ? "grabbing" : "grab" }}
          onMouseDown={onDragStart}
          onMouseLeave={onDragEnd}
          onMouseUp={onDragEnd}
          onMouseMove={onDragMove}
        >
          {options?.map((item) => (
            <div className="flex-shrink-0 max-w-[90%] px-2 py-4 md:px-4 md:py-6 overflow-y-auto">
              <PreferenceOption
                key={item.id}
                id={item.id}
                replyValue={item.replyValue}
                image={item.image}
                density={item.density}
                onClick={onOptionSelected}
                onReady={onPreferenceImageReady}
                isSelected={selectedOptionId === item.id}
                isCentered={isCentered}
				isDragging={isDragging}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default PreferenceSelect;

