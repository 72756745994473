import { useRef, useState, useEffect, RefObject } from 'react';
import { isWithin } from '../Clickmaps/Utils';

export interface IArea {
	id: number;
	clicksCount: number;
	missedClicksCount: number;
	respondentsCount: number;
	respondentsPercent: number;

	left: number;
	top: number;
	width: number;
	height: number;
	absoluteLeft: number;
	absoluteTop: number;
}

export default function useClickareas(scaleRatio: number, clickmapRef: RefObject<HTMLDivElement>, screenId: string, showClickAreas: boolean, showOnlyFirstClicks: boolean, respondentsTotal: number) {
	const reverseScale = 1 / scaleRatio;

	const clickAreaRef = useRef<HTMLDivElement>(null);
	const drawingAreaRef = useRef<any>({ drawingDiv: null, left: 0, top: 0 });
	const [areas, setAreas] = useState<IArea[]>([]);

	useEffect(() => {
		setTimeout(() => {
			setAreas(current => calcClickareasStats([...current]));
		}, 50);
	}, [showOnlyFirstClicks]);


	// clean areas when screenId changes
	useEffect(() => {
		setAreas([]);
	}, [screenId, showClickAreas]);

	useEffect(() => {
		// attach pointer down, pointer up, pointer move events to clickAreaRef
		if (!clickAreaRef.current) return;
		/** Вся область для рисования клик-ареа */
		const drawingArea = clickAreaRef.current;
		drawingArea.addEventListener('pointerdown', onPointerDown);
		drawingArea.addEventListener('pointermove', onPointerMove);
		drawingArea.addEventListener('pointerup', onPointerUp);

		function onPointerDown(e: PointerEvent) {
			// ignore right click
			if (e.button !== 0) return;
			if (drawingAreaRef.current.drawingDiv) return;
			const boundingRect = drawingArea.getBoundingClientRect();
			// console.log('onPointerDown', e, boundingRect);
			const drawingDiv = createDrawingDiv();
			const left = e.clientX - boundingRect.x;
			const top = e.clientY - boundingRect.y;

			drawingDiv.style.left = s(left) + 'px';
			drawingDiv.style.top = s(top) + 'px';
			drawingAreaRef.current.drawingDiv = drawingArea.appendChild(drawingDiv);
			drawingAreaRef.current.left = left;
			drawingAreaRef.current.top = top;
		}

		function onPointerMove(e: PointerEvent) {
			const drawingDiv = drawingAreaRef.current.drawingDiv;
			if (!drawingDiv) return;
			// console.log('onPointerMove', e);
			const boundingRect = drawingArea.getBoundingClientRect();
			const width = e.clientX - boundingRect.x - drawingAreaRef.current.left;
			const height = e.clientY - boundingRect.y - drawingAreaRef.current.top;
			if (width < 0) {
				drawingDiv.style.left = s(e.clientX - boundingRect.x) + 'px';
				// drawingAreaRef.current.left = e.clientX - boundingRect.x;
			}
			if (height < 0) {
				drawingDiv.style.top = s(e.clientY - boundingRect.y) + 'px';
				// drawingAreaRef.current.top = e.clientY - boundingRect.y;
			}
			drawingDiv.style.width = s(Math.abs(width)) + 'px';
			drawingDiv.style.height = s(Math.abs(height)) + 'px';
		}

		function onPointerUp(e: PointerEvent) {
			// console.log('onPointerUp', e,);

			const drawingDiv = drawingAreaRef.current.drawingDiv;
			if (!drawingDiv) return;

			const drawingAreaRect = drawingArea.getBoundingClientRect();

			const xInsideDrawingArea = e.clientX - drawingAreaRect.x;
			const yInsideDrawingArea = e.clientY - drawingAreaRect.y;
			// current.left and current.top are relative to drawingArea and it is a starting point of drawingDiv
			const width = s(xInsideDrawingArea - drawingAreaRef.current.left);
			const height = s(yInsideDrawingArea - drawingAreaRef.current.top);

			// if width or height is too small, remove drawingDiv
			if (Math.abs(width) < 10 || Math.abs(height) < 10) {
				drawingAreaRef.current.drawingDiv = null;
				drawingDiv.remove();
				return console.log('width or height is too small');
			}

			if (width < 0) {
				drawingDiv.style.left = s(xInsideDrawingArea) + 'px';
			}
			if (height < 0) {
				drawingDiv.style.top = s(yInsideDrawingArea) + 'px';
			}

			drawingDiv.style.width = width + 'px';
			drawingDiv.style.height = height + 'px';

			const newArea: IArea = {
				id: Date.now(),
				// если width или height отрицательные, значит клик-ареа рисовалось справа или снизу влево или вверх (то есть наоборот)
				// тогда начальные координаты заменяем на координаты, когда мышку отпустили
				left: s(width < 0 ? xInsideDrawingArea : drawingAreaRef.current.left),
				top: s(height < 0 ? yInsideDrawingArea : drawingAreaRef.current.top),
				width: Math.abs(width),
				height: Math.abs(height),
				absoluteLeft: s((width < 0 ? xInsideDrawingArea : drawingAreaRef.current.left) + drawingAreaRect.x),
				absoluteTop: s((height < 0 ? yInsideDrawingArea : drawingAreaRef.current.top) + drawingAreaRect.y),

				clicksCount: 0,
				missedClicksCount: 0,
				respondentsCount: 0,
				respondentsPercent: 0,
			};
			setAreas((current) => calcClickareasStats([...current, newArea]));
			drawingDiv.remove();
			drawingAreaRef.current.drawingDiv = null;
			console.log('newArea', newArea);
		}

		return () => {
			drawingArea.removeEventListener('pointerdown', onPointerDown);
			drawingArea.removeEventListener('pointermove', onPointerMove);
			drawingArea.removeEventListener('pointerup', onPointerUp);
		}
	}, [clickAreaRef.current]);

	function calcClickareasStats(areas: IArea[]) {
		// когда меняются areas, нужно пересчитать clicksCount и missedClicksCount
		const newAreas = [...areas];
		newAreas.forEach((area) => {
			area.clicksCount = 0;
			area.missedClicksCount = 0;
			area.respondentsCount = 0;
			const responseIdSet = new Set();

			// берутся все слои (которые скроллятся)
			const allScreenLayers = clickmapRef.current?.querySelectorAll('.screen-layer');
			allScreenLayers?.forEach((layer) => {
				// find all parents of layer with class screen-layer until its parent with class root
				const parents = getParentLayers(layer);
				const targetRect = layer.getBoundingClientRect();
				const intersection = {
					top: targetRect.top,
					left: targetRect.left,
					bottom: targetRect.bottom,
					right: targetRect.right,
				};

				// Берем всех родителей текущего слоя (они все скроллящиеся) и вычисляем минимальную видимую область для текущего слоя
				// Если слой не виден в области прокрутки родителей, то он не учитывается
				calculateAreaIntersection(parents, intersection);

				// Если область получается с отрицательной высотой или шириной, значит она не видна и для этого слоя клики не смотрим
				if (intersection.top >= intersection.bottom || intersection.left >= intersection.right) {
					return;
				}

				// берем клики внутри каждого слоя
				let clicks = Array.from(layer.querySelector('.screen-layer__clicks')?.querySelectorAll('.click') || []);

				// фильтруем клики которые входят в видимую область прокрутки
				clicks = clicks.filter((click) => {
					const rect = click.getBoundingClientRect();
					const clickX = rect.left + rect.width / 2;
					const clickY = rect.top + rect.height / 2;
					return isWithin(clickX, intersection.left, intersection.right) && isWithin(clickY, intersection.top, intersection.bottom);
				});

				clicks.forEach((click) => {
					const rect = click.getBoundingClientRect();
					const clickX = rect.left + rect.width / 2;
					const clickY = rect.top + rect.height / 2;

					// абсолютные координаты области - это координаты относительно всего экрана в несжатом виде
					const areaMinX = area.absoluteLeft * scaleRatio;
					const areaMaxX = (area.absoluteLeft + area.width) * scaleRatio;
					const areaMinY = area.absoluteTop * scaleRatio;
					const areaMaxY = (area.absoluteTop + area.height) * scaleRatio;

					if (isWithin(clickX, areaMinX, areaMaxX) && isWithin(clickY, areaMinY, areaMaxY)) {
						// console.log('click', click, layer, rect, area, click.getAttribute('data-handled'))
						area.clicksCount++;
						if (click.getAttribute('data-handled') !== 'true') {
							area.missedClicksCount++;
						}
						responseIdSet.add(click.getAttribute('data-response-id'));
						area.respondentsCount++;
					}
				});
			});

			area.respondentsCount = responseIdSet.size;
			area.respondentsPercent = respondentsTotal > 0 ? Math.round((area.respondentsCount / respondentsTotal) * 100) : 0;
		});
		return newAreas;
	}

	function createDrawingDiv() {
		const drawingDiv = document.createElement('div');
		drawingDiv.style.position = 'absolute';
		drawingDiv.style.border = '2px dashed #0066FF';
		drawingDiv.style.pointerEvents = 'none';
		drawingDiv.style.borderRadius = '8px';
		drawingDiv.style.backgroundColor = 'rgba(82, 151, 255, 0.25)';
		return drawingDiv;
	}

	function removeArea(areaToRemove: IArea) {
		setAreas((current) => current.filter((area) => area.id !== areaToRemove.id));
	}

	/** Скейлит число, чтобы получить исходный (реальный размер) до масштабирования прототипа */
	function s(value: number) {
		return value * reverseScale;
	}

	return {
		clickAreaRef,
		drawingAreaRef,
		areas,
		removeArea
	}
}

function calculateAreaIntersection(parents: HTMLElement[], intersection: { top: number; left: number; bottom: number; right: number; }) {
	parents.forEach((div) => {
		const divRect = div.getBoundingClientRect();

		intersection.top = Math.max(intersection.top, divRect.top);
		intersection.left = Math.max(intersection.left, divRect.left);
		intersection.bottom = Math.min(intersection.bottom, divRect.bottom);
		intersection.right = Math.min(intersection.right, divRect.right);
	});
}

function getParentLayers(layer: Element) {
	const parents = [];
	let parent = layer as HTMLElement | null;
	while (parent) {
		parent = parent.parentElement;
		if (parent?.classList.contains('screen-layer')) {
			parents.push(parent);
			if (parent.classList.contains('root')) break;
		}
	}
	return parents;
}
