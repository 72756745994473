import React, { PropsWithChildren, createContext, useContext, useEffect, useRef, useState } from 'react'
import FigmaIFrame from '../../Figma/FigmaIFrame'
import { IReportPrototypeClick } from './FigmaReport'
import { IPrototypeNode, IPrototypeOptions } from '../../Figma/Models';
import useFigmaContext from '../../Figma/hooks/useFigmaContext';
import { Heatmap } from '../Clickmaps';
import { tabsLabels } from '../hooks/useReportScreenTabs';
import { FigmaReportSidebar } from './FigmaReportSidebar';
import useClickareas, { IArea } from '../Clickareas/useClickareas';
import { getHeatmapMaxWeight, isWithin } from '../Clickmaps/Utils';
import { CanvasSize } from '../../../models/Response';
import { IconCursor, IconDelete, IconRespondent } from '../../../icons';
import _ from 'lodash';

import { useTranslation } from "react-i18next";
import clsx from 'clsx';
import Loader from '../../Loader';


enum DisplayMode {
	IMAGE = 'image',
	CLICKS = 'clicks',
	HEATMAP = 'heatmap'
}

const maxWidth = 800;

const ScreenClicksMaxWeightCtx = createContext<MaxValueContainer | null>(null);
class MaxValueContainer {
	public value: { value: number } = { value: 0 };
	public updateMaxValue(value: number): number {
		if (value > this.value.value) this.value = { value };
		return value;
	}
}

export default function NativePrototypeScreenReport(props: {
	currentScreenId: string,
	clicks: IReportPrototypeClick[],
	figmaScreenStructure: IPrototypeNode,
	prototypeOptions: IPrototypeOptions,
	onScreenChanged: (screenId: string) => void;
	showTabs: boolean;
	activeTab: string;
	canShowClicksOrder: boolean;
	canShowFirstClickControls: boolean;
	screenStats?: any[];
	size?: CanvasSize;
}) {
	const prototypeScreenSize = props.figmaScreenStructure.absoluteBoundingBox as { width: number, height: number };
	const protoWidth = prototypeScreenSize?.width || 0;
	const protoHeight = prototypeScreenSize?.height || 0;
	const activeTab = props.activeTab;

	const [showOnlyFirstClicks, setShowOnlyFirstClicks] = useState(false);
	const [showClicksOrder, setShowClicksOrder] = useState(false);
	const [reportDisplayMode, setReportDisplayMode] = useState<DisplayMode | null>(null);
	const [clickAreaMode, setClickAreaMode] = useState(false);

	const figmaContext = useFigmaContext();
	const screenClicks = (showOnlyFirstClicks ? _.uniqBy(props.clicks.filter(c => c.number === 1), click => click.answerId) : props.clicks)
		.filter(click => click.clickData?.raw?.presentedNodeId === props.currentScreenId);

	const maxWeightRef = useRef(new MaxValueContainer());

	// console.log('CLICKS', screenClicks)

	const reportWrapperRef = useRef<HTMLDivElement>(null);
	const wrapperMaxWidth = (reportWrapperRef.current?.offsetWidth || maxWidth) - 230 - 24;
	const reportWindowHeight = reportWrapperRef.current?.offsetHeight || 0;
	const scaleRatio = protoWidth > wrapperMaxWidth ? wrapperMaxWidth / protoWidth : 1;
	const wrapperWidth = protoWidth * scaleRatio;
	// если высота прототипа даже после скейла больше, чем высота окна, то мы ставим фрейму высоту окна модалки, чтобы внутри прототипа работал скролл
	const wrapperHeight = Math.min((protoHeight) * scaleRatio, reportWindowHeight);
	const wrapperScaledHeight = wrapperHeight / scaleRatio;
	const frameHeight = Math.min(protoHeight, wrapperScaledHeight);
	const marginTop = ((reportWindowHeight - frameHeight * scaleRatio) / 2);

	// ref for clickmap
	const clickmapRef = useRef<HTMLDivElement>(null);
	const { areas, clickAreaRef, removeArea } = useClickareas(scaleRatio, clickmapRef, props.currentScreenId, clickAreaMode, showOnlyFirstClicks, props.screenStats?.[0][1] || 0 as number);
	const [isLoading, setIsLoading] = useState(true);
	const [startNodeId] = useState(props.currentScreenId);


	useEffect(() => {
		figmaContext?.onPrototypeLoaded(onProtoLoad);

		return () => {
			figmaContext?.offPrototypeLoaded(onProtoLoad);
		};
	}, []);

	useEffect(() => {
		maxWeightRef.current.value = { value: 0 };
	}, [showOnlyFirstClicks, props.currentScreenId])

	function onProtoLoad() {
		setIsLoading(false);
	}

	useEffect(() => {
		// показываем/скрываем хитмапы и клики в зависимости от вкладки
		if (!props.showTabs) {
			setReportDisplayMode(DisplayMode.CLICKS);
			return;
		}
		if (activeTab === tabsLabels.CLICKS) {
			setReportDisplayMode(DisplayMode.CLICKS);
		} else if (activeTab === tabsLabels.HEATMAP) {
			setReportDisplayMode(DisplayMode.HEATMAP);
		} else {
			setReportDisplayMode(DisplayMode.IMAGE);
		}
	}, [activeTab]);

	function onPresentedNodeChanged(data: any) {
		props.onScreenChanged(data?.presentedNodeId);
	}

	return <>
		<div className='prototype-screen-report flex flex-row gap-6 items-start justify-between w-full h-full' ref={reportWrapperRef}>
			{/* Здесь релатив позиционирование нужно чтобы накладывать кликмапы на фрейм */}
			<div className={clsx('relative w-full h-full bg-black rounded-md', isLoading ? "overflow-hidden" : "overflow-auto")}>
				<div className={clsx("absolute inset-0 flex items-center justify-center bg-black z-10", !isLoading && "hidden")}>
					<div className="text-white font-medium text-center">
						<Loader />
						<div className="mt-4 text-base font-normal text-gray-500">Loading...</div>
					</div>
				</div>
				<div className={clsx("prototype-screen-report__content m-auto", isLoading && "invisible")} style={{ width: wrapperWidth + 'px', height: wrapperHeight + 'px', marginTop: marginTop + 'px' }}>
					<div className="prototype-screen-report__wrapper relative m-auto my-0" style={{ width: protoWidth + 'px', transform: `scale(${scaleRatio})`, transformOrigin: 'left top' }}>
						<FigmaIFrame
							options={{
								...props.prototypeOptions,
								scaling: 'scale-down-width',
								watchscroll: true,
								startNodeId,
							}}
							onPresentedNodeChanged={onPresentedNodeChanged}
							width={`${protoWidth}px`}
							// height={`${800}px`}
							height={`${frameHeight}px`}
						/>
						<div
							ref={clickmapRef}
							className={`clickmap absolute overflow-hidden top-0 left-0 bg-white bg-opacity-25 ${clickAreaMode ? 'pointer-events-auto cursor-[crosshair]' : 'pointer-events-none'}`}
							style={{ width: protoWidth + 'px', height: frameHeight + 'px' }}>
							<ScreenClicksMaxWeightCtx.Provider value={maxWeightRef.current}>
								<ScreenLayer
									key={props.figmaScreenStructure.id}
									baseCoordinates={props.figmaScreenStructure.absoluteBoundingBox}
									layer={props.figmaScreenStructure}
									clicks={screenClicks}
									displayMode={reportDisplayMode}
									showClicksOrder={showClicksOrder}
									freezeScroll={clickAreaMode}
									// height={testerProtoHeight}
									isRoot
								/>
							</ScreenClicksMaxWeightCtx.Provider>
							<div className="clickareas absolute -top-6 -left-6 w-[calc(100%+3rem)] h-[calc(100%+3rem)]" ref={clickAreaRef}>
								{areas.map((area, index) => {
									return <Clickarea key={'area-' + index} area={area} onClick={removeArea} />
								})}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="prototype-screen-report__sidebar min-w-[230px] max-w-[230px] h-full overflow-auto">
				<FigmaReportSidebar
					showFirstClickControls={!isLoading && props.canShowFirstClickControls}
					showClicksOrderControls={!isLoading && props.canShowClicksOrder}
					showClickAreas={false}
					screenStats={props.screenStats || []}
					onAreaAdded={(a: any) => { }}
					setShowOnlyFirstClicks={setShowOnlyFirstClicks}
					showOnlyFirstClicks={showOnlyFirstClicks}
					setShowClicksOrder={setShowClicksOrder}
					showClicksOrder={showClicksOrder}
					showClickAreaMode={!props.canShowClicksOrder}
					clickAreaModeOn={clickAreaMode}
					setClickAreaModeOn={setClickAreaMode}
				/>
			</div>
		</div>
	</>
}

interface IScreenClick extends IReportPrototypeClick {
	fixedOwnerId?: string;
	hasCorrectedPosition?: boolean;
}

function adjusCoordinates(originalCoordinates: { x: number, y: number }, adjustment: { x: number, y: number }) {
	return { x: originalCoordinates.x - adjustment.x, y: originalCoordinates.y - adjustment.y };
}

export function ScreenLayer(props: {
	/** базовые абсолютные координаты экрана прототипа на канвасе */
	baseCoordinates: { x: number, y: number },
	parentCoordinates?: { x: number, y: number },
	layer: IPrototypeNode,
	clicks: IReportPrototypeClick[];
	displayMode?: DisplayMode | null;
	showClicksOrder: boolean;
	isRoot?: boolean;
	freezeScroll?: boolean;
	height?: number;
	isFixed?: boolean;
}) {

	const layerRef = useRef<HTMLDivElement>(null);
	const scrollAreaSizeRef = useRef<{ size: { width: number, height: number } | null }>({ size: null });
	const [scrollableAreaSize, setScrollableAreaSize] = useState<{ width: number | null, height: number | null }>(scrollAreaSizeRef.current?.size || { width: null, height: null });
	// state for canDrawHeatmap
	const [canDrawHeatmap, setCanDrawHeatmap] = useState(false);
	const figmaContext = useFigmaContext();

	//#region Layer size stuff and scroll
	const layerSize = props.layer.absoluteBoundingBox as { width: number, height: number };
	const layerCoordinates = props.layer.absoluteBoundingBox as { x: number, y: number };
	// вычисляем координаты для нода в html на основе абслютных координат отностиельно канваса фигмы
	const adjustedCoordinates = adjusCoordinates(layerCoordinates, props.baseCoordinates);
	// запоминаем координаты для фиксированных слоев, чтобы потом найти клики внутри них
	const fixedLayerCoordinates = Object.assign({}, adjustedCoordinates);

	if (props.isFixed && props.parentCoordinates) {
		// добавляем координаты родителя, т.к. они не учитываются в абсолютных координатах
		adjustedCoordinates.x += props.parentCoordinates.x;
		adjustedCoordinates.y += props.parentCoordinates.y;
	}

	const layerStyleHeight = props.height || layerSize.height;
	const layerStyle = {
		width: layerSize.width + 'px',
		height: layerStyleHeight + 'px',
		top: adjustedCoordinates.y + 'px',
		left: adjustedCoordinates.x + 'px'
	};

	// используем контекст для синхронизации скролла с прототипом для каждого такого нода
	figmaContext?.onScroll(props.layer.id, (eventData) => {
		layerRef.current?.scrollTo(eventData.scrollPosition.x, eventData.scrollPosition.y);
		// if (layerRef.current) {
		// 	layerRef.current.style.left = (adjustedCoordinates.x - eventData.scrollPosition.x) + 'px';
		// 	layerRef.current.style.top = (adjustedCoordinates.y - eventData.scrollPosition.y) + 'px';
		// }
		// обновляем размеры скроллабельной области, т.к. с событием нам приходят реальные размеры
		// console.log('setScrollableAreaSize', eventData.scrollPosition);`
		if (scrollableAreaSize.height == null ||
			scrollableAreaSize.width == null ||
			eventData.scrollPosition.width > scrollableAreaSize.width ||
			eventData.scrollPosition.height > scrollableAreaSize.height ||
			layerStyleHeight + eventData.scrollPosition.y > scrollableAreaSize.height ||
			layerSize.width + eventData.scrollPosition.x > scrollableAreaSize.width
		) {
			const size = {
				width: Math.max(eventData.scrollPosition.width, layerSize.width + eventData.scrollPosition.x),
				height: Math.max(eventData.scrollPosition.height, layerStyleHeight + eventData.scrollPosition.y)
			};
			scrollAreaSizeRef.current.size = size;
			setScrollableAreaSize(size);
		}
	});

	//#endregion

	const [clicks, setClicks] = useState<IScreenClick[]>([]);
	const [scrollableArea, setScrollableArea] = useState<{ maxX: number, maxY: number, minX: number, minY: number }>({ maxX: layerSize.width, maxY: layerSize.height, minX: 0, minY: 0 });
	const [fixedChildrenData, setFixedChildrenData] = useState<{ coords: { x: number, y: number }, clicks: IScreenClick[] }[]>([]);
	const heatmapClicks = props.isFixed ? clicks.map(c => {
		const click = _.cloneDeep(c);
		click.clickData.top = ((click.clickData.raw?.y || 0) - fixedLayerCoordinates.y) || click.clickData.top;
		click.clickData.left = ((click.clickData.raw?.x || 0) - fixedLayerCoordinates.x) || click.clickData.left;
		return click;
	}) : clicks;

	const maxWeightContext = useContext(ScreenClicksMaxWeightCtx);
	maxWeightContext?.updateMaxValue(getHeatmapMaxWeight(heatmapClicks, 1, 1));

	useEffect(() => {
		const clicks = [] as IScreenClick[];
		const clicksToCorrect = [] as IScreenClick[];
		let maxX = layerSize.width;
		let maxY = layerSize.height;
		let minX = 0;
		let minY = 0;

		const fixedChildrenData = props.layer.fixedChildren.map((child) => {
			return {
				coords: adjusCoordinates(child.absoluteBoundingBox, layerCoordinates),
				clicks: []
			}
		}) as { coords: { x: number, y: number }, clicks: IScreenClick[] }[];

		if (props.isFixed) {
			// Если текущий слой - это фиксированный элемент, то ищем только клики внутри него
			for (let i = 0; i < props.clicks.length; i++) {
				const click = props.clicks[i];
				clicks.push(click);
			}
			if (!canDrawHeatmap) setCanDrawHeatmap(true);
		}
		// Иначе если скроллящийся слой, то берем клики которые принадлежат ему или все, если нет детей
		else {
			// loop through clicks and find the max x and y and min x and y and filter clicks by layer id
			for (let i = 0; i < props.clicks.length; i++) {
				const click = props.clicks[i];
				if (click.clickData.nodeId === props.layer.id || props.isRoot && !props.layer.children?.length) {
					const clickCopy = _.cloneDeep(click) as IScreenClick;

					// наличие координат - признак того, что это клик может быть внутри фиксированного элемента
					if (clickCopy.clickData.raw?.nearestScrollingFrameMousePosition) {
						// ищем клики внутри фиксированных элементов
						fixedChildrenData.forEach((childData, i) => {
							const fixedChild = props.layer.fixedChildren[i];

							if (isWithin(clickCopy.clickData.raw?.nearestScrollingFrameMousePosition?.x as number, childData.coords.x, childData.coords.x + fixedChild.absoluteBoundingBox.width) &&
								isWithin(clickCopy.clickData.raw?.nearestScrollingFrameMousePosition?.y as number, childData.coords.y, childData.coords.y + fixedChild.absoluteBoundingBox.height)) {
								clickCopy.fixedOwnerId = props.layer.id;
								childData.clicks.push(clickCopy);
							}
						});
					}

					// если клик не принадлежит фиксированному элементу, то добавляем его в массив кликов
					if (!clickCopy.fixedOwnerId) clicks.push(clickCopy);

					if (clickCopy.clickData.nodeId !== props.layer.id) clicksToCorrect.push(clickCopy);

					maxX = Math.max(maxX, clickCopy.clickData.left);
					maxY = Math.max(maxY, clickCopy.clickData.top);
					minX = Math.min(minX, clickCopy.clickData.left);
					minY = Math.min(minY, clickCopy.clickData.top);
				}
			}
		}

		if (!scrollAreaSizeRef.current.size) setScrollableArea({ maxX, maxY, minX, minY });
		setClicks(clicks);
		setFixedChildrenData(fixedChildrenData);
		// console.log('clicks', clicks, clicksToCorrect);

		// UPD: 07.05.2024: Больше эта штука не нужна, т.к. мы теперь запрашиваем всю рендер дату сразу по всем кликам в figmaReport
		// По таймауту запрашиваем координаты точек, которые не принадлежат текущему слою, чтобы скорректировать их позицию
		// Таймаут - чтобы сцена успела отрендериться и мы смогли получить правильные координаты
		// setTimeout(() => {
		// Promise.all(
		// 	clicksToCorrect.map(
		// 		(click) => figmaContext?.getNodePosition(click.clickData.nodeId)
		// 			.then((nodePosition) => ({ click, nodePosition }))
		// 			.catch(e => console.warn(e))
		// 	)
		// ).then((results) => {
		// 	const newClicks = clicks.map((click) => {
		// 		const result = results.find(r => r?.click === click);
		// 		if (result) {
		// 			if (click.hasCorrectedPosition) return click;
		// 			click.clickData.top += result.nodePosition.y;
		// 			click.clickData.left += result.nodePosition.x;
		// 			click.hasCorrectedPosition = true;
		// 		}
		// 		return click;
		// 	});
		// 	setClicks(newClicks);
		// });
		// }, 300);

		// console.log('clicks', clicks);

	}, [props.clicks, props.isFixed]);


	// пока не узнали реальный размер области, то умножаем тупо на 2, чтобы область была больше и скроллилась
	const coeff = props.layer.overflowDirection ? 2 : 1;
	const scrollableAreaHeight = scrollableAreaSize.height || (scrollableArea.maxY * coeff);
	const scrollableAreaWidth = scrollableAreaSize.width || (scrollableArea.maxX * coeff);

	// если скролла у слоя нет, то устанавливаем размеры, чтобы отрисовался хитмап
	useEffect(() => {
		if (!props.layer.overflowDirection || scrollableArea.maxX <= layerSize.width && scrollableArea.maxY <= layerSize.height) {
			setScrollableAreaSize({ width: scrollableArea.maxX, height: scrollableArea.maxY });
		}
	}, [props.layer.overflowDirection, scrollableArea]);

	// if (props.isFixed) {
	// 	console.log('fixed height', scrollableAreaSize.height, clicks.filter(click => props.isFixed || !((click as any).fixedOwner)))
	// }

	return <div
		key={props.layer.id}
		id={props.layer.id}
		className={clsx('screen-layer bg-black bg-opacity-[5%]', {
			'root': props.isRoot,
			'fixed': props.isFixed,
			'absolute': !props.isFixed
		}, props.layer.clipsContent || ((scrollableAreaSize.height || 0) > layerStyleHeight || (scrollableAreaSize.width || 0) > layerSize.width) ?
			(props.freezeScroll ? 'overflow-hidden' : 'overflow-auto') :
			'')}
		style={layerStyle}
		ref={layerRef}
	>
		<div className="screen-layer__data relative" style={{ width: `${scrollableAreaWidth}px`, height: `${scrollableAreaHeight}px` }}>

			{!props.isFixed && <div className={`screen-layer__clicks w-full h-full absolute ${props.displayMode === DisplayMode.CLICKS ? 'visible' : 'invisible'}`}>
				{clicks.map((click, index) => {
					if (click.fixedOwnerId) return null;
					return <Click key={'click-' + index} click={click} showNumber={props.showClicksOrder} />
				})}
			</div>}
			{!!props.isFixed && <div className={`screen-layer__clicks w-full h-full absolute ${props.displayMode === DisplayMode.CLICKS ? 'visible' : 'invisible'}`}>
				{clicks.map((click, index) => {
					return <Click key={'click-' + index} click={click} showNumber={props.showClicksOrder} left={(click.clickData.raw?.x || 0) - fixedLayerCoordinates.x} top={(click.clickData.raw?.y || 0) - fixedLayerCoordinates.y} />
				})}
			</div>}

			{/* 
			не отрисовываем хитмапы, пока не придет событие скролла, после которого мы узнаем реальный размер
			иначе хитмапы отрисуются с неверным размером и их потом очень геморно перерисовывать. 
			Событие скрола приходит после инициализации прототипа
			 */}
			{!props.isFixed && scrollableAreaSize.height !== null && <Heatmap
				className={`screen-layer__heatmap ${props.displayMode === DisplayMode.HEATMAP ? 'visible' : 'invisible'}`}
				width={scrollableAreaWidth}
				height={scrollableAreaHeight}
				clicks={heatmapClicks}
				maxWeight={maxWeightContext?.value.value || 0}
				useAbsoluteValues
			/>}

			{props.isFixed && canDrawHeatmap && <Heatmap
				className={`screen-layer__heatmap ${props.displayMode === DisplayMode.HEATMAP ? 'visible' : 'invisible'}`}
				width={scrollableAreaWidth}
				height={scrollableAreaHeight}
				maxWeight={maxWeightContext?.value.value || 0}
				clicks={heatmapClicks}
				useAbsoluteValues
			/>}


		</div>
		{props.layer.children?.map((child) => {
			return <ScreenLayer key={child.id}
				baseCoordinates={layerCoordinates}
				layer={child}
				clicks={props.clicks}
				displayMode={props.displayMode} showClicksOrder={props.showClicksOrder} freezeScroll={props.freezeScroll} />
		})}

		{fixedChildrenData?.map((data, i) => {
			const child = props.layer.fixedChildren[i];
			return <ScreenLayer key={child.id}
				baseCoordinates={layerCoordinates}
				parentCoordinates={adjustedCoordinates}
				layer={child}
				clicks={data.clicks}
				isFixed={true}
				displayMode={props.displayMode} showClicksOrder={props.showClicksOrder} freezeScroll={props.freezeScroll} />
		})}

	</div>
}

export function Clickarea(props: {
	onClick: (area: IArea) => void,
	area: IArea
}) {
	const { t } = useTranslation();
	const area = props.area;
	return <div
		onClick={() => props.onClick(area)}
		className="clickarea absolute border-2 bg-opacity-25 bg-blue-400 border-blue-600 rounded-md cursor-pointer select-none"
		style={{
			top: area.top + 'px',
			left: area.left + 'px',
			width: area.width + 'px',
			height: area.height + 'px'
		}}
	>
		<div className="clickarea__info absolute text-[11px] text-white rounded-md p-1 m-1 font-bold">
			<ClickareaDataLabel title={`${t("Clicks")}: ${area.clicksCount}`}>
				<IconCursor className='w-3 h-3 fill-current' /><span className='whitespace-nowrap'>{t("Clicks")}: {area.clicksCount}</span>
			</ClickareaDataLabel>
			<ClickareaDataLabel title={`${t("Missed")}: ${area.missedClicksCount}`}>
				<IconDelete className='w-3 h-3 fill-current' /><span className='whitespace-nowrap'>{t("Missed")}: {area.missedClicksCount}</span>
			</ClickareaDataLabel>
			<ClickareaDataLabel title={`${t("Respondents")}: ${area.respondentsCount} (${area.respondentsPercent}%)`}>
				<IconRespondent className='w-3 h-3 fill-current' /><span className='whitespace-nowrap'>{t("Respondents")}: {area.respondentsCount} ({area.respondentsPercent}%)</span>
			</ClickareaDataLabel>
		</div>
	</div>
}

export function ClickareaDataLabel(props: PropsWithChildren<{ title?: string }>) {

	return <div className='rounded-md bg-blue-600 bg-opacity-75 my-1 px-1 w-fit-content flex items-center gap-1 flex-nowrap' title={props.title}>
		{props.children}
	</div>
}

export function Click(props: { click: IReportPrototypeClick, showNumber: boolean, top?: number, left?: number }) {
	const clickStyle = {
		top: (props.top || props.click.clickData.top) + 'px',
		left: (props.left || props.click.clickData.left) + 'px'
	}

	return <div
		className={`click border-2 -translate-x-2 -translate-y-2 absolute w-4 h-4 rounded-full
		${props.click.handled ? 'bg-green-500 border-green-700' : 'bg-red-500 border-red-700'} 
		flex items-center justify-center text-xs`}
		data-handled={props.click.handled}
		data-response-id={props.click.answerId}
		style={clickStyle}>{props.showNumber ? props.click.number : ''}</div>
}