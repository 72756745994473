import React from 'react';
import Popover from '../../../Popover';
import { MenuItem } from './MenuItem';


interface ContextMenuArgs {
	children: any,
	menuItems: IContextMenuItem[],
	position?: string,
	styleMode?: string,
	gap?: number,
	className?: string;
	onClick: (item: IContextMenuItem) => void | Promise<void>,
	onOpen?: (() => void) | null,
	onClose?: (() => void) | null
}

export function ContextMenu({ children, menuItems, className, position = 'bottom-right', styleMode='light', gap = 2, onClick, onOpen, onClose }: ContextMenuArgs) {

	const gapClass = `gap-${gap}`;

	const onMenuItemClick = (e: React.MouseEvent, item: IContextMenuItem, popoverSetIsVisible: (arg: boolean) => void) => {
		e.stopPropagation();
		if (!item.disabled) {
			onClick(item);
			popoverSetIsVisible(false);
			if (onClose) {
				onClose();
			}
		}
	}

	return (
    <Popover
      position={position}
      className={className}
      styleMode={styleMode}
      onOpen={onOpen as any}
      onClose={onClose as any}
      getContent={(popoverSetIsVisible: (arg: boolean) => void) => (
        <div className="context-menu-items-list w-auto text-base rounded-md" onClick={(e) => e.stopPropagation()}>
          <div className={`flex flex-col items-start`}>
            {menuItems.map((item, i) => {
              if (item.code === "divider") {
                return <div key={item.code + i} className={`h-px my-2 w-full bg-gray-300`} />;
              }

              return (
                <MenuItem key={item.code} item={item} onClick={(e) => onMenuItemClick(e, item, popoverSetIsVisible)} />
              );
            })}
          </div>
        </div>
      )}
      anchorClassName="context-menu__anchor hover:opacity-75 cursor-pointer transition-opacity duration-75 ease-in"
    >
      {children}
    </Popover>
  );
}


export interface IContextMenuItem {
	name: string;
	icon?: React.ReactElement;
	code: string;
	disabled?: boolean;
	description?: string;
}