import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { getCurrentLanguage } from '../../i18n/config';

export default function ErrorBoundaryFallbackMessage() {
	const { t } = useTranslation('translation')

	function conatctSupport() {
		window.Intercom("showNewMessage", getCurrentLanguage() === "ru" ? "Здравствуйте, в отчете теста происходит ошибка" : "Hello, there is an error in the test report");
	}

	return <div className='error-doundary-fallback-message w-full h-64 flex flex-col gap-2 items-center justify-center'>
		<div className='message-title text-base font-medium text-gray-600'>{t('Oops, something went wrong.')}</div>
		<div className='message-title text-sm text-gray-500'>
			<Trans i18nKey={`Please, <1>message us</1> if error doesn't disappear.`}>
				Please, <a className='text-blue-500' onClick={conatctSupport}>message us</a> if error doesn't disappear.
			</Trans>
		</div>
	</div>
}