import React, { useContext, useEffect, useState } from "react";
import "../tailwind.generated.css";
import clsx from "clsx";
import { IconError, IconInfo, IconCheck } from "../icons";

import appContext from "../AppContext";

import { setNotification } from "../actions";

const Toast = () => {
  const { state, dispatch } = useContext(appContext);
  const [isFading, setIsFading] = useState(false);

  const text = state.app.notification.text;
  const type = state.app.notification.type;

  const iconMap = {
    error: IconError,
    info: IconInfo,
    success: IconCheck,
  }

  let IconComponent: any = null;

  if (type) {
    IconComponent = iconMap[type as keyof typeof iconMap];
  };

  useEffect(() => {
    if (text) {
      setTimeout(() => {
        setIsFading(true);
        setTimeout(() => {
          dispatch(setNotification(null, null));
          setIsFading(false);
        }, 300);
      }, 3000);
    }
  }, [text]);

  if (!text || !type) {
    return null;
  }

  return (
    <div
      style={{ zIndex: "99990", transform: "translate(-50%, 0)", left: "50%" }}
      className={clsx(
        "toast fixed bottom-8 text-white rounded-full px-4 py-2 font-medium flex items-center justify-center",
        isFading && "fadeToast",
        !isFading && "toast",
        type === "success" && "bg-green-600",
        type === "info" && "bg-black",
        type === "error" && "bg-red-600",
        type === "warning" && "bg-orange-600"
      )}
    >
      {type === "success" && IconComponent && <IconComponent
        className="fill-current text-white mr-2"
        width={20}
        height={20}
        />
      }
      {text}
    </div>
  );
};

export default Toast;
