import _ from "lodash";
import { v4 as uuidv4 } from "uuid";

import { storage } from "./firebase";

export const generateId = () => {
  return uuidv4();
};

export const getFirstClickCoordinates = (e) => {
  const imageNode = document.getElementById("firstClickImage");
  const left =
    e.pageX - imageNode.getBoundingClientRect().left - window.scrollX;
  const top = e.pageY - imageNode.getBoundingClientRect().top - window.scrollY;
  const w = imageNode.getBoundingClientRect().width;
  const h = imageNode.getBoundingClientRect().height;
  return {
    left: Math.round((left / w + Number.EPSILON) * 1000) / 1000,
    top: Math.round((top / h + Number.EPSILON) * 1000) / 1000,
  };
};

export const generateVerificationCode = () => {
  return (Math.floor(Math.random() * 10000) + 10000).toString().substring(1);
};

export const isLocalFlagEnabled = (flag) => {
  if (localStorage.getItem(flag) === "true") {
    return true;
  } else {
    return false;
  }
};

export const uploadImage = async (image, path) => {
  return storage
    .ref()
    .child(path)
    .put(image)
    .then(async (snapshot) => {
      const url = await snapshot.ref.getDownloadURL();
      return url;
    });
};

export const getRedirectUrl = () => {
  const params = new URLSearchParams(window.location.search);
  const redirectUrl = params.get("redirectUrl");

  if (!redirectUrl) {
    return null;
  }

  if (new RegExp("^(?:[a-z+]+:)?//", "i").test(redirectUrl)) {
    // We should return NULL for absolute URLs to avoid some kind of redirect fraud
    return null;
  }

  return redirectUrl;
};

export const addActualParamsToUrl = (relativeUrl) => {
  const params = new URLSearchParams(window.location.search);
  const formattedUrl = new URL(relativeUrl, window.location);

  params.forEach((value, key) => {
    formattedUrl.searchParams.set(key, value);
  });

  return formattedUrl.toString().replace(/^(?:\/\/|[^/]+)*\//, "/");
};
