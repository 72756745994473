import React, { useState, useEffect, useRef } from "react";
import clsx from "clsx";
import "../../tailwind.generated.css";

import { IconSearch } from "../../icons";

import Input from "../Input";

type SearchInputProps = {
  placeholder?: string;
  id: string;
  className?: string;
  onChange: (value: string) => void;
};

const SearchInput = ({ placeholder, onChange, id, className }: SearchInputProps) => {

  const searchWrapperRef = useRef<HTMLDivElement | null>(null);

  return (
    <div
    ref={searchWrapperRef}
      className={clsx(
        "default-input flex items-center justify-start",
        className && className
      )}
    >
      <IconSearch className="fill-current text-gray-500 mr-1" />
      <Input
        initialValue=""
        className={clsx("w-full rounded-none")}
        placeholder={placeholder || "Search"}
        id={id}
        onChange={(value: string) => {
          onChange(value);
        }}
        onFocus={() => {
          if (searchWrapperRef.current) {
          searchWrapperRef.current?.classList.add("default-input-focus");
          }
        }}
        onBlur={() => {
          if (searchWrapperRef.current) {
          searchWrapperRef.current?.classList.remove("default-input-focus");
          }
        }}
        focusOnMount={false}
        selectAllOnFocus={false}
      />
    </div>
  );
};

export default SearchInput;
