import React from "react";
import "../../tailwind.generated.css";

import OpenQuestionReport from "./OpenQuestionReport";
import ChoiceReport from "./ChoiceReport";
import FirstClickReport from "./FirstClickReport/FirstClickReport";
import FigmaReport from "./FigmaReport/FigmaReport";
import PreferenceReport from "./PreferenceReport";
import CardSortReport from "./CardSortReport/CardSortReport";
import MatrixReport from "./MatrixReport/MatrixReport";
import ScaleReport from "./ScaleReport/ScaleReport";

import { blockTypeMap } from "../Common";
import { Block, BlockType } from "../../models/Test";

import { IAnswerMetaData, IReportTestData } from "../../models/Report";
import {
	IBlockTestResponse,
	IChoiceResponse,
	IFigmaResponse,
	IOpenQuestionResponse,
	isChoiceResponseArray,
	isFirstClickResponseArray,
	isCardSortResponseArray,
	isAIResponseArray,
} from "../../models/Response";
import { IScaleResponse } from "../../models/Response";
import { ICardSortResponse } from "../../models/Response";
import { IPreferenceResponse } from "../../models/Response";
import { IMatrixResponse } from "../../models/Response";
import { isOpenQuestionsResponseArray, isFigmaResponseArray, isScaleResponseArray, isMatrixResponseArray } from "../../models/Response";
import { Trans, useTranslation } from "react-i18next";
import { ErrorBoundary } from '@sentry/react';
import ErrorBoundaryFallbackMessage from '../Common/ErrorBoundaryFallbackMessage';
import AiChatReportSummary from './AiChatReport/AiChatReportSummary';
import { TestBlockReportContext } from './ReportContext';

import ImageWithZoom from "./ImageWithZoom";

type ContentBlockHeaderProps = {
	blockContent: Block;
	type: BlockType;
	responsesNum?: number;
	i: number | undefined;
};

function ContentBlockHeader({ blockContent, type, responsesNum = 0, i }: ContentBlockHeaderProps) {
	const { t } = useTranslation();
	const blockType = blockTypeMap[type];

	const header = blockContent.text;

	const image = 'image' in blockContent ? blockContent.image : undefined;

	return (
		<div className="flex items-start leading-6 pb-3 border-b-1 border-gray-200">
			<div className="flex gap-2 max-w-[75%]">
				{i !== undefined && <span className="shrink-0 font-medium">{i + 1}.</span>}
				<span className="align-top">{blockType.getIcon({ height: 24, width: 24 })}</span>
				<span className="text-gray-800 font-medium overflow-hidden text-ellipsis whitespace-nowrap max-w-[480px]" style={{lineHeight: "24px"}}><Trans>{blockTypeMap[type].name}</Trans></span>
			</div>
			<div className="ml-auto text-gray-700 flex-shrink-0">{responsesNum} {t("responded", { count: responsesNum })}</div>
		</div>
	);
}

type ContentBlockResultsProps = {
	responses:
	| IFigmaResponse[]
	| IOpenQuestionResponse[]
	| IChoiceResponse[]
	| IScaleResponse[]
	| ICardSortResponse[]
	| IPreferenceResponse[]
	| IMatrixResponse[]
	| IBlockTestResponse[];
	blockContent: Block;
	metadata: { [key: string]: IAnswerMetaData }
	blockId: string;
	testId: string;
	name?: string;
	sharedByLink?: boolean;
	sharingToken?: string;
	i: number | undefined;
};

function ContentBlockResults({ responses, blockContent, metadata, blockId, testId, name, sharedByLink, sharingToken, i }: ContentBlockResultsProps) {

	if (!responses || !blockContent || responses.length < 1) {
		return null;
	}

	if (!blockContent.type) return <></>;

	return (
		<div key={`summary-${blockId}`} id={`summary-${blockId}`} className="block-results bg-white rounded-2xl p-5 shadow-xs w-full">
			<ErrorBoundary fallback={<ErrorBoundaryFallbackMessage />}>
				<TestBlockReportContext.Provider value={{ testId, block: blockContent, sharedByLink: !!sharedByLink, sharingToken }}>
					<ContentBlockHeader blockContent={blockContent} type={blockContent.type} responsesNum={responses.length} i={i} />
					<div className="mt-2 flex-1">
						{blockContent.type === BlockType.openquestion && isOpenQuestionsResponseArray(responses) && (
							// Open question Block Results
							<OpenQuestionReport responses={responses} questionText={blockContent.text} image={blockContent.image} />
						)}

						{blockContent.type === BlockType.choice && isChoiceResponseArray(responses) && (
							// Choice Block Results
							<ChoiceReport
								type={blockContent.replyType}
								replies={blockContent.replies}
								responses={responses}
								questionText={blockContent.text}
								withOther={blockContent.other}
								image={blockContent?.image}
							/>
						)}

						{blockContent.type === BlockType.preference && (
							<PreferenceReport replies={blockContent.replies} responses={responses} questionText={blockContent.text} />
						)}

						{blockContent.type === BlockType.firstclick && isFirstClickResponseArray(responses) && (
							<FirstClickReport text={blockContent.text} imageUrl={blockContent.image} responses={responses} testId={testId} blockId={blockId} />
						)}

						{blockContent.type === BlockType.figma && isFigmaResponseArray(responses) && (
							<FigmaReport block={blockContent} responses={responses.map((response) => { return { ...response, userAgent: metadata[response.answerId].userAgent } })} testId={testId} sharingToken={sharingToken} isSummaryReport={true} />
						)}

						{blockContent.type === BlockType.cardsort && isCardSortResponseArray(responses) && <CardSortReport testId={testId} block={blockContent} responses={responses} sharedByLink={sharedByLink} sharingToken={sharingToken} />}
						{blockContent.type === BlockType.matrix && isMatrixResponseArray(responses) && <MatrixReport block={blockContent} responses={responses} />}
						{blockContent.type === BlockType.scale && isScaleResponseArray(responses) && <ScaleReport block={blockContent} responses={responses} />}
						{blockContent.type === BlockType.ai && isAIResponseArray(responses) && <AiChatReportSummary responses={responses} />}
					</div>
				</TestBlockReportContext.Provider>
			</ErrorBoundary>
		</div>
	);
}

type SummaryProps = {
	testId: string;
	currentBlock: Block & { responses: IBlockTestResponse[] } | undefined | null;
	totalBlocks: number | undefined;
	metadata: { [key:string]: IAnswerMetaData }
	sharedByLink?: boolean;
	sharingToken?: string;
	i: number | undefined;
};

const Summary = (props: SummaryProps) => {

	const { t } = useTranslation();

	if (!props.currentBlock) return null;

	return (
    <div className="summary__wrapper h-full w-full grow-0 shrink-0 flex flex-col gap-4">
      <ContentBlockResults
        name={props.currentBlock.name}
        i={props.i}
        responses={props.currentBlock.responses}
        metadata={props.metadata}
        blockId={props.currentBlock.blockId}
        blockContent={props.currentBlock}
        testId={props.testId}
        sharedByLink={props.sharedByLink}
        sharingToken={props.sharingToken}
      />
      {props.totalBlocks && props.totalBlocks > 1 && (
        <div className="flex items-center gap-2 text-gray-600 text-sm">
          {t("Use")}
          <span className="w-4 h-4 flex items-center justify-center border-gray-400 border-1 rounded-[4px]">↑</span>
          <span className="w-4 h-4 flex items-center justify-center border-gray-400 border-1 rounded-[4px]">↓</span>
          {t("to switch between blocks")}
        </div>
      )}
    </div>
  );
};

export default Summary;
