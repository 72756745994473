import React, { useState, useRef } from "react";
import parse from "html-react-parser";
import _ from "lodash";
import "../../../../../tailwind.generated.css";
import clsx from "clsx";

import { PreferenceBlock, Block, FirstClickBlock } from "../../../../../models/Test";
import { IconImage, IconCheck, IconCancel } from "../../../../../icons";
import { Stepper } from "./ContentBlock/Stepper";
import Button from "../../../../Button";
import FirstClickPointer from "../../../../Test/FirstClickPointer";

import { getFirstClickCoordinates } from "../../../../../utils";

import useIsMobile from "../hooks/useIsMobile";

import { locale, interfaceText } from "../../../../../helpers";
import TaskSidebar from "../TaskSidebar";

type TaskProps = {
  blockData: FirstClickBlock & Block;
};

function Task(props: TaskProps) {
  return (
    <div>
      <span className="block font-medium mb-2">{(interfaceText as any).test[locale()].task}</span>
      <div className="text-lg flex-shrink-0 break-words flex flex-col w-full">
        <div>{parse(props.blockData.questionHtml || props.blockData.text)}</div>
      </div>
    </div>
  );
}

type FirstclickProps = {
  blockData: FirstClickBlock & Block;
  blockAnswer: any;
  handleBlockAnswer: any;
  nextStepOnClick: any;
  withProgress?: boolean;
  totalSteps?: number;
  currentStep?: number;
  responseStart?: any;
  setResponseStart?: any;
  isAnswerValid: boolean;
};

function Firstclick(props: FirstclickProps) {
  const isMobile = useIsMobile();

  const [sidebarState, setSidebarState] = useState<"hidden" | "default" | "overlay">("overlay");
  const [imageReady, setImageReady] = useState(false);

  const taskStarted = !!props.responseStart;
  const isValid = props.blockAnswer?.selectedOptions && props.blockAnswer?.selectedOptions.length > 0;

  const taskContainerRef = useRef<HTMLDivElement | null>(null);
  const imageContainerRef = useRef<HTMLImageElement | null>(null);

  const position = props.blockAnswer.clickData;

  console.log("Firstclick.tsx", props, isMobile, sidebarState, taskStarted, isValid, taskContainerRef, position);

  return (
    <div
      className={clsx("flex-1 flex max-h-full h-full overflow-y-auto w-full", isMobile ? "flex-col" : "flex-row")}
      ref={taskContainerRef}
    >
      <TaskSidebar
        sidebarState={sidebarState}
        setSidebarState={setSidebarState}
        parentRef={taskContainerRef}
        sidebarContent={
          <div className="flex flex-col h-full justify-between">
            <Task blockData={props.blockData} />
            <div className="border-t-1 border-gray-300 pt-4">
              {!props.responseStart && (
                <Button
                  name={(interfaceText as any).firstclickTesting[locale()].showImage}
                  handler={() => {
                    props.setResponseStart(_.now());
                    if (isMobile) {
                      setSidebarState("hidden");
                    } else {
                      setSidebarState("default");
                    }
                  }}
                  large
                  className="mt-4"
                  icon={<IconImage width={20} height={20} className="fill-current text-white mr-2" />}
                  disabled={!imageReady}
                />
              )}
            </div>
          </div>
        }
        content={
          <div className="w-full h-full overflow-y-auto">
              {taskStarted && isMobile && (
              <div className="py-4 px-6">
                <Task blockData={props.blockData} />
              </div>
            )}
          <div className={`flex justify-center p-2 md:p-4`}>
            <div className="relative inline-block" ref={imageContainerRef}>
              {position && (
                <FirstClickPointer
                imageContainerRef={imageContainerRef}
                withPopover={true}
                  position={position}
                  opacity={100}
                  popoverContentBlock={
                    <div className="flex flex-col items-center justify-center">
                      <Button
                        name={""}
                        handler={props.nextStepOnClick}
                        icon={<IconCheck width={24} height={24} className="fill-current text-white" />}
                        small
                        disabled={!props.isAnswerValid}
                        className="mb-2"
                      />
                      <Button
                        small
                        type="ghost"
                        name={""}
                        icon={
                          <IconCancel
                            width={24}
                            height={24}
                            className="fill-current text-gray-600 hover:text-gray-800 transition-all ease-in duration-75"
                          />
                        }
                        handler={() => {
                          props.handleBlockAnswer("clickData", null);
                        }}
                      />
                    </div>
                  }
                />
              )}
              <img
                id="firstClickImage"
                onClick={(e) => {
                  if (!position) {
                    props.handleBlockAnswer("clickData", {
                      left: getFirstClickCoordinates(e).left,
                      top: getFirstClickCoordinates(e).top,
                      responseTime: _.now() - props.responseStart,
                    });
                  }
                }}
                onLoad={() => {
                  setImageReady(true);
                }}
                src={props.blockData.image}
                alt=""
                className={`lg:mt-0 max-w-full transition-all duration-150 ease-in`}
                style={position ? { filter: "brightness(60%)" } : undefined}
              />
            </div>
          </div>
          </div>
        }
      />
    </div>
  );
}

export default Firstclick;
