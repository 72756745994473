import React from "react";
import "../../../../../tailwind.generated.css";

import Modal from "../../../../Modal";
import Button from "../../../../Button";
import { SuccessIll } from "../../../../../icons";

import { locale, interfaceText } from "../../../../../helpers";

function FigmaSuccessModal(props: {
  onClick: (e: React.MouseEvent | undefined) => void;
  isOpen: boolean;
  isLoading?: boolean;
}) {

  return (
    <Modal
      isOpen={props.isOpen}
      setIsOpen={() => { }}
      width="440px"
      disallowClickOutside
      background
      slow
    >
      <div className="success-modal p-6 mx-auto flex flex-col justify-between h-full">
        <div>
          <SuccessIll className="mb-4" />
          <div className="flex text-2xl mb-2 font-bold font-display">
            {(interfaceText as any).figmaTestingSuccess[locale()].header}
          </div>
          <div className="mt-2 text-lg">
            {(interfaceText as any).figmaTestingSuccess[locale()].text}
          </div>
        </div>
        <Button
          className="success-modal__button mt-4"
          name={(interfaceText as any).figmaTestingSuccess[locale()].button}
          handler={props.onClick}
          large
          type="primary"
          disabled={props.isLoading}
          isLoading={props.isLoading}
        />
      </div>
    </Modal>
  );
}

export default FigmaSuccessModal;
