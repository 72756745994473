import React, { useEffect, useState, useRef } from "react";
import "../../../../../tailwind.generated.css";
import clsx from "clsx";

import parse from "html-react-parser";

import _ from "lodash";

import { MatrixBlock, Block } from "../../../../../models/Test";
import { IconCheckMark } from "../../../../../icons";

const MatrixSelect = ({
  blockData: { blockId, rows, columns, replyType, text, questionHtml },
  blockAnswer,
  handleBlockAnswer,
}: {
  blockData: MatrixBlock & Block;
  blockAnswer: {
    selectedOptions: Record<string, string[]>;
  };
  handleBlockAnswer: (key: string, value: any) => void;
}) => {
  const [withScroll, setWithScroll] = useState<boolean | null>(null);
  const [theadOffset, setTheadOffset] = useState(0);

  const selectedOptions = blockAnswer?.selectedOptions;
  const isMulti = replyType === "multi";

  const updateWithScroll = () => {
    const tableElement = document.getElementById(`matrixTableWrapper-${blockId}`);
    if (tableElement) {
      const withScroll =
        tableElement.scrollWidth > tableElement.clientWidth &&
        tableElement.scrollWidth - tableElement.scrollLeft > tableElement.clientWidth + 20;
      setWithScroll(withScroll);
    }
  };

  useEffect(() => {
    handleBlockAnswer("selectedOptions", {
      ..._.chain(rows)
        .keyBy("id")
        .mapValues(() => {
          return [];
        })
        .value(),
    });
  }, []);

  useEffect(() => {
    updateWithScroll();
    window.addEventListener("resize", updateWithScroll);
    window.addEventListener("scroll", updateWithScroll, true);
    return () => {
      window.removeEventListener("resize", updateWithScroll);
      window.removeEventListener("scroll", updateWithScroll, true);
    };
  }, [selectedOptions]);

  const handleSelectOption = (rowId: string, columnId: string) => {
    if (replyType === "single") {
      handleBlockAnswer("selectedOptions", {
        ...selectedOptions,
        [rowId]: [columnId],
      });
    } else {
      const selectedOptions = blockAnswer?.selectedOptions;
      const rowOptions = selectedOptions[rowId];
      const newOptions = _.xor(rowOptions, [columnId]);
      handleBlockAnswer("selectedOptions", {
        ...selectedOptions,
        [rowId]: newOptions,
      });
    }
  };

  const matrixTableRef = useRef<HTMLTableElement>(null);
  const theadRef = useRef<HTMLTableSectionElement>(null);

  const [isMatrixTableOverflowing, setIsMatrixTableOverflowing] = useState(false);

  useEffect(() => {

    // this is logic for sticking thead to the top while scrolling + displaying gradient block on the right side of the table

    const contentWrapper = document.getElementById(`content-wrapper-${blockId}`);
    const matrixTable = matrixTableRef.current;
    const theadElement = theadRef.current;

    const calculateMatrixTableOverflowing = () => {
      if (matrixTable && contentWrapper) {
        setIsMatrixTableOverflowing(matrixTable.scrollWidth > contentWrapper.clientWidth);
      }
    }

    let theadInitialTop = 0;
    if (theadElement && contentWrapper) {
      // Calculate the initial top position of the `<thead>` relative to its parent container
      theadInitialTop = theadElement.getBoundingClientRect().top - contentWrapper.getBoundingClientRect().top;
    }

    const handleScroll = () => {
      if (contentWrapper) {
        const scrollPosition = contentWrapper.scrollTop;
        // Calculate the offset only when the scroll position exceeds the initial top position of the `<thead>`
        const offset = Math.max(0, scrollPosition - theadInitialTop);
        setTheadOffset(offset);
      }
    };

    handleScroll();
    calculateMatrixTableOverflowing();

    const updateMatrixCalculations = () => {
      calculateMatrixTableOverflowing();
      handleScroll();
    }

    contentWrapper?.addEventListener("scroll", updateMatrixCalculations);
    window.addEventListener("resize", updateMatrixCalculations);

    return () => {
      contentWrapper?.removeEventListener("scroll", updateMatrixCalculations);
      window.removeEventListener("resize", updateMatrixCalculations);
    };
  }, [theadRef.current, matrixTableRef.current, blockId]);

  if (!selectedOptions) {
    return null;
  }

  return (
    <>
      <div className="w-full text-xl my-2 flex items-start">
        <div>{parse(questionHtml || text)}</div>
      </div>
      <div className="my-2 w-full">
      <div className="relative">
        <div className={clsx("relative overflow-x-auto")} id={`matrixTableWrapper-${blockId}`}>
          {isMatrixTableOverflowing && <div className="fixed h-full bg-gradient-to-l from-white from-50% to-transparent right-3 top-0 w-5 z-999" />}
          <table ref={matrixTableRef} className="w-full">
            <thead ref={theadRef} className="relative z-[50]" style={{top: `${theadOffset}px`}}>
              <tr>
                <th className="text-center bg-white px-2 py-4 z-40"></th>
                {columns.map((column) => (
                  <th
                    className="bg-white text-center px-2 py-4 font-normal text-sm border-b-1 border-gray-200 border-solid"
                    key={column.id}
                  >
                    <div className={clsx("min-w-10 w-fit-content mx-auto")}>{column.value}</div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody id="matrixTbody">
              {rows.map((row) => {
                return (
                  <tr key={row.id} className={clsx("h-12", withScroll && "matrixTableRow")}>
                    <td
                      className="sticky py-3 px-2 align-middle text-sm left-0 bg-white text-ellipsis w-32 overflow-hidden"
                      style={{ minWidth: "128px" }}
                    >
                      {row.value}
                    </td>
                    {columns.map((column) => {
                      const isOptionSelected = blockAnswer.selectedOptions[row.id].includes(column.id);
                      return (
                        <td className={clsx("px-2 py-4 text-center h-12 text-xl")} key={column.id}>
                          <div
                            className={clsx(
                              "w-8 h-8 border-2 bg-white mx-auto flex items-center justify-center cursor-pointer z-10 hover:border-blue-600 transition-all duration-75 ease-in",
                              isOptionSelected ? "border-blue-600" : "border-gray-400",
                              isMulti ? "rounded-md" : "rounded-full"
                            )}
                            onClick={() => {
                              handleSelectOption(row.id, column.id);
                            }}
                          >
                            {isOptionSelected && <IconCheckMark className="fill-current text-blue-600" />}
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      </div>
    </>
  );
};

export default MatrixSelect;
