import React from "react";
import "../tailwind.generated.css";
import { locale, interfaceText } from "../helpers";

const PageNotFound = () => {
  return (
    <div className="container mx-auto h-full flex justify-center items-center">
      <div className="text-center">
        <div className="mb-4 text-3xl">{interfaceText.pageNotFound[locale()].header}</div>
        <div>{interfaceText.pageNotFound[locale()].text}</div>
      </div>
    </div>
  );
};

export default PageNotFound;
