import { useEffect, useState } from 'react';

const useTypingAnimation = (text: string = '', typingSpeed: number = 150) => {
  const [animatedText, setAnimatedText] = useState('');
  const [showCursor, setShowCursor] = useState(true);
  const [isTypingComplete, setIsTypingComplete] = useState(false); // New state to track completion

  useEffect(() => {
    if (text) {
      setAnimatedText('');
      setIsTypingComplete(false); // Reset on new text
      const typingInterval = setInterval(() => {
        setAnimatedText((prev) => {
          if (prev.length < text.length) {
            return text.substring(0, prev.length + 1);
          } else {
            clearInterval(typingInterval);
            setIsTypingComplete(true); // Mark typing as complete
            return text;
          }
        });
      }, typingSpeed);

      // Cursor blinking effect
      const cursorInterval = setInterval(() => {
        setShowCursor((prev) => !prev);
      }, 500);

      return () => {
        clearInterval(typingInterval);
        clearInterval(cursorInterval);
      };
    }
  }, [text, typingSpeed]);

// Only show the cursor if typing is not complete
const animatedTextWithCursor = `${animatedText}${!isTypingComplete ? '|' : ''}`;

  return {text: animatedTextWithCursor, isTypingComplete};
};

export default useTypingAnimation;